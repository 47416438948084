<template>
	<div class="task-card" @click="$emit('toDownload')">
		<div class="card_title">{{ model.title }}</div>
		<div class="card_time">{{ model.updateTime }}</div>
		<div class="card_desc">{{ model.content }}</div>
		<div class="card_content">{{ model.relateTitle }}</div>
	</div>
</template>

<script>
export default {
	name: "task_card",
	props:{
		model:{
			type:Object
		}
	}
};
</script>

<style lang="scss" scoped>
.task-card {
	width: 100%;
	height: fit-content;
	background-color: #FFF;
	border-radius: 8px;
	padding: 14px 16px;
	box-sizing: border-box;
  .card_title {
    color: #333333;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  .card_time {
    color: #555555;
    line-height: 16px;
    font-size: 10px;
    margin-bottom: 20px;
  }
	.card_desc {
		font-size: 12px;
		color: #333333;
		line-height: 18px;
	}
	.card_content {
		max-width: 448px;
		height: 34px;
		font-size: 12px;
		color: #555555;
		background-color: #F3F3F5;
		line-height: 34px;
		padding: 0 8px;
		border-radius: 4px;
		margin-top: 4px;
		box-sizing: border-box;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
