<template>
	<transition name="el-fade-in-linear">
		<div class="PaymentDialog" v-show="visible">
			<div class="PaymentDialog_box">
				<div class="header_box">
					<div class="header_title">支付</div>
					<div class="header_close icon-sohu-quxiao" @click="close"></div>
				</div>
				<div class="qrCodeBox">
					<div class="price">
						扫码支付<span class="unit">￥</span
						><span class="num">{{ price }}</span>
					</div>
					<div class="qrCode" v-loading="loading">
						<vue-qr :margin="0" :text="qrcodeVal" :size="159"></vue-qr>
					</div>
					<div class="Refresh" @click="RefreshClick">
						<i class="icon-sohu-shuaxin"></i>
						刷新二维码
					</div>
				</div>
				<q-button type="theme" @click="complete">已完成支付</q-button>
			</div>
		</div>
	</transition>
</template>
<script>
import VueQr from 'vue-qr';
import dialogBox from '@/components/dialogBox';
export default {
	components: { dialogBox, VueQr },
	data() {
		return {
			visible: false,
			price: '',
			qrcodeVal: '',
			payFunction: null,
			loading: false,
			masterOrderNo: '',
			paySource: '',
		};
	},
	methods: {
		/**
		 * 关闭
		 */
		close() {
			this.visible = false;
		},

		/**
		 * 确定
		 */
		complete() {
			this.callback('confirm');
			this.close();
		},

		// 显示弹窗
		async showDialog() {
			this.visible = true;
			this.loading = true;
			let res;
			try {
				if (typeof this.payFunction === 'function') {
					res = await this.payFunction();
				} else {
					res = await this.$http.payment({
						payType: 'yi-ma',
						paySource: this.paySource,
						masterOrderNo: this.masterOrderNo,
						payChannel: 'pc',
					});
				}
				this.qrcodeVal = JSON.parse(res.msg).codeUrl;
				setTimeout(() => {
					this.loading = false;
				}, 3000);
			} catch (error) {
				setTimeout(() => {
					this.loading = false;
				}, 3000);
			}
		},

		// 刷新二维码
		RefreshClick() {
			this.showDialog();
		},
	},
};
</script>
<style lang="scss">
.PaymentDialog {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
	.PaymentDialog_box {
		background: #ffffff;
		border-radius: 12px;
		width: 300px;
		padding: 20px;
		box-sizing: border-box;

		.header_box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.header_title {
				font-size: 20px;
				color: #3d3d3d;
				font-weight: 600;
				line-height: 26px;
			}
			.header_close {
				color: #979797;
				font-size: 16px;
				font-weight: bold;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}
		}

		.qrCodeBox {
			margin-top: 18px;
			text-align: center;
			.price {
				color: rgba(0, 0, 0, 0.9);
				font-size: 14px;
				line-height: 22px;
				display: flex;
				align-items: flex-end;
				justify-content: center;
				.unit {
					color: #ea0000;
					font-size: 18px;
					font-weight: 600;
					line-height: 26px;
					margin-left: 16px;
					margin-right: 5px;
				}
				.num {
					color: #ea0000;
					font-size: 36px;
					font-weight: 600;
					line-height: 40px;
				}
			}
			.qrCode {
				margin-top: 15px;
				margin-bottom: 6px;
			}
			.Refresh {
				color: rgba(153, 153, 153, 0.9);
				font-size: 12px;
				line-height: 22px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				i {
					font-size: 16px;
					margin-right: 4px;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
		.q-button {
			margin: auto;
			margin-top: 12px;
			margin-bottom: 8px;
		}
	}
}
</style>
