<template>
	<dialogBox
		appendToBody
		width="500px"
		v-model="dialogVisible"
		class="ApplyJoinGroupDialog"
		title="申请加入群聊"
		showBottomBtn
		confirmText="加入群聊"
		@confirm="confirmClick"
	>
		<div class="groupData">
			<el-avatar :size="40" :src="groupDetails.logo"></el-avatar>
			<div class="groupName">{{ groupDetails.name }}</div>
		</div>
		<div class="fromTitle">发送验证消息</div>
		<qInput
			placeholder="请输入进群理由"
			v-model="applyContent"
			type="textarea"
			:maxlength="40"
			:rows="5"
		></qInput>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import qInput from '@/components/q-input.vue';
export default {
	components: { dialogBox, qInput },
	data() {
		return {
			dialogVisible: false,
			groupId: '',
			groupDetails: {},
			applyContent: '',
		};
	},
	methods: {
		async open(groupId) {
			const res = await this.$http.imGroupDetails(groupId);
			this.groupDetails = res.data;
			this.dialogVisible = true;
		},
		// 加入群聊按钮
		async confirmClick(close) {
			const res = await this.$http.imApplyGroup({
				groupId: this.groupDetails.id,
				inviteUserId: this.$route.query.inviteId,
				inviteCalculate: this.$route.query.inviteCalculate,
				channelCode: this.$route.query.channelCode,
				applyContent: this.applyContent,
			});
			if (res.code == 200) {
				this.dialogVisible = false;
				this.$message.success('申请成功，等待群主审核');
				if (this.$route.query.groupId) {
					this.$router.replace({
						path: '/',
					});
				}
			}
			close();
		},
	},
};
</script>
<style lang="scss">
.ApplyJoinGroupDialog {
	.groupData {
		display: flex;
		align-items: center;
		margin: 24px 0;
		.groupName {
			color: #3d3d3d;
			font-weight: 500;
			font-size: 14px;
			margin-left: 10px;
		}
	}
	.fromTitle {
		color: #333333;
		font-weight: 500;
		font-size: 14px;
		margin-bottom: 8px;
	}
}
</style>
