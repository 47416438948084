<template>
	<el-container class="layout-game">
		<el-header height="72px"> <q-header></q-header></el-header>
		<el-main>
			<router-view />
		</el-main>
	</el-container>
</template>
<script>
import qHeader from './q-header.vue';
export default {
	components: {
		qHeader,
	},
	data() {
		return {};
	},
	methods: {},
};
</script>
<style lang="scss">
.layout-game {
	height: 100%;
	.el-header {
		padding: 0;
	}
	.el-main {
		padding: 0;
	}
}
</style>
