<template>
	<div
        v-if='fullScrennVisiable'
		class="rnui-layer"
		:class="{
			'rnui-layer-active': fullScrennVisiable,
			animate__zoomIn: fullScrennVisiable,
		}"
	>
		<svg class="icon close" aria-hidden="true" @click="handleClose">
			<use :xlink:href="`#icon-sohu-guanbi`"></use>
		</svg>
		<slot> </slot>
	</div>
</template>
<script>
export default {
	data() {
		return {
			fullScrennVisiable: false,
		};
	},
	methods: {
		handleClose() {
			this.fullScrennVisiable = false;
            this.$emit('close')
		},
	},
};
</script>
<style lang="scss" scoped>
.rnui-layer {
	background: #141414;
	flex-direction: column;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 2000;
    color: #fff;
	// display: none;
	&.rnui-layer-active {
		display: flex;
	}
	.close {
		font-size: 40px;
		position: absolute;
		left: 20px;
		top: 20px;
		z-index: 999;
	}
}

@keyframes zoomIn {
	from {
		transform: scale(0.3);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	to {
		transform: scale(1);
	}
}

.animate__zoomIn {
	animation: zoomIn 0.5s ease-in-out forwards;
}
</style>
