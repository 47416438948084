import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css';
import '@/assets/css/common.scss';
import { registerMicroApps , start } from "qiankun";
import microApp from "@/micro";
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.config.productionTip = false;

Vue.use(ElementUI);

import http from '@/utils/api';
Vue.prototype.$http = http;

import util from '@/utils/util.js';
Vue.prototype.$util = util;

import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';
import '@/assets/font/fonts.scss';

store.dispatch('init');

import '@/components/confirm/index.js';
import '@/components/PaymentDialog/index.js';
import '@/components/login/index.js';
import '@/components/foxCoinDialog/index.js';
import '@/components/reportDialog/index.js';
import qInput from '@/components/q-input';
Vue.component('q-input', qInput);

import checkbox from '@/components/checkbox';
Vue.component('q-checkbox', checkbox);

import empty from '@/components/empty.vue';
Vue.component('q-empty', empty);

import scrollBox from '@/components/scroll-box.vue';
Vue.component('scroll-box', scrollBox);

import YSF from '@neysf/qiyu-web-sdk';
YSF.init('296904123fc762ad332610839363736a', {
	templateId: '6654991',
});

// 分页组件
import Pagination from '@/components/Pagination';
Vue.component('Pagination', Pagination);

// 按钮
import qButton from '@/components/q-button/index.vue';
Vue.component('q-button', qButton);

import Moment from 'moment';
Vue.prototype.$moment = Moment;

//微前端
registerMicroApps(microApp,{
	beforeLoad: app => {
		console.log('before load app.name====>>>>>', app.name)
	},
	beforeMount: [
		app => {
			console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
		}
	],
	afterMount: [
		app => {
			console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
		}
	],
	afterUnmount: [
		app => {
			console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
		}
	]
})
// start();

let instance = null;

function render(props) {
	instance = new Vue({
		router,
		store,
		render: (h) => h(App),
	}).$mount('#app');
}

if (window.__POWERED_BY_QIANKUN__) {
	__webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__;
} else {
	render();
}


