<template>
	<div>
		<fullScreenBox ref="fullScreenBox" @close="handleClose">
			<videoBox
				:videoList="videoList"
				v-model="currentVideoIndex"
				:total="total"
				ref="videoBoxRef"
				@getVideoList="getVideoList"
				@getCommentList="commentOpen"
				v-if="videoList.length > 0"
			></videoBox>
			<!--分享，评论等功能按钮 -->
			<videoOperation
				v-if="videoList.length > 0"
				:videoList="videoList"
				:video-info="videoList[currentVideoIndex]"
				:isMy="isMy"
				:make-money="makeMoney"
				@getVideoList="getVideoList"
				@commentOpen="commentOpen"
				@arrowClick="arrowClick"
				@handleOpt="handleOpt"
			></videoOperation>
			<!-- 评论弹窗 -->
			<template v-if="videoList[currentVideoIndex]">
				<el-drawer
					:visible.sync="drawerShow"
					:title="'评论(' + videoList[currentVideoIndex].commentCount + ')'"
					:direction="direction"
					append-to-body
					:modal="false"
					:size="540"
					@close="handleCloseDrawer"
				>
					<commentList
						:id="videoList[currentVideoIndex].id"
						type="Video"
						:detail-info="
							videoList.find((v) => v.id === videoList[currentVideoIndex].id) ||
							videoList[currentVideoIndex]
						"
						v-model="videoList[currentVideoIndex].commentCount"
						height="400px"
						ref="qCommentList"
						:entry="entry"
					></commentList>
				</el-drawer>
			</template>
		</fullScreenBox>
	</div>
</template>
<script>
import {
	aiBuryContentType,
	aiBuryEvent,
	aiBuryScene,
	aiRec
} from '@/utils/filter/aiModelEnum';
import store from '@/store';
import showcaseWindow from '@/components/showcaseWindow.vue';
import videoOperation from '@/components/videoOperation.vue';
import videoBox from '@/components/videoBox.vue';
import fullScreenBox from '@/components/fullScreenBox.vue';
import commentList from '@/components/commentList.vue';
export default {
	props: {
		detailItem: {
			type: Object,
		},
		//入口 位置 (例:home,money)
		entry: {
			type: String,
			default: '',
		},
		isMy: {
			type: Boolean,
			default() {
				return false;
			},
		},
		makeMoney: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	components: {
		commentList,
		showcaseWindow,
		videoOperation,
		videoBox,
		fullScreenBox,
	},
	data() {
		return {
			currentVideoIndex: 0, //从第几个开始播放
			total: 0,
			pageNum: 1,
			drawerShow: false,
			direction: 'rtl',
			videoInfo: {},
			videoList: [],
			videoDetailVisiable: false,
		};
	},
	watch: {
		videoDetailVisiable(val) {
			if (val) {
				this.$refs.fullScreenBox.fullScrennVisiable = true;
				if (this.isMy) {
					//如果是从我的这个菜单进入，不轮播，只展示当前的视频
					this.videoList = [this.detailItem];
				} else {
					this.getVideoList();
				}
			}
		},
	},
	methods: {
		async getVideoList(pageNum) {
			this.pageNum = pageNum ? pageNum : this.pageNum;
			let params = {
				pageSize: 10,
				pageNum: this.pageNum,
				siteId: this.$store.state.siteId,
				aiRec,
				aiRecSceneId: aiBuryScene.homeVideo,
			};
			if (localStorage.getItem('aiRec')) {
				params.aiRecImei = store.getters.isLogin
					? store.getters.imei
					: localStorage.getItem('aiRec');
			} else {
				params.aiRecImei = store.getters.isLogin
					? store.getters.imei
					: new Date().getTime();
			}
			const res = await this.$http.getContentVideoList(params);
			if (this.pageNum == 1) {
				let i = res.data.findIndex((val) => {
					return val.id == this.detailItem.id;
				});
				if (i >= 0) {
					this.currentVideoIndex = i;
					this.videoList = res.data;
				} else {
					this.currentVideoIndex = 0;
					await this.getVideoDetail();
					this.videoList = [this.videoInfo, ...res.data];
				}
			} else {
				this.videoList.push(...res.data);
			}
			this.total = res.total;
		},
		// 获取视频详情
		async getVideoDetail() {
			let id;
			if(this.$route.path == '/my'){
				id = this.isMy?this.detailItem.id:this.detailItem.objId;
			}else{
				id = this.detailItem.id
			}
			const res = await this.$http.getContentVideoDetail(id);
			this.videoInfo = res.data;
		},
		// 点击关闭图标
		handleClose() {
			this.currentVideoIndex =  0;
			this.total = 0;
			this.pageNum = 1;
			this.videoList = [];
			this.videoDetailVisiable = false;
			this.$emit('detailClose')
		},
		handleOpt(type) {
			switch (type) {
				case 'like':
					//AI智能模型点赞
					this.handleAiSceneFilter(aiBuryEvent.like);
					break;
				case 'collect':
					//AI智能模型收藏
					this.handleAiSceneFilter(aiBuryEvent.collect);
					break;
				case 'share':
					//AI智能模型分享
					this.handleAiSceneFilter(aiBuryEvent.share);
					break;
				case 'disLikeSure':
					//AI智能模型不喜欢
					this.handleAiSceneFilter(aiBuryEvent.unlike);
					break;
				case 'report': //举报成功
				this.$refs.videoBoxRef.playVideo();
				break;
			}
		},
		arrowClick(val) {
			this.$refs.videoBoxRef.arrowClick(val);
		},
		// 打开评论弹窗
		async commentOpen() {
			this.drawerShow = true;
			setTimeout(() => {
				this.$refs.qCommentList.getCommentList();
			}, 500);
		},
		// 查看别人视角我的详情
		goMyDetail() {
			if (this.$store.state.userInfo.id == this.videoInfo.userId) {
				this.$login().then((res) => {
					this.$router.push({
						path: '/my',
					});
				});
			} else {
				this.$router.push({
					path: '/othersHome',
					query: {
						userId: this.videoInfo.userId,
					},
				});
			}
		},
		// 关闭评论弹窗
		handleCloseDrawer() {
			this.drawerShow = false;
		},
		/**
		 * ai场景过滤器
		 * @param event 事件
		 */
		handleAiSceneFilter(event) {
			let scene = '';
			let content = ''; //为短剧预留字段
			//根据入口判断类型
			switch (this.entry) {
				case 'home':
					scene = aiBuryScene.homeVideo;
					content = aiBuryContentType.video;
					break;
				case 'money':
					scene = aiBuryScene.moneyVideo;
					content = aiBuryContentType.video;
					break;
				default:
					scene = aiBuryScene.homeVideo;
					content = aiBuryContentType.video;
					break;
			}
			//AI智能模型
			this.handleAiRec(
				scene,
				content,
				event,
				this.videoList.find(
					(v) => v.id === this.videoList[this.currentVideoIndex].id,
				)?.aiResultItem,
			);
		},
		/**
		 *  AI智能模型
		 * @param scene 场景
		 * @param content 内容
		 * @param event 事件
		 * @param data 埋点数据
		 */
		handleAiRec(scene, content, event, data) {
			if (data) {
				this.$http.aiRecAdd(scene, content, event, {
					...data,
					bhvValue: 1,
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
:deep(.el-drawer.rtl) {
	height: 91% !important;
}

:deep(.el-drawer__header) {
	padding-bottom: 0;
	margin-bottom: 10px;
}
</style>
