<template>
	<dialogBox v-model="dialogVisible" class="GroupApprovalDialog">
		<div slot="header_title">进群审批</div>
		<!-- <q-input
			class="searchInput"
			v-model="searchInput"
			placeholder="请输入要查找的内容"
			@input="searchChange"
		></q-input> -->
		<scroll-box
			:loading="loading"
			:total="list.length"
			height="500px"
			style="margin-top: 20px"
			@scrolltolower="load"
		>
			<template>
				<div class="list_item" v-for="item of list" :key="item.id">
					<div class="leftUserData">
						<el-avatar :src="item.userAvatar"></el-avatar>
						<div class="userData">
							<div class="line-1 username">{{ item.userName }}</div>
							<div class="applyContent">
								{{ item.applyContent }}
							</div>
						</div>
					</div>
					<button
						class="right_btn"
						v-if="item.applyState == 'WaitApprove'"
						@click="approvedClick(item)"
					>
						通过
					</button>
					<div class="right_text" v-else>
						{{ item.applyState == 'Pass' ? '已通过' : '拒绝' }}
					</div>
				</div>
			</template>
		</scroll-box>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
export default {
	components: { dialogBox },
	data() {
		return {
			dialogVisible: false,
			searchInput: '',
			list: [],
			id: '',
			pageNum: 1,
			total: 0,
			loading: false,
		};
	},
	methods: {
		searchChange() {},

		async open(id) {
			this.dialogVisible = true;
			this.id = id;
			this.pageNum = 1;
			this.getList();
		},

		// 获取列表
		async getList() {
			this.loading = true;
			const res = await this.$http.imApplyList({
				targetId: this.id,
				applyType: 'group',
				pageNum: this.pageNum,
				pageSize: this.pageSize,
			});
			if (this.pageNum == 1) {
				this.list = res.data;
			} else {
				this.list = this.list.concat(res.data);
			}
			console.log(this.list);
			this.total = res.total;
			this.loading = false;
		},

		// 滚动事件
		load() {
			if (this.total > this.list.length) {
				this.pageNum++;
				this.getList();
			}
		},

		// 审核通过
		async approvedClick(val) {
			const res = await this.$http.imApplyGroupAudit({
				id: val.id,
				applyState: 'Pass',
			});
			if (res.code == 200) {
				val.applyState = 'Pass';
			}
		},
	},
};
</script>
<style lang="scss">
.GroupApprovalDialog {
	.searchInput {
		margin: 24px 0;
	}

	.list_item {
		margin-top: 24px;
		display: flex;
		justify-content: space-between;
		.leftUserData {
			display: flex;
			.el-avatar {
				width: 40px;
				height: 40px;
				margin-right: 10px;
				min-width: 40px;
			}
			.userData {
				.username {
					color: #3d3d3d;
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
				}
				.applyContent {
					font-size: 14px;
					line-height: 20px;
					color: #999999;
				}
			}
		}
		.right_btn {
			background: #ff6c27;
			width: 70px;
			min-width: 70px;
			height: 28px;
			border-radius: 2.8px;
			color: #ffffff;
			font-size: 14px;
			line-height: 22px;
			margin-left: 36px;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
		.right_text {
			min-width: 70px;
			font-size: 12px;
			margin-left: 36px;
			color: #999;
		}
	}
}
</style>
