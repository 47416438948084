<template>
	<div :class="[{ showSearchIcon: showSearchIcon }, 'q-input']">
		<el-input
			:maxlength="maxlength"
			v-model="input"
			:type="type"
			resize="none"
			@input="inputChange"
			:placeholder="placeholder"
			:rows="rows"
			:show-word-limit="showWordLimit"
			:disabled="disabled"
			:clearable="clearable"
			@clear="clear"
			:autofocus="autofocus"
		>
			<i
				v-if="showSearchIcon"
				@click="searchClick"
				slot="suffix"
				class="icon-sohu-sousuo1"
			></i
		></el-input>
	</div>
</template>
<script>
export default {
	props: {
		// text textarea
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: String,
			default: '',
		},
		maxlength: {
			type: Number,
			default: 32,
		},
		placeholder: {
			type: String,
			default: '',
		},
		rows: {
			type: Number,
			default: 2,
		},
		showWordLimit: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		showSearchIcon: {
			type: Boolean,
			default: false,
		},
		// 原生属性，自动获取焦点
		autofocus:{
			type: Boolean,
			default: false
		}
	},
	watch: {
		value: {
			handler(val) {
				this.input = val;
			},
			deep: true,
		},
	},
	data() {
		return { input: this.value };
	},
	methods: {
		inputChange(value) {
			this.input = value.replace(/^[ \t]+|[ \t]+$/g, '');
			this.$emit('input', this.input);
		},
		// 搜索按钮
		searchClick() {
			this.$emit('search-click');
		},
		// 清空按钮
		clear() {
			this.$emit('input', '');
			this.$emit('clear');
		},
	},
};
</script>
<style lang="scss">
.q-input {
	.el-input__inner,
	.el-textarea__inner {
		background: #f3f3f3;
		border: 0 solid #000;
	}
	::placeholder {
		color: #999999;
	}
	.icon-sohu-sousuo1 {
		right: 5px;
		position: absolute;
		top: 10px;
		font-size: 20px;
		color: #5d5d5d;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}

	&.showSearchIcon {
		.el-input__inner,
		.el-textarea__inner {
			padding-right: 60px;
		}

		.el-input__suffix {
			.el-input__clear {
				position: absolute;
				right: 30px;
			}
			.icon-sohu-sousuo1 {
				right: 5px;
				position: absolute;
				top: 10px;
				font-size: 20px;
				color: #5d5d5d;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}
</style>
