import DemolitionEnterLayout from '@/views/entry/demolitionEnter/Layout.vue';
// 拆单方入驻
export default [
	{
		path: '/demolitionEnter',
		component: DemolitionEnterLayout,
		name: 'DemolitionLayout',
		redirect: '/demolitionEnter/demolitionEnterIndex',
		children: [
			{
				path: 'demolitionEnterIndex',
				component: () =>
					import('@/views/entry/demolitionEnter/demolitionEnterIndex/index'),
				meta: {
					title: '拆单方驻首页',
				},
			},
			{
				path: 'demolitionEnterInfo',
				component: () =>
					import('@/views/entry/demolitionEnter/demolitionEnterInfo/index'),
				meta: {
					title: '填写入驻信息',
				},
			},
			{
				path: 'success',
				component: () => import('@/views/entry/demolitionEnter/success/index'),
				meta: {
					title: '提交成功',
				},
			},
		],
	},
];
