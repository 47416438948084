import { get, postFile, postJson, deleteApi } from '@/utils/request';

export default {
	// 获取各种类别下的列表
	categoryTopTen(data) {
		return get(`/app/api/dj/category/top/ten`, data);
	},
	// 获取热门前十
	topTen(data) {
		return get(`/app/api/dj/top/ten`, data);
	},
	// 获取热门前三
	playletHot(data) {
		return get(`/app/api/dj/playlet/hot`, data);
	},
	// 短剧搜索列表
	playletSearch(data) {
		return get(`/app/api/dj/playlet/search`, data);
	},
	// 查看更多
	getMoreplaylet(data) {
		return get(`/app/api/dj/list/playlet`, data);
	},
	//显示更多短剧-列表
	getShortPlayNum(episodeRelevance, data) {
		return get(`/app/api/dj/playlet/${episodeRelevance}`, data);
	},
	//观看短剧的某集
	djSeeVideoId(videoId, data) {
		return get(`/app/api/dj/see/${videoId}`, data);
	},
	//查询剧集主体详细信息
	PlayletInfo(videoId, data) {
		return get(`/app/api/dj/playlet/info/${videoId}`, data);
	},
	// 观看记录列表
	seeList(data) {
		return get(`/app/api/dj/see/list`, data);
	},

	// 订单新增 预支付
	 ordersAddedApp(data) {
		return get(`/app/pay/payment`, data);
  },

	// 订单新增 预支付 (虚拟币)
	ordersAdded(data) {
		return get(`/pay/pay/pc/payment`, data);
	},
	// 校验数据是否存在,通过分销链接进入
	independentMaterialCheckData(data) {
		return get(`/admin/independentMaterial/check/data`, data);
	}
}
