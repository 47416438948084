<template>
	<el-container class="systemNotification">
		<el-header height="56px">
			<div class="systemNotification_header">
				<div class="leftTitle">
					<el-image
						:src="require('@/assets/images/chat/notification.png')"
					></el-image>
					消息通知
				</div>
				<div class="rightTabs">
					<div class="title">{{ messageTitle }}</div>
				</div>
			</div>
		</el-header>
		<el-main>
			<el-container style="height: 100%">
				<el-aside width="248px">
					<div class="notificationTypeList">
						<div
							v-for="item of systemOuterList"
							:class="[
								{ active: systemEnum[item.type].value === leftTitleActive },
								'notificationType_item',
							]"
							@click="leftTitleClick(item)"
						>
							<el-image :src="systemEnum[item.type].icon"></el-image>
							{{ systemEnum[item.type].name }}
							<div class="dot-red" v-show="item.unReadCount && systemEnum[item.type].value!=leftTitleActive"></div>
						</div>
					</div>
				</el-aside>
				<el-main style="padding: 0">
					<div class="notificationContent" @scroll="handleScroll">
						<!-- 系统通知 -->
						<template v-if="leftTitleActive === 'system'">
							<div class="system-tab">
								<div
									class="item"
									v-for="item in systemTabList"
									:key="item.type"
									@click="handleSystemTab(item)"
								>
									<img :src="item.icon" alt="" />
									<div class="title">
										<div class="point" v-if="item.unReadCount > 0"></div>
										<p
											:style="{
												color:
													item.type === systemTabActive
														? item.color
														: '#3D3D3D',
												textDecoration:
													item.type === systemTabActive ? 'underline' : 'none',
											}"
										>
											{{ item.title }}
										</p>
									</div>
								</div>
							</div>
							<div class="system-list">
								<template>
									<system_card
										v-for="item of list"
										:item="item"
										:key="item.id"
										@toDownload="handleDownload"
									></system_card>
								</template>
							</div>
						</template>
						<!-- 新的朋友 -->
						<template v-if="leftTitleActive === 'friend'">
							<div class="new-tab">
								<div
									v-for="v in newList"
									:key="v.type"
									:class="[
										'item',
										newTabActive === v.type ? 'success' : 'fail',
									]"
									@click="handleNewTab(v)"
								>
									{{ v.title }}
								</div>
							</div>
							<div class="new-list" v-if="newTabActive === 'friend'">
								<NewFriends_card :list="list"></NewFriends_card>
							</div>
							<div class="fans-list" v-if="newTabActive === 'fans'">
								<new-fans_card :list="list" @update="handleFansUpdate" />
							</div>
						</template>
						<!-- 互动消息 -->
						<template v-if="leftTitleActive === 'interact'">
							<div class="interact-list">
								<InteractiveMessages_card
									:list="list"
								></InteractiveMessages_card>
							</div>
						</template>
						<!--任务通知-->
						<template v-if="leftTitleActive === 'task'">
							<div class="task-tab">
								<div
									v-for="v in taskList"
									:key="v.type"
									:class="[
										'item',
										taskTabActive === v.type ? 'success' : 'fail',
									]"
									@click="handleTaskTab(v)"
								>
									{{ v.title }}
								</div>
							</div>
							<div class="task-list">
								<task_card
									v-for="item of list"
									:key="item.id"
									:model="item"
									@toDownload="handleDownload"
								/>
							</div>
						</template>
						<p class="bottom_loading" v-if="loading">加载中...</p>
						<q-empty height="400px" v-if="!list.length"></q-empty>
					</div>
				</el-main>
			</el-container>
		</el-main>
		<download_dialog v-model="downloadShow" />
	</el-container>
</template>
<script>
import system_card from './components/system_card';
import NewFriends_card from './components/NewFriends_card';
import InteractiveMessages_card from './components/InteractiveMessages_card';
import newFans_card from "@/views/systemNotification/components/newFans_card.vue";
import task_card from '@/views/systemNotification/components/task_card.vue';
import TaskCard from '@/components/taskCard.vue';
import download_dialog from '@/views/systemNotification/components/download_dialog.vue';
import NewFans_card from "@/views/systemNotification/components/newFans_card.vue";
export default {
	components: {
		NewFans_card,
		TaskCard,
		system_card,
		NewFriends_card,
		InteractiveMessages_card,
		task_card,
		download_dialog,
	},
	props:{
		systemList:{
			type: Array,
			default: []
		}
	},
	data() {
		return {
			systemOuterList:this.systemList,
			systemEnum: {
				outerFriend:{
					icon: require('@/assets/images/chat/newFriends.png'),
					name: '新的朋友',
					value: 'friend',
				},
				outerInteract:{
					icon: require('@/assets/images/chat/interactiveMessages.png'),
					name: '互动消息',
					value: 'interact',
				},
				outerSystem:{
					icon: require('@/assets/images/chat/system.png'),
					name: '系统通知',
					value: 'system',
				},
				outerTask:{
					icon: require('@/assets/images/chat/taskMessage.png'),
					name: '任务通知',
					value: 'task',
				}
			},
			systemTabList: [
				{
					title: '系统通知',
					type: 'system',
					unReadCount: 0,
					icon: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/08/07/f318b7b577274abd8dbdda71d9363265_126x126.png',
					color: '#5EB4E2',
				},
				{
					title: '商城通知',
					type: 'shop',
					unReadCount: 0,
					icon: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/08/07/58e7427fc4584bd49e2c54c09e92cb9a_126x126.png',
					color: '#E1A038',
				},
				{
					title: '钱包通知',
					type: 'wallet',
					unReadCount: 0,
					icon: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/08/07/08a943f5b8dd4f46bb8a24f36b61a85a_126x126.png',
					color: '#72DDCB',
				},
				{
					title: 'MCN通知',
					type: 'mcn',
					unReadCount: 0,
					icon: 'https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/08/07/bf787a0ab7fa440b8706e31bb7acb514_126x126.png',
					color: '#7C98F8',
				},
			],
			taskList: [
				{
					type: 'publisher',
					title: '我是发布人',
				},
				{
					type: 'receiver',
					title: '我是接单人',
				},
			],
			newList: [
				{
					type: 'friend',
					title: '新朋友',
				},
				{
					type: 'fans',
					title: '新粉丝',
				},
			],
			leftTitleActive: 'system',
			systemTabActive: 'system',
			taskTabActive: 'publisher',
			newTabActive: 'friend',
			list: [],
			pageNum: 1,
			total: 0,
			loading: false,
			downloadShow: false,
		};
	},
	watch:{
		systemList(){
			this.handleGetSystemMsg()
		},
		isLogin:{
			handler(val){
				if (val) {
					this.getList();
				} else {
					this.list = [];
				}
			},
			deep:true,
			immediate:true
		}
	},
	computed: {
		isLogin(){
			return this.$store.getters.isLogin;
		},
		/**
		 * 标题
		 * @returns {string}
		 */
		messageTitle() {
			switch (this.leftTitleActive) {
				case 'system':
					return '系统通知';
				case 'friend':
					return '新的朋友';
				case 'interact':
					return '互动消息';
				case 'task':
					return '任务通知';
			}
		},
	},
	methods: {
		/**
		 * 获取系统通知外层列表
		 * @returns {Promise<void>}
		 */
		async handleGetSystemMsg() {
			const res = await this.$http.getSystemOuterList();
			if (res.code === 200) {
				this.systemOuterList = res.data.filter(
					(v) => v.type !== "outerMerchant",
				);
			}
		},
		/**
		 * 操作粉丝列表数据更新
		 * @param index
		 */
		handleFansUpdate(index){
			this.list[index].meFollowHim = true;
		},
		/**
		 * 显示下载App
		 */
		handleDownload() {
			this.downloadShow = true;
		},
		/**
		 * 新的朋友tab切换
		 * @param item
		 */
		handleNewTab(item) {
			if (item.type === this.newTabActive) return;
			this.newTabActive = item.type;
			this.list = [];
			this.pageNum = 1;
			this.getList();
		},
		/**
		 * 系统通知tab切换
		 * @param item
		 */
		handleSystemTab(item) {

			if (item.type === this.systemTabActive) return;
			this.systemTabActive = item.type;
			this.list = [];
			this.pageNum = 1;
			this.getList();
		},
		/**
		 * 任务通知tab切换
		 * @param item
		 */
		handleTaskTab(item) {
			if (item.type === this.taskTabActive) return;
			this.taskTabActive = item.type;
			this.list = [];
			this.pageNum = 1;
			this.getList();
		},
		// 左侧类型切换
		leftTitleClick(val) {
			this.list = [];
			this.pageNum = 1;
			this.leftTitleActive = this.systemEnum[val.type].value;
			this.handleGetSystemMsg()
			setTimeout(()=>{
				this.getList();
				// this.handleGetSystemMsg()
			},500)
			
		},

		// 获取通知列表
		async getList() {
			this.loading = true;
			let res;
			switch (this.leftTitleActive) {
				case 'system':
					return this.getSystemList();
				case 'friend':
					return this.getFansList();
				case 'interact':
					res = await this.$http.noticeInteractPage({
						pageSize: 10,
						pageNum: this.pageNum,
					});
					break;
				case 'task':
					res = await this.$http.imTaskNotice({
						pageNum: this.pageNum,
						pageSize: 10,
						noticeType: this.taskTabActive,
					});
					break;
			}
			if (this.pageNum === 1) {
				this.list = res.data;
			} else {
				this.list.push(...res.data);
			}
			this.loading = false;
			this.total = res.total;
		},

		/**
		 * 获取新的粉丝通知列表
		 */
		async getFansList() {
			let res;
			switch (this.newTabActive) {
				case "friend":
					res = await this.$http.appFriendsList({
						pageNum: this.pageNum,
						pageSize: 10,
					});
					break;
				case "fans":
					res = await this.$http.getFansNoticeList({
						pageNum: this.pageNum,
						pageSize: 10,
					});
					break;
			}
			if (this.pageNum === 1) {
				this.list = res.data;
			} else {
				this.list.push(...res.data);
			}
			this.loading = false;
			this.total = res.total;
		},

		/**
		 * 系统通知多状态列表处理
		 */
		async getSystemList() {
			let res;
			switch (this.systemTabActive) {
				case 'system':
					res = await this.$http.noticeSystemPage({
						pageNum: this.pageNum,
						pageSize: 10,
					});
					res = {
						...res,
						data: res.data.map((v) => ({
							...v,
							content: JSON.parse(v.content),
							systemType: 'system',
						})),
					};
					break;
				case 'shop':
					res = await this.$http.imSystemShopNotice({
						pageNum: this.pageNum,
						pageSize: 10,
					});
					res = {
						...res,
						data: res.data.map((v) => ({
							...v,
							content: JSON.parse(v.content),
							systemType: 'shop',
						})),
					};
					break;
				case 'wallet':
					res = await this.$http.imSystemWalletNotice({
						pageNum: this.pageNum,
						pageSize: 10,
					});
					res = {
						...res,
						data: res.data.map((v) => ({ ...v, systemType: 'wallet' })),
					};
					break;
				case 'mcn':
					res = await this.$http.imSystemMcnNotice({
						pageNum: this.pageNum,
						pageSize: 10,
					});
					res = {
						...res,
						data: res.data.map((v) => ({ ...v, systemType: 'mcn' })),
					};
					break;
			}
			await this.getSystemReadNum();
			if (this.pageNum === 1) {
				this.list = res.data;
			} else {
				this.list.push(...res.data);
			}
			this.loading = false;
			this.total = res.total;
		},

		/**
		 * 获取系统通知未读消息数量
		 */
		async getSystemReadNum(){
			const res = await this.$http.getSystemReadNum();
			if (res.code === 200) {
				res.data.forEach((item) => {
					const index = this.systemTabList.findIndex(v=>v.type === item.type);
					this.systemTabList[index].unReadCount = item.unReadCount;
				})
			}
		},

		// 监听滚动
		handleScroll(event) {
			const scrollTop = event.target.scrollTop;
			const scrollHeight = event.target.scrollHeight;
			const clientHeight = event.target.clientHeight;
			if (scrollHeight - scrollTop - clientHeight < 10) {
				if (this.total > this.list.length && !this.loading) {
					this.pageNum++;
					this.getList();
				}
			}
		},
	},
};
</script>
<style lang="scss">
.systemNotification {
	height: 100%;
	.systemNotification_header {
		display: flex;
		align-items: center;
		height: 100%;
		.leftTitle {
			padding-left: 16px;
			display: flex;
			align-items: center;
			color: #011627;
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			width: 248px;
			box-sizing: border-box;
			.el-image {
				width: 24px;
				height: 24px;
				margin-right: 4px;
			}
		}
		.rightTabs {
			flex: 1;
			height: 100%;
			background-color: #f3f3f3;
			padding-left: 20px;
			box-sizing: border-box;
			.title {
				font-size: 16px;
				color: #3d3d3d;
				line-height: 57px;
			}
		}
	}

	.notificationTypeList {
		.notificationType_item {
			height: 60px;
			display: flex;
			align-items: center;
			color: #1a1a1a;
			font-size: 16px;
			font-weight: 500;
			padding-left: 16px;
			position: relative;
			.dot-red{
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: #e41c1c;
				position: absolute;
				top: 50%;
				left: 5px;
				transform: translate(0, -50%);
			}
			.el-image {
				height: 40px;
				width: 40px;
				margin-right: 16px;
			}
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
			&.active {
				background: #eeeeee;
			}
		}
	}
	.notificationContent {
		background: #f3f3f3;
		width: 100%;
		height: 100%;
		//padding: 32px;
		box-sizing: border-box;
		display: grid;
		gap: 16px;
		overflow-y: auto;
		align-content: start;
		.bottom_loading {
			text-align: center;
		}
		.system-tab {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0 60px;
			box-sizing: border-box;
			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				img {
					width: 42px;
					height: 42px;
				}
				.title {
					position: relative;
					.point {
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: #e41c1c;
						position: absolute;
						top: 50%;
						left: -10px;
						transform: translate(0, -50%);
					}
					p {
						font-size: 14px;
						color: #3d3d3d;
						line-height: 24px;
					}
				}
			}
		}
		.new-list,
    .fans-list,
		.interact-list {
			padding: 0 10px;
		}
		.system-list,
		.task-list {
			width: 100%;
			display: grid;
			gap: 16px;
			padding: 0 32px;
			box-sizing: border-box;
		}
		.task-tab,
		.new-tab {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0 140px;
			box-sizing: border-box;
			.item {
				width: 88px;
				height: 28px;
				border-radius: 59px;
				text-align: center;
				line-height: 28px;
				cursor: pointer;
				&.fail {
					background-color: #ffffff;
					color: #3d3d3d;
				}
				&.success {
					background-color: #ff6c27;
					color: #ffffff;
				}
			}
		}
	}
}
</style>
