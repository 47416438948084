<template>
	<div
		v-if="shortPlayDetailVisiable"
		class="rnui-layer"
		:class="{
			'rnui-layer-active': shortPlayDetailVisiable,
			animate__zoomIn: shortPlayDetailVisiable,
		}"
	>
		<div class="short-play-detail-box flex">
			<div class="layer-main">
				<svg
					class="icon shortClose"
					aria-hidden="true"
					@click="handleDetailClose"
				>
					<use :xlink:href="`#icon-sohu-guanbi`"></use>
				</svg>
				<el-carousel
					height="100vh"
					direction="vertical"
					trigger="click"
					arrow="always"
					:autoplay="false"
					ref="carousel"
					@change="videoChange"
					indicator-position="none"
				>
					<el-carousel-item
						v-for="(item, index) in videoList"
						:key="index"
						:name="'video' + index"
					>
						<div class="video-main" @mousewheel="handleMousewheel">
							<video
								class="video"
								ref="videos"
								v-if="currentVideoIndex == index"
								:src="item.videoStr"
								@ended="handleEnded"
								@timeupdate="onTimeUpdate"
								@loadedmetadata="getVideoDuration"
								@click="playVideo"
							></video>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="playBox">
					<i class="icon-sohu-play" @click="playVideo" v-if="isPlay"></i>
				</div>
				<div class="control-box">
					<el-slider
						:max="sliderTime"
						v-model="currentSliderTime"
						@change="seekTo"
					></el-slider>
					<div class="operation-btn">
						<div class="left-box">
							<i class="icon-sohu-play" @click="playVideo" v-if="isPlay"></i>
							<i
								class="icon-sohu-zanting"
								@click="playVideo"
								v-if="!isPlay"
							></i>
							{{ currentTime }}/{{ videoTime }}
						</div>
						<div class="right-box" @click="playVolume">
							<i class="icon-sohu-yinliang" v-show="!isSilent"></i>
							<i class="icon-sohu-jingyin" v-show="isSilent"></i>
						</div>
					</div>
				</div>
				<div class="person-info">
					<p class="name">@{{ videoInfo.userName }}</p>
					<p class="title">{{ videoInfo.title }}</p>
				</div>
				<!--分享，评论等功能按钮 -->
				<videoOperation
					v-if="videoList.length > 0"
					:videoList="videoList"
					:video-info="videoInfo"
					:isShortPlay="true"
					@arrowClick="arrowClick"
					@commentOpen="commentOpen"
				></videoOperation>
			</div>
			<div class="stretch" @click.stop="drawerShow = true" v-show="!drawerShow">
				<i class="icon-sohu-fanhui"></i>
			</div>
			<div class="drawer" v-show="drawerShow">
				<div class="tabs">
					<div
						@click="checkTab(item, 'nav')"
						:class="['tab_item', { active: tabActive === item.id }]"
						v-for="(item, index) in overseasTabs"
						:key="index"
					>
						{{ item.name }}
					</div>
					<i class="icon-sohu-guanbi1" @click="handleClose"></i>
				</div>
				<div class="video-info" v-show="tabActive == 'video'">
					<div class="video-title-box">
						<p class="title line-1">短剧·{{ videoInfo.playletTitle }}</p>
						<p class="num">
							{{ playInfo.viewCount }}播放·共{{ videoInfo.episodeCount }}集
						</p>
						<div class="intro line-3">
							{{ playInfo.intro }}
						</div>
					</div>

					<div class="tabs">
						<div
							@click="checkTab(item, 'num')"
							:class="['tab_item', { active: tabActiveNum === item.name }]"
							v-for="(item, index) in numArr"
							:key="index"
						>
							{{ item.name }}
						</div>
					</div>
					<div class="episode-box">
						<div
							class="flex episode"
							v-for="(it, i) in videoArray"
							:key="i"
							@click="selectOption(it)"
						>
							<div class="coverImage">
								<el-image :src="it.coverImage" fit="cover"></el-image>
								<template v-if="it.episodePay && !it.paid">
									<p :class="{ paid: !it.paid }">付费</p>
								</template>
								<p class="purchased" v-if="it.id == activeIndex">播放中</p>
								<div class="episode-pay" v-if="it.episodePay && !it.paid">
									<i class="icon-sohu-suoding"></i>
								</div>
							</div>

							<div class="right">
								<p class="episodeNumber">
									第{{ it.episodeNumber }}集<i
										class="icon-sohu-bofang1"
										v-if="it.id == activeIndex"
									></i>
								</p>
								<p class="intro line-3">
									{{ it.intro || playInfo.intro }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<commentList
					v-show="videoInfo && tabActive == 'comment'"
					:id="videoInfo.id"
					:detail-info="videoInfo"
					v-model="videoInfo.commentCount"
					height="400px"
					style="height: 85vh; overflow-y: auto"
					type="Video"
					ref="qCommentList"
				></commentList>
			</div>
		</div>
		<shortPlayPayDialog
			ref="shortPlayPay"
			:videoInfo="videoInfo"
			:video-list="videoList"
			:playInfo="playInfo"
			@success="getVideoDetail"
		></shortPlayPayDialog>
	</div>
</template>
<script>
import commentList from '@/components/commentList.vue';
import shortPlayPayDialog from '@/components/shortPlayPayDialog.vue';
import videoOperation from '@/components/videoOperation.vue';
export default {
	props: {
		detailItem: {
			type: Object,
		},
		episodeRelevance: {
			type: String,
			default: '',
		},
	},
	components: { commentList, shortPlayPayDialog, videoOperation },
	data() {
		return {
			videoList: [],
			drawerShow: true,
			direction: 'rtl',
			dialogVisible: false, //付费弹窗
			videoTime: null,
			videoInfo: {},
			sliderTime: null,
			currentSliderTime: 0,
			currentTime: '00:00:00',
			activeIndex: 0,
			isPlay: false, //是否播放
			isSilent: false, //是否静音
			shortPlayDetailVisiable: false,
			overseasTabs: [
				{
					name: '视频',
					id: 'video',
				},
				{
					name: '评论',
					id: 'comment',
				},
			],
			tabActive: 'video',
			numArr: [],
			tabActiveNum: '',
			videoArray: [],
			playInfo: {},
			currentVideoIndex: 0,
		};
	},
	watch: {
		'videoInfo.commentCount'(val) {
			this.overseasTabs[1].name = `评论(${this.videoInfo.commentCount})`;
		},
	},
	methods: {
		init() {
			this.getVideoDetail();
			this.drawerShow = true;
			this.shortPlayDetailVisiable = true;
		},
		// 获取视频详情
		async getVideoDetail(episodeNumber) {
			let res = await this.$http.getShortPlayNum(this.episodeRelevance);
			res.data.length > 0 &&
				res.data.forEach((val) => {
					val.videoStr = this.$util.AES_Decrypt(val.videoUrl, val.videoKey);
				});
			this.videoList = res.data;
			if (episodeNumber == undefined) {
				//不传历史集数的情况下直接去数据的第一条
				this.videoInfo = res.data[0] || {};
			} else {
				this.videoInfo =
					res.data.find((item) => item.episodeNumber == episodeNumber) || {};
			}
			this.activeIndex = this.videoInfo.id;
			let obj;
			if (this.videoList.length > 50) {
				let splitNum = Math.floor(this.videoList.length / 5);
				if (this.videoList.length % 5 == 0) {
					obj = this.$util.getSplitArray(
						this.videoList,
						splitNum,
						episodeNumber,
					);
				} else {
					obj = this.$util.getSplitArray(
						this.videoList,
						splitNum + 1,
						episodeNumber,
					);
				}
			} else {
				obj = this.$util.getSplitArray(this.videoList, 10, episodeNumber);
			}
			this.numArr = obj.numArr;
			this.videoArray = obj.videoArray;
			this.tabActiveNum = obj.tabActiveNum;
			this.currentVideoIndex = this.videoInfo.episodeNumber - 1;
			// 获取付费的单价和总价
			let res1 = await this.$http.PlayletInfo(this.episodeRelevance);
			this.playInfo = res1.data;
		},
		//获取视频的总长度
		getVideoDuration(event) {
			this.sliderTime = Math.floor(event.target.duration);
			this.videoTime = this.$util.dealTime(Math.floor(event.target.duration));
			this.$refs.qCommentList.getCommentList();
			this.handlePay();
		},
		seekTo(time) {
			this.currentSliderTime = time;
			this.$refs.videos[0].currentTime = time;
		},
		onTimeUpdate(event) {
			this.currentSliderTime = event.target.currentTime;
			this.currentTime = this.$util.dealTime(
				Math.floor(event.target.currentTime),
			);
		},
		// 当视频结束时
		handleEnded() {
			this.currentTime = '00:00:00';
			this.currentSliderTime = 0;
			this.isPlay = true;
			this.arrowClick('down');
		},
		// 点击上下切换按钮
		arrowClick(val) {
			if (val === 'down') {
				this.$refs.carousel.next();
			} else {
				this.$refs.carousel.prev();
			}
		},
		// 鼠标滚轮事件
		handleMousewheel(event) {
			if (event.deltaY < 0) {
				this.$refs.carousel.prev();
			} else {
				this.$refs.carousel.next();
			}
		},
		// 播放视频
		playVideo() {
			this.handlePay('play');
		},
		// 设置音量
		playVolume() {
			if (this.$refs.videos[0].volume == 0) {
				this.$refs.videos[0].volume = 1;
				this.isSilent = false;
			} else {
				this.$refs.videos[0].volume = 0;
				this.isSilent = true;
			}
		},
		// 视频切换时调用
		videoChange(newIndex, oldIndex) {
			this.currentVideoIndex = newIndex;
			this.videoInfo = this.videoList[newIndex];
		},
		// 点击关闭图标
		handleDetailClose() {
			this.shortPlayDetailVisiable = false;
			this.currentTime = '00:00:00';
			this.currentSliderTime = 0;
			this.currentVideoIndex = 0;
			this.videoInfo = {};
			this.$emit('close');
		},
		//tab切换
		checkTab(item, type) {
			if (type == 'nav') {
				this.tabActive = item.id;
			} else {
				this.videoArray = item.value;
				this.tabActiveNum = item.name;
			}
		},
		//点击某一集
		selectOption(item) {
			this.videoInfo = item;
			this.currentVideoIndex = item.episodeNumber - 1;
			this.$refs.carousel?.setActiveItem(item.episodeNumber - 1);
			this.handlePay();
		},
		// 打开评论弹窗
		async commentOpen() {
			this.drawerShow = true;
			this.tabActive = 'comment';
			setTimeout(() => {
				this.$refs.qCommentList.getCommentList();
			}, 500);
		},
		// 查看别人视角我的详情
		goMyDetail() {
			if (this.$store.state.userInfo.id == this.videoInfo.userId) {
				this.$login().then((res) => {
					this.$router.push({
						path: '/my',
					});
				});
			} else {
				this.$router.push({
					path: '/othersHome',
					query: {
						userId: this.videoInfo.userId,
					},
				});
			}
		},
		// 关闭评论弹窗
		handleClose() {
			this.drawerShow = false;
		},
		// 当前集数是否需要付费
		handlePay(type) {
			// 自动轮播时判断当前集数属于哪一组
			let arr = [];
			this.numArr.forEach((val, i) => {
				arr[i] = val.name.split('-');
			});
			arr.forEach((m, i) => {
				if (
					this.videoInfo.episodeNumber >= Number(m[0]) &&
					this.videoInfo.episodeNumber <= Number(m[1])
				) {
					this.tabActiveNum = this.numArr[i].name;
					this.videoArray = this.numArr[i].value;
				}
			});
			if (this.videoInfo.episodePay && !this.videoInfo.paid) {
				this.isPlay = true;
				this.$login().then((res) => {
					this.$refs.shortPlayPay.init();
				});
			} else {
				if (type) {
					//暂停时点击播放按钮
					if (this.$refs.videos[0].paused) {
						this.$refs.videos[0].play();
					} else {
						this.$refs.videos[0].pause();
					}
					this.isPlay = !this.isPlay;
				} else {
					//自动轮播到下一集
					this.isPlay = false;
					this.activeIndex = this.videoInfo.id;
					this.$refs.videos[0].play();
				}
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.flex {
	display: flex;
}
.rnui-layer {
	background: #141414;
	flex-direction: column;
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 2000;
	display: none;

	&.rnui-layer-active {
		display: flex;
	}
}
.layer-main {
	flex: 1;
	overflow-y: auto;
	position: relative;
	color: #fff;
	max-width: 100%;
	.playBox {
		position: absolute;
		top: calc(50% - 50px);
		left: calc(50% - 50px);
		z-index: 999;

		i {
			font-size: 40px;
			color: #fff;
		}
	}

	.shortClose {
		font-size: 40px;
		position: absolute;
		left: 20px;
		top: 20px;
		z-index: 9999;
	}

	.video-main {
		display: flex;
		justify-content: center;
		z-index: 10;

		.video {
			width: 100%;
			height: 100vh;
			object-fit: contain;
		}
	}

	.person-info {
		position: absolute;
		left: 20px;
		bottom: 80px;
		font-size: 18px;
		line-height: 40px;
		z-index: 20;
	}
	:deep(.el-slider__runway) {
		background: rgba(255, 255, 255, 0.5);
		margin: 0;
	}
	:deep(.el-slider__bar) {
		background: #fff;
	}
	:deep(.el-slider__button) {
		display: none;
	}
	.control-box {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index: 999;
		.operation-btn {
			height: 54px;
			display: flex;
			align-items: center;
			padding: 0 20px 0 24px;
			.right-box {
				margin-left: auto;
			}
		}
	}
	.active {
		background-color: #ccc;
	}
}

@keyframes zoomIn {
	from {
		transform: scale(0.3);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	to {
		transform: scale(1);
	}
}

.animate__zoomIn {
	animation: zoomIn 0.5s ease-in-out forwards;
}
/* 定义动画 */
@keyframes rtl-drawer-in {
	0% {
		transform: translate(100%, 0px);
	}
	100% {
		transform: translate(0px, 0px);
	}
}

:deep(.el-drawer__header) {
	display: none;
}
.stretch {
	width: 32px;
	height: 48px;
	border-radius: 30px 0px 0px 30px;
	background: #333333;
	border: 1px solid rgba(255, 255, 255, 0.5);
	position: absolute;
	right: 0;
	top: calc(50% - 24px);
	z-index: 999999;
	i {
		color: #8a8a8a;
		font-size: 24px;
		position: absolute;
		top: calc(50% - 12px);
		left: calc(50% - 12px);
	}
}
.drawer {
	background: #fff;
	width: 540px;
	height: 100%;
	animation: rtl-drawer-in 0.3s 1ms;
	position: relative;
	right: 0;
	top: 0;
	bottom: 0;
	.tabs {
		display: flex;
		align-items: center;
		height: 60px;
		border-bottom: 1px solid #ddd;
		padding-left: 34px;
		overflow-x: auto;
		position: relative;
		i {
			position: absolute;
			cursor: pointer;
			color: #72767b;
			font-size: 20px;
			right: 34px;
		}
		.tab_item {
			width: 60px;
			text-align: center;
			cursor: pointer;
			font-size: 18px;
			font-weight: 500;
			line-height: 24px;
			color: #3d3d3d;
			margin-right: 32px;
			white-space: nowrap;
			&.active {
				position: relative;
				color: $theme-color;

				&::after {
					content: '';
					width: 60px;
					height: 3px;
					background-color: $theme-color;
					border-radius: 4px;
					position: absolute;
					bottom: -18px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	.video-info {
		margin-top: 28px;
		.video-title-box {
			padding: 0 34px;
			.title {
				font-size: 20px;
				font-weight: 500;
				color: #333;
			}

			.num {
				font-size: 14px;
				color: #999;
				margin-top: 10px;
			}
			.intro {
				margin-top: 6px;
				font-size: 15px;
				line-height: 20px;
				color: #3d3d3d;
				word-break: break-all;
				width: 100%;
			}
		}

		.btns-main {
			gap: 20px;
			font-size: 12px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: flex-end;

			.btn-item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}

			i {
				color: #999;
				font-size: 24px;
				margin-bottom: 5px;
			}

			.likeActive {
				color: $like-color !important;
			}

			.collectActive {
				color: $collect-color !important;
			}
		}

		.episode-box {
			overflow-y: auto;
			height: 70vh;
			.episode {
				gap: 20px;
				margin-top: 20px;
				padding: 0 34px;
				.coverImage {
					width: 120px;
					height: 80px;
					border-radius: 6px;
					overflow: hidden;
					position: relative;

					.paid,
					.purchased {
						border-radius: 0px 6px 0px 6px;
						text-align: center;
						height: 18px;
						line-height: 18px;
						font-size: 10px;
						color: #ffffff;
						position: absolute;
						right: 0;
						top: 0;
					}

					.paid {
						width: 34px !important;
						background: rgba(255, 108, 39, 0.8) !important;
					}

					.purchased {
						width: 49px;
						background: rgba(26, 172, 0, 0.8);
					}

					.el-image {
						width: 100%;
						height: 100%;
						border-radius: 6px;
						object-fit: cover;
					}

					.episode-pay {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background: rgba(0, 0, 0, 0.3);
						display: flex;
						justify-content: center;
						align-items: center;

						.icon-sohu-suoding {
							font-size: 24px;
							color: rgba(255, 255, 255, 0.7);
						}
					}
				}

				.right {
					flex: 1;

					.episodeNumber {
						font-size: 15px;
						font-weight: 500;
						color: #333;

						i {
							margin-left: 10px;
							color: $theme-color;
						}
					}

					.intro {
						font-size: 13px;
						line-height: 21px;
						color: #666;
					}
				}
			}
		}
	}
}
.likeActive {
	color: $like-color !important;
}

.collectActive {
	color: $collect-color !important;
}
</style>
