import Vue from 'vue';
import dialog from './index.vue';
const DialogConstructor = Vue.extend(dialog);

let instance;
const initInstance = (obj) => {
	instance = new DialogConstructor({
		el: document.createElement('div'),
		data() {
			return {
				...obj,
			};
		},
	});
};

const showPaymentDialog = (obj) => {
	return new Promise((resolve, reject) => {
		initInstance(obj);
		document.body.appendChild(instance.$el);
		//显示组件
		Vue.nextTick(() => {
			instance.showDialog();
		});

		instance.callback = (action) => {
			instance.$el.parentNode.removeChild(instance.$el);
			if (action === 'confirm') {
				resolve();
			} else if (action === 'cancel') {
				reject();
			}
		};
	});
};

Vue.prototype.$pay = showPaymentDialog;
