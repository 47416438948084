import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
const iv = CryptoJS.enc.Utf8.parse('56781234efghabcd'); //偏移量
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import store from '@/store';
import router from '@/router/index.js';
const isYestday = (date) => {
	var yesterday = new Date(new Date() - 1000 * 60 * 60 * 24);
	return (
		yesterday.getYear() === date.getYear() &&
		yesterday.getMonth() === date.getMonth() &&
		yesterday.getDate() === date.getDate()
	);
};
const isYear = (date) => {
	return date.getYear() === new Date().getYear();
};
const formatDateTime = (date) => {
	if (!date) {
		return '';
	}
	var dateObject = new Date(date);
	var y = dateObject.getFullYear();
	var m = dateObject.getMonth() + 1;
	m = m < 10 ? '0' + m : m;
	var d = dateObject.getDate();
	d = d < 10 ? '0' + d : d;
	var h = dateObject.getHours();
	h = h < 10 ? '0' + h : h;
	var minute = dateObject.getMinutes();
	minute = minute < 10 ? '0' + minute : minute;
	var second = dateObject.getSeconds();
	second = second < 10 ? '0' + second : second;
	return y + '/' + m + '/' + d + ' ' + h + ':' + minute + ':' + second;
};

/**
 * 时间日期转换
 * @param date 当前时间，new Date() 格式
 * @param format 需要转换的时间格式字符串
 * @param type 日期显示类型
 * @description format 字符串随意，如 `YYYY-mm、YYYY-mm-dd`
 * @returns 返回拼接后的时间字符串
 */
export const dateFormat = (val, format = 'yyyy-MM-dd', type) => {
	// 获取当前时间
	var now = new Date();
	const nowY = now.getFullYear();
	const nowM = now.getMonth() + 1;
	const nowD = now.getDate();
	// 获取昨天的时间
	now.setDate(nowD - 1);
	const yesterdayY = now.getFullYear();
	const yesterdayM = now.getMonth() + 1;
	const yesterdayD = now.getDate();
	// 获取传入的时间
	const date = new Date(val || new Date());
	let dateStr = format;
	const Y = date.getFullYear();
	const M = date.getMonth() + 1;
	const D = date.getDate();
	const h = date.getHours();
	const m = date.getMinutes();
	const s = date.getSeconds();
	if (nowY == Y && nowM == M && nowD == D && type == 'message') {
		dateStr = dateStr.replace(/YYYY-MM-DD/g, '');
	} else if (
		yesterdayY == Y &&
		yesterdayM == M &&
		yesterdayD == D &&
		type == 'message'
	) {
		dateStr = dateStr.replace(/YYYY-MM-DD/g, '昨天');
	} else {
		dateStr = dateStr.replace(/yyyy|YYYY/g, Y.toString());
		dateStr = dateStr.replace(/MM/g, (M > 9 ? M : '0' + M).toString());
		dateStr = dateStr.replace(/M/g, M.toString());
		dateStr = dateStr.replace(/dd|DD/g, (D > 9 ? D : '0' + D).toString());
		dateStr = dateStr.replace(/d|D/g, D.toString());
	}
	dateStr = dateStr.replace(/HH|hh/g, (h > 9 ? h : '0' + h).toString());
	dateStr = dateStr.replace(/H|h/g, h.toString());
	dateStr = dateStr.replace(/mm/g, (m > 9 ? m : '0' + m).toString());
	dateStr = dateStr.replace(/m/g, m.toString());
	dateStr = dateStr.replace(/SS|ss/g, (s > 9 ? s : '0' + s).toString());
	dateStr = dateStr.replace(/S|s/g, s.toString());
	return dateStr;
};

export default {
	// 时间格式转换
	toTimeText: (timeStamp, simple) => {
		if (!timeStamp) {
			return '';
		}
		var dateTime = new Date(timeStamp);
		var currentTime = Date.parse(new Date()); //当前时间
		var timeDiff = currentTime - dateTime; //与当前时间误差
		var timeText = '';
		if (timeDiff < 86400000 && !isYestday(dateTime)) {
			//今日
			timeText = formatDateTime(dateTime).substr(11, 5);
		} else if (isYestday(dateTime)) {
			//昨天
			timeText = '昨天' + formatDateTime(dateTime).substr(11, 5);
		} else if (isYear(dateTime)) {
			//今年
			timeText = formatDateTime(dateTime).substr(5, simple ? 5 : 14);
		} else {
			//不属于今年
			timeText = formatDateTime(dateTime);
			if (simple) {
				timeText = timeText.substr(2, 8);
			}
		}
		return timeText;
	},

	dateFormat,

	// 获取uuid
	getUuid: () => {
		return uuidv4();
	},

	// 获取html中的img标签
	extractImageSrc(htmlString) {
		if (htmlString) {
			var imgSrcArray = [];
			var imgTagRegex = /<img[^>]+src=['"]([^'"]+)['"][^>]*>/g;
			var match;
			while ((match = imgTagRegex.exec(htmlString)) !== null) {
				var src = match[1];
				imgSrcArray.push(src);
			}
			return imgSrcArray;
		}
	},

	// 将数字转换成万
	convertToTenThousandWithDecimal(number, decimalPlaces = 1) {
		if (number && number >= 10000) {
			const result = number / 10000;
			return result.toFixed(decimalPlaces) + '万';
		}
		return number || 0;
	},
	/**
	 * 去除富文本里面的html标签
	 */
	replaceHtml(val) {
		let str = val;
		if (str) {
			// str = str.replace(/<(?!img)[^>]*>/g, ''); //去除HTML tag 保留img标签
			str = str.replace(/<[^>]*>/g, ''); // 移除所有 HTML 标签
			str = str.replace(/[ | ]*\n/g, '\n'); //去除行尾空白
			str = str.replace(/ /gi, ''); //去掉
			str = str.replace(/nbsp;/gi, ' '); //替换空格
		}
		return str || '';
	},

	/**
	 * 去除富文本里面的html标签(包括圖片)
	 */
	replaceHtmlAll(val) {
		let str = val;
		if (str) {
			str = str.replace(/<\/?[^>]*>/g, ''); //去除HTML tag
			str = str.replace(/[ | ]*\n/g, '\n'); //去除行尾空白
			str = str.replace(/ /gi, ''); //去掉
			str = str.replace(/nbsp;/gi, ' '); //替换空格
		}
		return str || '';
	},

	/**
	 * @description 函数节流
	 * @param {Function} fn 函数
	 * @param {Number} interval 延迟执行毫秒数,默认200
	 */
	throttle(fn, interval = 200) {
		let timer = null;
		return function () {
			if (timer) return;
			timer = setTimeout(() => {
				fn.apply(this, arguments);
				timer = null;
			}, interval);
		};
	},

	/**
	 * @description 函数防抖
	 * @param {Function} fn 函数
	 * @param {Number} interval 延迟执行毫秒数,默认500
	 */
	debounce(fn, interval = 500) {
		let timeout;
		return function () {
			const context = this;
			const args = arguments;
			if (!timeout) {
				fn.apply(context, args);
				timeout = setTimeout(() => {
					timeout = null;
				}, interval);
			}
		};
	},

	/**
	 * 获取用户唯一标识
	 */
	async getBrowserFingerprint() {
		return new Promise(async (resolve, reject) => {
			const fp = await FingerprintJS.load({ screen_resolution: true });
			const result = await fp.get();
			const visitorId = result.visitorId;
			resolve(visitorId);
		});
	},
	// 视频解密
	AES_Decrypt(text, keyText) {
		if (keyText) {
			const key = CryptoJS.enc.Utf8.parse(keyText); //密钥
			const decrypted = CryptoJS.AES.decrypt(text, key, {
				iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});
			let str = decrypted.toString(CryptoJS.enc.Utf8);
			let index = str.lastIndexOf('.');
			return str.substring(0, index) + '.mp4';
		} else {
			return text;
		}
	},
	//加密
	encrypt(data) {
		const srcs = CryptoJS.enc.Utf8.parse(data);
		const key = CryptoJS.enc.Latin1.parse('iHq0Cf0McLN00L0j');
		const encrypted = CryptoJS.AES.encrypt(srcs, key, {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.ZeroPadding,
		});
		return encrypted.toString();
	},
	/**
	 * 根据秒数计算时间
	 */
	dealTime(time) {
		const minute = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		let sec = seconds < 10 ? `0${seconds}` : seconds;
		let minu = '00';
		let hour = '00';
		if (minute >= 60) {
			hour = Math.floor(minute / 60);
			hour = hour < 10 ? `0${hour}` : hour;
			minu = minute % 60;
			minu = minu < 10 ? `0${minu}` : minu;
		} else {
			minu = minute < 10 ? `0${minute}` : minute;
		}
		return `${hour}:${minu}:${sec}`;
	},
	/**
	 * @param list 原始数组
	 * @param splitCount 分割数
	 * @param numArr 按照分割数分成的题
	 * @param numArr 按照分割数分成的标题
	 * @param videoArray 按照分割数分成的视频数组
	 * @param tabActiveNum 当前选中的标题下标
	 * @param historyNum 历史记录中进来的集数
	 */
	//将数据处理成10个一组
	getSplitArray(list, splitCount = 10, historyNum) {
		let numArr, videoArray, tabActiveNum;
		numArr = [];
		for (let i = 0; i < list.length; i += splitCount) {
			let start = i + 1;
			let end = i + splitCount;
			if (end > list.length) {
				end = list.length;
			}
			numArr.push({
				name: start + '-' + end,
				value: list.slice(i, i + splitCount),
			});
		}
		if (historyNum) {
			let arr = [];
			numArr.forEach((val, i) => {
				arr[i] = val.name.split('-');
			});
			arr.forEach((m, i) => {
				if (historyNum >= Number(m[0]) && historyNum <= Number(m[1])) {
					tabActiveNum = numArr[i].name;
					videoArray = numArr[i].value;
				}
			});
		} else {
			videoArray = numArr[0].value;
			tabActiveNum = numArr[0].name;
		}
		return { numArr, videoArray, tabActiveNum };
	},
	// 自己发布的图文，自己的问答不能显示关注按钮。当未登录时默认显示
	handlefollowShow(obj) {
		if (store.getters.isLogin && localStorage.getItem('userInfo') != null) {
			let id = JSON.parse(localStorage.getItem('userInfo')).id;
			return !(id == obj.userId);
		} else {
			return true;
		}
	},
	deepClone(source) {
		if (!source && typeof source !== 'object') {
			throw new Error('error arguments', 'deepClone');
		}
		const targetObj = source.constructor === Array ? [] : {};
		Object.keys(source).forEach((keys) => {
			if (source[keys] && typeof source[keys] === 'object') {
				targetObj[keys] = this.deepClone(source[keys]);
			} else {
				targetObj[keys] = source[keys];
			}
		});
		return targetObj;
	},
	// 跳转游戏详情
	goGameDetails(id) {
		let routeUrl = router.resolve({
			path: '/game/details',
			query: { id },
		});
		window.open(routeUrl.href, '_blank');
	},
};
