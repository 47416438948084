<template>
	<el-container class="chat-page">
		<el-aside width="248px" class="chat-list-box">
			<div class="friendRelated">
				<div
					class="friendRelated_item"
					v-for="item in friendRelated"
					@click="friendRelatedClick(item)"
					:key="item.name"
				>
					<el-image
						style="width: 22px; height: 22px"
						:src="item.icon"
						fit="contain"
					></el-image>
					<div class="friendRelated_item_name">{{ item.name }}</div>
				</div>
			</div>
			<el-scrollbar class="chat-list-items">
				<div v-for="(item, index) in chatUserMsg" :key="item.chatId">
					<chatUserItem
						@chatUserItemClick="chatUserItemClick(item)"
						:item="item"
						:active="chatUserMsgActive"
					></chatUserItem>
				</div>
			</el-scrollbar>
		</el-aside>
		<el-container
			class="chat-box"
			v-if="Object.values(chatUserMsgActive).some((item) => !!item)"
		>
			<el-container>
				<el-header height="56px">
					<messageUser_header
						ref="messageUser_header"
						:chatUserMsgActive="chatUserMsgActive"
						:switchGroup="switchGroup"
						:imDetails="imDetails"
					></messageUser_header>
				</el-header>
				<div class="strange-view" v-if="!imDetails.meFriend&&imDetails.sessionType==='single'">
					<div class="title">对方不是你的好友</div>
					<div class="btn" @click="handleOpenApplyFriend">加好友</div>
				</div>
				<el-main style="padding: 0">
					<el-container
						class="content-box"
						element-loading-spinner="none"
						v-loading="chatMsgLoading && messagePages.pageNum == 1"
						element-loading-background="#f3f3f3"
					>
						<el-main id="el-main" class="im-chat-main">
							<div class="im-chat-box" @scroll="onScroll">
								<ul>
									<div
										class="messageLoading"
										v-if="
											(chatMsgLoading ||
												messagePages.total == messagesList.length) &&
											messagePages.pageNum > 1
										"
									>
										{{
											messagePages.total > messagesList.length
												? '加载中...'
												: '没有更多消息了'
										}}
									</div>
									<div class="im-chat-box-topmsg" v-if="imDetails.groupNotice">
										<p
											v-for="(text, index) in imDetails.groupNotice.split('\n')"
											:key="index"
										>
											{{ text }}
										</p>
									</div>
									<li
										:id="`id_${item.chatId}`"
										v-for="(item, idx) in messagesList"
										:key="item.chatId"
									>
										<chatMessageItem
											@imageLoad="imageLoad"
											:prev="idx == 0 ? null : messagesList[idx - 1]"
											:item="item"
											@errorClick="errorClick"
										>
										</chatMessageItem>
									</li>
									<li id="id_-1"></li>
								</ul>
							</div>
						</el-main>
						<el-footer class="im-chat-footer">
							<chatSendBox
								ref="chatSendBox"
								:chatUserMsgActive="chatUserMsgActive"
								:switchGroup="switchGroup"
								:imDetails="imDetails"
								@sendMessage="sendMessage"
							></chatSendBox>
						</el-footer>
					</el-container>
				</el-main>
			</el-container>
		</el-container>
		<div v-else class="chatMessageEmpty">
			<el-image
				:src="require('@/assets/images/chat/messageEmpty.png')"
			></el-image>
			<div class="empty_text">暂无私信</div>
		</div>

		<!-- 通讯录弹窗 -->
		<friendsDialog ref="friendsDialogRef"></friendsDialog>

		<!-- 添加好友弹窗 -->
		<AddFriendsDialog ref="AddFriendsDialogRef"></AddFriendsDialog>

		<!-- 申请添加好友弹窗 -->
		<ApplicationDialog ref="ApplicationDialogRef"></ApplicationDialog>

		<!-- 建群聊 -->
		<SelectMembersDialog ref="SelectMembersDialogRef"></SelectMembersDialog>
	</el-container>
</template>
<script>
import chatUserItem from './components/chatUserItem.vue';
import chatSendBox from './components/chatSendBox.vue';
import chatMessageItem from './components/chatMessageItem.vue';
import { sendWSPush } from '@/utils/websocket';
import friendsDialog from './components/friendsDialog.vue';
import AddFriendsDialog from './components/userDialog/AddFriendsDialog';
import messageUser_header from './components/messageUser_header';
import SelectMembersDialog from './components/groupDialog/SelectMembersDialog.vue';
import VueEmit from '@/utils/VueEmit';
import ApplicationDialog from '@/views/chat/components/userDialog/ApplicationDialog.vue';
export default {
	components: {
		ApplicationDialog,
		chatUserItem,
		chatSendBox,
		chatMessageItem,
		friendsDialog,
		AddFriendsDialog,
		messageUser_header,
		SelectMembersDialog,
	},
	props: ['show'],
	data() {
		return {
			friendRelated: [
				{ name: '通讯录', icon: require('@/assets/images/chat/Calendar.png') },
				{
					name: '加好友',
					icon: require('@/assets/images/chat/Profile.png'),
				},
				{
					name: '建群聊',
					icon: require('@/assets/images/chat/Chat.png'),
				},
			],
			chatUserMsgActive: {},
			messagesList: [],
			messagePages: {
				pageNum: 1,
				pageSize: 15,
				total: 0,
			},
			chatMsgLoading: false,
			localId: '',
			switchGroup: {},
			imgLoadNum: 0, // 图片已经加载数量
			imgTotal: 0, // 图片总数
			onMessage: false, // 判断是否是推送过来的消息
			imDetails: {},
			isShowChatTriggered: false, // 用于记录事件是否被触发
		};
	},
	watch: {
		message(val) {
			try {
				let msgInfo = JSON.parse(val);
				// 音视频通话不处理
				if (!['voiceCall', 'videoCall'].includes(msgInfo.messageType)) {
					console.log(
						'测试数据反馈',
						this.chatUserMsgActive.receiver.name,
						msgInfo.receiver.name,
					);
					if (
						(msgInfo.receiver.id == this.chatUserMsgActive.receiver?.id ||
							msgInfo.receiver.id == this.chatUserMsgActive.sender?.id ||
							msgInfo.sender.id == this.chatUserMsgActive.receiver?.id ||
							msgInfo.sender.id == this.chatUserMsgActive.sender?.id) &&
						this.chatUserMsgActive.receiver.name == msgInfo.receiver.name
						// (msgInfo.receiver.id == this.chatUserMsgActive.receiver?.id ||
						// (msgInfo.receiver.id == this.chatUserMsgActive.sender?.id)) && (this.chatUserMsgActive.groupUserCount == msgInfo.groupUserCount)
					) {
						this.onMessage = true;
						this.messagesList.push(msgInfo);
						this.$nextTick(() => {
							setTimeout(() => {
								let div = document.getElementById(`id_-1`);
								if (div) {
									div.scrollIntoView({ behavior: 'auto' });
								}
							}, 200);
						});
					}
					if (
						msgInfo.sender.id == this.chatUserMsgActive.receiver?.id &&
						this.show
					) {
						this.chatMessageRead();
					}
					setTimeout(() => {
						this.$store.dispatch('getUserMessageList');
					}, 1000);
				}
			} catch (error) {
				console.log('错误..', error);
			}
		},

		show(val) {
			console.log('val,', val);

			if (val) {
				if (this.chatUserMsg.length > 0 && !this.isShowChatTriggered) {
					this.isShowChatTriggered = true;
					this.chatUserItemClick(this.chatUserMsg[0]);
				}
			}
		},
	},
	computed: {
		message() {
			return this.$store.state.message;
		},
		chatUserMsg() {
			if (
				this.$store.state.messageList.every(
					(item) => item.receiver.id != this.chatUserMsgActive.receiver?.id,
				)
			) {
				this.chatUserMsgActive = {};
			}
			return this.$store.state.messageList;
		},
	},
	provide() {
		return {
			chatUserItemClick: this.chatUserItemClick,
			switchGroupChat: this.switchGroupChat,
			getImDetailsInfo: this.getImDetailsInfo,
		};
	},
	mounted() {
		VueEmit.$on('showChat', (res) => {
			this.chatUserItemClick(res);
		});
	},
	methods: {
		/**
		 * 操作前往添加好友
		 */
		handleOpenApplyFriend() {
			this.$refs.ApplicationDialogRef.open(this.imDetails.friendId);
		},
		// 设置消息为已读
		async chatMessageRead() {
			this.$http.chatMessageRead(
				this.chatUserMsgActive.sessionType,
				this.chatUserMsgActive.receiver?.id,
			);
		},

		// 点击聊天顶部功能
		friendRelatedClick(val) {
			console.log(val);
			switch (val.name) {
				case '通讯录':
					this.$refs.friendsDialogRef.open();
					break;
				case '加好友':
					this.$refs.AddFriendsDialogRef.open();
					break;
				case '建群聊':
					this.$refs.SelectMembersDialogRef.open({
						title: '发起群聊',
					});
					break;
			}
		},

		// 切换关联群
		switchGroupChat(val) {
			console.log(val, '切换群聊');
			this.messagePages.pageNum = 1;
			this.switchGroup = val;
			console.log(this.switchGroup.id);
			this.getMessageList(this.switchGroup.id);
		},

		// 点击左侧用户
		async chatUserItemClick(val) {
			this.messagePages.pageNum = 1;
			this.chatUserMsgActive = val;
			this.switchGroup = {};
			this.getImDetailsInfo();
			this.chatMessageRead();
			let messageList = this.$store.state.messageList;
			messageList.forEach((item) => {
				if (item.receiver.id == val.receiver.id) {
					item.unreadCount = 0;
				}
			});
			this.getMessageList();
			this.$nextTick(() => {
				this.$refs.messageUser_header.messageSetPopoverBox = false;
				if (val.shareId) {
					this.$refs.chatSendBox.showCard(val.shareId, val.shareType);
				} else {
					this.$refs.chatSendBox.showContentCard = false;
				}
			});
		},

		// 获取用户或群详情
		async getImDetailsInfo() {
			let res;
			if (
				this.chatUserMsgActive.sessionType == 'group' ||
				this.chatUserMsgActive.sessionType == 'groupTask'
			) {
				res = await this.$http.imGroupDetails(
					this.chatUserMsgActive.receiver.id,
				);
			} else {
				res = await this.$http.appFriendsDetails(
					this.chatUserMsgActive.receiver.id,
				);
			}
			this.imDetails = { ...res.data, ...this.chatUserMsgActive };
			console.log('imDetails', this.imDetails);
		},

		// 获取对话消息列表
		async getMessageList(id) {
			this.chatMsgLoading = true;
			const res = await this.$http.imChatMessageInnerList({
				sessionType: this.chatUserMsgActive.sessionType,
				receiverId: this.switchGroup.id || this.chatUserMsgActive.receiver.id,
				pageNum: this.messagePages.pageNum,
				pageSize: this.messagePages.pageSize,
			});
			if (this.messagePages.pageNum == 1) {
				this.messagesList = res.data.reverse();
			} else {
				this.messagesList.unshift(...res.data.reverse());
			}
			this.messagePages.total = res.total;
			this.$nextTick(() => {
				if (this.messagePages.total) {
					let id = '';
					if (this.messagePages.pageNum == 1) {
						this.imgLoadNum = 0;
						this.imgTotal = res.data.filter((item) => {
							return item.messageType == 'photo';
						}).length;
						id = 'id_-1';
					} else {
						id = `id_${res.data[res.data.length - 1].chatId}`;
					}
					setTimeout(() => {
						let div = document.getElementById(id);
						if (div) {
							div.scrollIntoView({ behavior: 'auto' });
						}
						this.chatMsgLoading = false;
					}, 50);
				} else {
					this.chatMsgLoading = false;
				}
			});

			console.log('获取的对话消息列表..', this.messagesList);
		},

		// 图片加载完成
		imageLoad() {
			if (this.onMessage) {
				let div = document.getElementById('id_-1');
				if (div) {
					div.scrollIntoView({ behavior: 'auto' });
				}
			} else {
				this.imgLoadNum++;
				if (
					this.imgLoadNum == this.imgTotal &&
					this.messagePages.pageNum == 1
				) {
					let div = document.getElementById('id_-1');
					if (div) {
						div.scrollIntoView({ behavior: 'auto' });
					}
				}
			}
		},

		// 滚动事件
		onScroll(e) {
			if (e.target.scrollTop < 50) {
				if (this.messagePages.total > this.messagesList.length) {
					if (!this.chatMsgLoading) {
						this.messagePages.pageNum++;
						this.getMessageList();
					}
				}
			}
		},

		// 发送失败重发
		errorClick(val) {
			let i = this.messagesList.findIndex(
				(item) => item.localId == val.localId,
			);
			delete val.localId;
			this.messagesList.splice(i, 1);
			this.sendMessage(val);
		},

		// 发送消息
		sendMessage(val) {
			this.localId = this.$util.getUuid();
			console.log(this.chatUserMsgActive);
			sendWSPush({
				localId: this.localId,
				receiverId: this.switchGroup.id || this.chatUserMsgActive.receiver.id,
				sessionType: this.chatUserMsgActive.sessionType,
				...val,
			});
			console.log(
				this.chatUserMsgActive.receiver.id,
				'sendWS msg',
				this.chatUserMsg,
			);
		},
	},
};
</script>
<style lang="scss">
.im-chat-box-topmsg {
	text-align: center;
	margin-bottom: 20px;
	color: #999;
	font-size: 12px;
	padding-top: 20px;
	line-height: 16px;
}
.chat-page {
	height: 100%;
	.strange-view {
		width: 100%;
		height: 60px;
		background-color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px;
		box-sizing: border-box;
		border-top: 1px solid #e7e7e7;
		.title {
		}
		.btn {
			padding: 5px 10px;
			background-color: #ff6c27;
			border-radius: 5px;
			color: #fff;
			cursor: pointer;
		}
	}
	.chat-list-box {
		border-right: 1px solid #e7e7e7;

		.friendRelated {
			height: 57px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e7e7e7;
			position: sticky;
			top: 0;
			background-color: #fff;
			z-index: 10;
			.friendRelated_item {
				color: #999999;
				text-align: center;
				cursor: pointer;
				margin-left: 18px;
				.friendRelated_item_name {
					margin-top: 2px;
					font-size: 12px;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
		.chat-list-items {
		}
	}
	.chat-box {
		display: flex;
		background-color: #f3f3f3;

		.content-box {
			height: 100%;

			.im-chat-main {
				.im-chat-box {
					padding-bottom: 30px;
					box-sizing: border-box;
					height: 100%;
					overflow-y: auto;
					.messageLoading {
						text-align: center;
						margin-top: 20px;
						color: #999;
						font-size: 12px;
					}
				}
			}
		}
		.im-chat-footer {
			padding: 10px 10px 20px;
			box-sizing: border-box;
			height: auto !important;
			box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.02);
		}
	}
	.chatMessageEmpty {
		margin: 220px auto;
		text-align: center;
		.el-image {
			width: 246.68px;
			height: 96px;
		}
		.empty_text {
			color: #999999;
			font-size: 14px;
			line-height: 22px;
			margin-top: 7px;
		}
	}
}
</style>
