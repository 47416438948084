import layoutImageText from '@/views/layout/layoutImageText.vue';
// 内容详情
export default [
	{
		path: '/imageTextDetail',
		component: layoutImageText,
		children: [
			{
				path: '',
				component: (resolve) =>
					require(['@/views/content/detail/imageTextDetail.vue'], resolve),
				meta: { title: '图文详情', mPath: '/focus/imageText/imageText' },
			},
			{
				path: '/askDetail',
				component: (resolve) =>
					require(['@/views/content/detail/askDetail.vue'], resolve),
				meta: {
					title: '问答详情',
					mPath: '/contentPackages/qaDetail/qaDetail',
				},
			},
			{
				path: '/makeMoneyDetail',
				component: (resolve) =>
					require(['@/views/content/detail/taskDetail.vue'], resolve),
				meta: {
					title: '任务详情',
					mPath: '/contentPackages/qaDetail/qaDetail',
				},
			},
			{
				path: '/makeMoney/cashOut',
				component: (resolve) =>
					require(['@/views/makeMoney/cashOut/index.vue'], resolve),
				meta: { title: '提现', mPath: '/contentPackages/wallet/index' },
			},
			{
				path: '/makeMoney/mineOrders',
				component: (resolve) =>
					require(['@/views/makeMoney/mineOrders/index.vue'], resolve),
				meta: { title: '我的订单', mPath: '/makeMoney/taskSquare/index' },
			},
			{
				path: '/makeMoney/customize',
				component: (resolve) =>
					require(['@/views/makeMoney/customize/index.vue'], resolve),
				meta: { title: '私人定制', mPath: '/makeMoney/customization/index' },
			},
			{
				path: '/makeMoney/finance',
				component: (resolve) =>
					require(['@/views/makeMoney/finance/index.vue'], resolve),
				meta: {
					title: '金融赚钱',
					mPath: '/makeMoney/financialEarning/list/index',
				},
			},
			{
				path: '/makeMoney/AITools',
				component: (resolve) =>
					require(['@/views/makeMoney/AITools/index.vue'], resolve),
				meta: { title: 'AI工具', mPath: '/makeMoney/aiChat/index' },
			},
			{
				path: '/inviteFriendsIndex',
				component: () =>
					import('@/views/inviteFriends/inviteFriendsIndex/index'),
				meta: {
					title: '邀请好友',
					mPath: '/contentPackages/invitation/inviteeHomePage',
				},
			},
			{
				path: '/explain',
				component: () => import('@/views/inviteFriends/explain/index'),
				meta: {
					title: '活动说明',
				},
			},
			{
				path: '/investmentFlow',
				component: (resolve) =>
					require(['@/views/investmentFlow/index.vue'], resolve),
				children: [
					{
						path: '/',
						component: (resolve) =>
							require(['@/views/investmentFlow/flow/index.vue'], resolve),
						meta: { title: '投流详情' },
					},
					{
						path: '/investmentFlow/order',
						component: (resolve) =>
							require(['@/views/investmentFlow/order/index.vue'], resolve),
						meta: { title: '投流订单' },
					},
				],
			},
			{
				path: '/makeMoney/finance/detail',
				component: (resolve) =>
					require(['@/views/makeMoney/finance/detail/index.vue'], resolve),
				meta: {
					title: '金融详情',
					mPath: '/makeMoney/financialEarning/details/index',
				},
			},
			{
				path: '/makeMoney/applyCard/index',
				component: (resolve) =>
					require(['@/views/makeMoney/applyCard/index.vue'], resolve),
				meta: { title: '分享链接' },
			},
			{
				path: '/makeMoney/distributionDetail',
				component: (resolve) =>
					require([
						'@/views/makeMoney/mineOrders/components/distributionDetail.vue',
					], resolve),
				meta: { title: '短剧分销' },
			},
			{
				path: '/makeMoney/businessOrsersDetail',
				component: (resolve) =>
					require([
						'@/views/makeMoney/mineOrders/components/businessOrsersDetail.vue',
					], resolve),
				meta: { title: '商单详情' },
			},
			{
				path: '/novel/novelDetails',
				component: (resolve) =>
					require([
						'@/views/novel/novelDetails/index.vue',
					], resolve),
				meta: { title: '小说详情' },
			},
			{
				path: '/novel/commentPage',
				component: (resolve) =>
					require([
						'@/views/novel/commentPage/index.vue',
					], resolve),
				meta: { title: '评论列表' },
			},
			{
				path: '/novel/readerPage',
				component: (resolve) =>
					require([
						'@/views/novel/readerPage/index.vue',
					], resolve),
				meta: { title: '阅读器' },
			},
		],
	},
];
