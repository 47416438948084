var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],class:[
		'unlike-report-box',
		{ article: _vm.type == 'Article' },
		{ answer: _vm.type == 'Question' },
	]},[_c('div',{staticClass:"unlike-report-item",on:{"click":function($event){return _vm.handleOpt('disLikeSure')}}},[_vm._m(0),_c('p',{staticClass:"name"},[_vm._v("不感兴趣")])]),_c('div',{staticClass:"unlike-report-item",on:{"click":function($event){return _vm.handleOpt('report')}}},[_vm._m(1),_c('p',{staticClass:"name"},[_vm._v("举报")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box"},[_c('i',{staticClass:"icon-sohu-unlike"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-box"},[_c('i',{staticClass:"icon-sohu-report"})])
}]

export { render, staticRenderFns }