<template>
	<dialogBox
		appendToBody
		width="500px"
		v-model="dialogVisible"
		class="SetLinkDialog"
		title="设置渠道"
		showBottomBtn
		confirmText="新增渠道"
		@confirm="addEditClick($event)"
	>
		<scroll-box
			emptyContent="暂无渠道"
			height="600px"
			:total="channelList.length"
		>
			<div class="topTitle">通用进群链接</div>
			<div class="commonLink">
				<span class="linkUrl line-1" :title="details.groupLink">{{
					details.groupLink
				}}</span>
				<span class="copyTxt" @click="copyClick('')">复制链接</span>
			</div>
			<div class="topTitle">进群设置</div>
			<div class="groupSet" @click="comeInSetClick">
				<div>进群前可浏览群消息</div>
				<div>去设置<i class="icon-sohu-xiayiyeqianjinchakangengduo"></i></div>
			</div>
			<div class="topTitle">进群渠道</div>
			<div class="linkList" v-if="channelList.length">
				<div class="link_item" v-for="(item, i) of channelList" :key="item.id">
					<div class="item_top">
						<div class="item_top_left">
							<div class="item_title">{{ item.channelName }}</div>
							<div class="item_number cur" @click="showMembers(item)">进群人数{{ item.channelUserCount }}</div>
						</div>
						<div class="item_top_right">
							<i
								class="icon-sohu-fuzhi"
								@click="copyClick(item.channelUrl)"
							></i>
							<i
								class="icon-sohu-huida"
								@click="addEditClick($event, item.id)"
							></i>
						</div>
					</div>
					<div :class="[{ expandRow: item.expand }, 'channelNote']">
						{{ item.channelNote }}
					</div>
					<div class="expand" @click="expandClick(i)" v-if="item.channelNote">
						{{ item.expand ? '收起' : '查看备注' }}
						<i class="el-icon-arrow-up" v-if="item.expand"></i>
						<i class="el-icon-arrow-down" v-else></i>
					</div>
					<div v-else class="expand">暂无备注</div>
				</div>
			</div>
		</scroll-box>

		<!-- 新增\编辑弹窗 -->
		<addLinkDialog ref="addLinkDialogRef"></addLinkDialog>
		<!-- 进群设置弹窗 -->
		<GroupComeInSettingDialog ref="GroupComeInSettingDialogRef" />

		<!-- 查看进群人员列表 -->
		<GroupMembers ref="GroupMembersRef"></GroupMembers>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import addLinkDialog from './addLinkDialog.vue';
import GroupComeInSettingDialog from './GroupComeInSettingDialog.vue';
import GroupMembers from './BindFriendsDialog/GroupMembers.vue';
export default {
	components: { dialogBox, addLinkDialog, GroupComeInSettingDialog,GroupMembers },
	data() {
		return {
			dialogVisible: false,
			channelList: [],
			pageNum: 1,
			details: {},
			inviteCalculate: '',
			loading: false,
			show: false,
		};
	},
	provide() {
		return {
			getChannelList: this.getList,
		};
	},
	methods: {
		open(details, inviteCalculate) {
			this.details = details;
			this.inviteCalculate = inviteCalculate;
			this.dialogVisible = true;
			this.getList();
		},

		// 复制按钮
		copyClick(url) {
			this.$copyText(url || this.details.groupLink).then((e) => {
				this.$message({
					message: '链接复制成功',
					type: 'success',
				});
			});
		},

		// 获取列表
		async getList() {
			const res = await this.$http.imGroupChannelList({
				groupId: this.details.id,
			});
			this.channelList = res.data || [];
		},

		// 新增/编辑渠道
		addEditClick(close, id) {
			if (!id) {
				close(true);
			}
			this.$refs.addLinkDialogRef.open(this.details.id, id);
		},

		// 查看备注
		expandClick(i) {
			this.$set(this.channelList[i], 'expand', !this.channelList[i].expand);
		},

		//建群设置
		comeInSetClick() {
			this.$refs.GroupComeInSettingDialogRef.open(this.details);
		},

		// 显示人员列表
		async showMembers(val) {
			const res = await this.$http.imGroupInviteUserList({
				channelType: 'common',
				groupId: this.details.id,
				channelCode: val.channelCode
			});
			if (!res.data[0].inviteList) return;
			this.$refs.GroupMembersRef.open(res.data[0].inviteList);
		},
	},
};
</script>
<style lang="scss">
.SetLinkDialog {
	.topTitle {
		color: #333333;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		margin-bottom: 8px;
		margin-top: 20px;
	}
	.groupSet {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
	}
	.commonLink {
		background: rgba(255, 108, 39, 0.05);
		border-radius: 4px;
		height: 36px;
		line-height: 36px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		color: #333333;
		padding: 0 12px;
		box-sizing: border-box;
		.linkUrl {
			max-width: 310px;
		}
		.copyTxt {
			color: #ff6c27;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.linkList {
		display: grid;
		row-gap: 8px;
		.link_item {
			background: #fafafa;
			border-radius: 4px;
			padding: 10px 12px;
			box-sizing: border-box;

			.item_top {
				margin-bottom: 8px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.item_top_left {
					display: flex;
					align-items: center;
					.item_title {
						color: #333333;
						font-size: 14px;
						margin-right: 4px;
						line-height: 22px;
					}
					.item_number {
						background: rgba(255, 108, 39, 0.1);
						width: 85px;
						height: 18.4px;
						border-radius: 2px;
						color: #ff6c27;
						font-size: 10px;
						line-height: 18.4px;
						text-align: center;
					}
				}

				.item_top_right {
					i {
						font-size: 16px;
						color: #999999;
						cursor: pointer;
						&:hover {
							opacity: 0.8;
						}
						& + i {
							margin-left: 20px;
						}
					}
				}
			}
			.channelNote {
				color: #666666;
				left: 12px;
				line-height: 18px;
				margin-bottom: 4px;
				max-height: 0px;
				transition: max-height 0.8s;
				overflow: hidden;
				&.expandRow {
					max-height: 70px;
				}
			}
			.expand {
				color: #999999;
				font-size: 10px;
				display: flex;
				align-items: center;
				cursor: pointer;
				line-height: 14px;
				&:hover {
					opacity: 0.8;
				}
				i {
					margin-left: 4px;
				}
			}
		}
	}
}
</style>
