<template>
    <div class="mcn-card">
		<div class="card_title">{{ content.title }}</div>
		<div class="card_time">{{ content.updateTime }}</div>
		<div class='card_desc'>{{ content.content }}</div>
    </div>
</template>

<script>
export default {
    props:{
        content:{
            typr:Object
        }
    },
    data(){
        return {
            
        };
    }
}
</script>

<style lang="scss" scoped>
.mcn-card {
    width: 100%;
	height: 100px;
	background-color: #FFF;
	border-radius: 8px;
    .card_title {
    color: #333333;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  .card_time {
    color: #555555;
    line-height: 16px;
    font-size: 10px;
    margin-bottom: 20px;
  }
  .card_desc {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
