<template>
	<dialog-box
		confirmText="新增禁言"
		showBottomBtn
		appendToBody
		v-model="dialogVisible"
		@confirm="addClick"
		class="GroupProhibitionDialog"
	>
		<div slot="header_title">群禁言</div>
		<div class="set_row">
			<div>
				全部禁言
				<span class="subtitle"> 开启后，仅群主和群管理员允许发言</span>
			</div>
			<el-switch
				@change="forbidChange"
				v-model="details.forbid"
				active-color="#13ce66"
			>
			</el-switch>
		</div>
		<div class="rowLine"></div>
		<div class="set_row">
			<div>
				部分禁言人员
				<span class="subtitle"> （以下成员不允许发言）</span>
			</div>
			<el-button
				:disabled="!userList.length"
				type="text"
				size="mini"
				@click="UnlockingAll"
				>全部解禁</el-button
			>
		</div>
		<div class="userList">
			<AddressBook_row
				v-for="item of userList"
				:key="item.id"
				:item="item"
				type="群禁言"
			></AddressBook_row>
			<q-empty content="暂无禁言" v-if="!userList.length"></q-empty>
		</div>

		<!-- 选择群对话框 -->
		<SelectMembersDialog ref="SelectMembersDialogRef"></SelectMembersDialog>
	</dialog-box>
</template>
<script>
import AddressBook_row from '@/views/chat/components/AddressBook_row.vue';
import DialogBox from '@/components/dialogBox.vue';
import SelectMembersDialog from './SelectMembersDialog.vue';
export default {
	components: { DialogBox, AddressBook_row, SelectMembersDialog },
	data() {
		return {
			dialogVisible: false,
			userList: [],
			details: {},
			id: '',
		};
	},
	created() {},
	provide() {
		return {
			imGroupForbidList: this.getList,
		};
	},
	methods: {
		async open(id) {
			this.id = id;
			this.dialogVisible = true;
			const res = await this.$http.imGroupDetails(this.id);
			this.details = res.data;
			this.getList();
		},

		// 获取列表
		async getList() {
			const res = await this.$http.imGroupForbidUsers(this.id);
			this.userList = res.data || [];
		},

		// 切换禁言状态
		async forbidChange(val) {
			console.log(val);
			const res = await this.$http.editImGroup(this.details);
			if (res.code == 200) {
				this.$message.success('切换成功');
			}
		},

		// 新增群成员
		addClick(close) {
			this.$refs.SelectMembersDialogRef.open({
				title: '新增禁言',
				id: this.details.id,
			});
			close(true);
		},

		// 全部解禁
		UnlockingAll() {
			this.$confirmDialog({
				content: '是否全部解禁？',
				beforeClose: async (state, close) => {
					if (state == 'confirm') {
						const res = await this.$http.imGroupBatchSetforbid({
							forbid: false,
							groupId: this.id,
							userIds: this.userList.map((item) => item.userId),
						});
						if (res.code == 200) {
							this.getList();
							this.$message.success('解除成功');
							close();
						}
					}
				},
			});
		},
	},
};
</script>
<style lang="scss">
.GroupProhibitionDialog {
	.set_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		.subtitle {
			color: rgba(0, 0, 0, 0.26);
		}
		.el-button {
			padding: 0;
		}
		&:last-child {
			margin-top: 24px;
		}
		&:first-child {
			margin-top: 27px;
		}
	}
	.rowLine {
		margin: 16px 0;
		height: 1px;
		background-color: #e7e7e7;
	}
	.userList {
		margin-top: 24px;
	}
}
</style>
