<template>
	<div class="q_checkbox">
		<div
			:class="[{ disabled: disabled }, 'checkBox']"
			:style="{ width: size, height: size }"
			@click="checkedClick"
		>
			<el-image
				v-if="checked"
				:src="require('@/assets/images/checked.png')"
			></el-image>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: '24px',
		},
		value: {
			type: Boolean,
		},
	},
	watch: {
		value(val) {
			this.checked = val;
		},
	},
	data() {
		return {
			checked: this.value,
		};
	},
	methods: {
		// 选择
		checkedClick() {
			if (this.disabled) return;
			this.checked = !this.checked;
			this.$emit('input', this.checked);
		},
	},
};
</script>
<style lang="scss">
.q_checkbox {
	.checkBox {
		background: #f3f3f3;
		border-radius: 50%;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		&:hover {
			opacity: 0.8;
		}
		.el-image {
			width: 100%;
			height: 100%;
		}
		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}
</style>
