<template>
	<dialog-box
		confirmText="新增管理员"
		showBottomBtn
		appendToBody
		v-model="dialogVisible"
		class="SetAdmin"
		@confirm="addClick"
	>
		<div slot="header_title">设置管理员</div>
		<div class="groupList">
			<address-book_row
				v-for="item of userList"
				:key="item.id"
				:item="item"
				type="设置管理员"
			></address-book_row>

			<q-empty
				height="200px"
				v-if="userList.length == 0"
			></q-empty>
		</div>

		<!-- 选择成员 -->
		<SelectMembersDialog ref="SelectMembersDialog"></SelectMembersDialog>
	</dialog-box>
</template>
<script>
import AddressBook_row from '../AddressBook_row.vue';
import DialogBox from '@/components/dialogBox.vue';
import SelectMembersDialog from './SelectMembersDialog.vue';
export default {
	components: { DialogBox, AddressBook_row, SelectMembersDialog },
	data() {
		return {
			dialogVisible: false,
			userList: [],
			id: '',
		};
	},
	provide() {
		return {
			groupAdminList: this.open,
		};
	},
	methods: {
		async open(id) {
			this.id = id;
			this.dialogVisible = true;
			const res = await this.$http.imGroupUserAll({
				groupId: id,
				permissionType: 'group_admin',
			});
			this.userList = res.data;
		},

		// 确定按钮
		addClick(close) {
			this.$refs.SelectMembersDialog.open({
				title: '新增管理员',
				id: this.id,
			});
			close(true);
		},
	},
};
</script>
<style lang="scss">
.SetAdmin {
	.groupList {
		margin-top: 24px;
	}
}
</style>
