<template>
	<dialogBox appendToBody v-model="dialogVisible" class="AddFriendsDialog">
		<div slot="header_title" style="display: flex; align-items: center">
			<el-image
				style="margin-right: 8px"
				:src="require('@/assets/images/chat/Calendar.png')"
			></el-image>
			添加好友
		</div>
		<el-input
			v-model="searchInput"
			placeholder="请输入要查找的内容"
			class="searchInput"
			clearable
			@change="searchClick"
		>
			<i @click="searchClick" slot="suffix" class="icon-sohu-sousuo1"></i
		></el-input>
		<scroll-box
			:loading="loading"
			:total="userList.length"
			height="340px"
			style="margin-top: 20px"
			@scrolltolower="userLoad"
		>
			<template>
				<AddressBook_row
					v-for="item of userList"
					type="添加好友"
					:item="item"
					:key="item.id"
				></AddressBook_row>
			</template>
		</scroll-box>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
import AddressBook_row from '../AddressBook_row.vue';
export default {
	components: { dialogBox, AddressBook_row },
	data() {
		return {
			dialogVisible: false,
			searchInput: '',
			userList: [],
			pageNum: 1,
			total: 0,
			loading: false,
		};
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},

		// 搜索按钮
		searchClick() {
			this.pageNum = 1;
			this.getUserList();
		},

		// 获取用户列表
		async getUserList() {
			this.loading = true;
			const res = await this.$http.userPage({
				keyword: this.searchInput,
				pageNum: this.pageNum,
				pageSize: 10,
				queryFriend: true,
			});
			if (this.pageNum == 1) {
				this.userList = res.data;
			} else {
				this.userList = this.userList.concat(res.data);
			}
			this.total = res.total;
			this.loading = false;
		},

		// 好友弹窗滚动到底部
		userLoad() {
			if (this.total > this.userList.length) {
				this.pageNum++;
				this.getUserList();
			}
		},
	},
};
</script>
<style lang="scss">
.AddFriendsDialog {
	.searchInput {
		margin-top: 24px;
		border-radius: 4px;
		height: 36px;
		position: relative;
		.el-input__inner {
			border: 0 solid #000;
			background: #f3f3f3;
			padding-left: 10px;
			padding-right: 40px;

			&::placeholder {
				color: #999999;
				font-size: 14px;
			}
		}
		.el-input__suffix {
			.el-input__clear {
				position: absolute;
				right: 30px;
			}
			.icon-sohu-sousuo1 {
				right: 5px;
				position: absolute;
				top: 10px;
				font-size: 20px;
				color: #5d5d5d;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}
</style>
