<template>
	<div class="InteractiveMessages_card">
		<div v-for="item of list" :key="item.id" class="card_row" @click="handleToDetail(item)">
			<div class="leftUserInfo">
				<div class="userAvatar">
					<div class="point" v-if="!item.readState"></div>
					<el-avatar :src="item.operatorUserAvatar"></el-avatar>
					<el-image class="typeIcon" :src="iconFilter(item.type)"></el-image>
				</div>
				<div class="userData">
					<div class="username">{{ item.operatorUserName }}</div>
					<div class="content">
						{{ ["Question"].includes(item.objType) ? handleInteractContent(item.content) : item.content }}
					</div>
					<div class="time">{{ item.createTime }}</div>
				</div>

			</div>
			<el-image
				:src="handleCoverImage(item)"
				class="rightImg"
			></el-image>
		</div>
		<videoDetail
			ref="videoDetail"
			:detailItem="videoModel"
			@detailClose="detailClose"
		></videoDetail>
	</div>
</template>
<script>
import videoDetail from "@/views/content/detail/videoDetail.vue";

export default {
	components: { videoDetail },
	props: ["list"],
	data() {
		return {
			videoModel: {},
		};
	},
	methods: {
		detailClose() {

		},
		/**
		 * 操作前往详情
		 * @param model
		 */
		handleToDetail(model) {
			switch (model.topType) {
				case "Video":
					this.videoModel = { id: model.topCode };
					this.$refs.videoDetail.videoDetailVisiable = true;
					break;
				case "Article":
					//跳转
					const articleUrl = this.$router.resolve({
						path: "/imageTextDetail",
						query: {
							id: model.topCode,
						},
					}).href;
					window.open(articleUrl, "_blank");
					break;
				case "Question":
					const questionUrl = this.$router.resolve({
						path: "/askDetail",
						query: {
							id: model.topCode,
						},
					}).href;
					window.open(questionUrl, "_blank");
					break;
			}
		},
		/**
		 * 处理互动消息特殊文字
		 * @param {Object} value
		 */
		handleInteractContent(value) {
			const imgRegex = /(https?:\/\/[^\s]+\.(jpg|jpeg|gif|png))/gi;
			if (imgRegex.test(value)) {
				return this.$util.replaceHtmlAll(value) + "[图片]";
			} else {
				return this.$util.replaceHtmlAll(value);
			}
		},
		/**
		 * 处理背景图
		 * @param model
		 */
		handleCoverImage(model) {
			switch (model.objType) {
				case "Question":
					return model.objCoverImage
						? model.objCoverImage
						: "https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/09/20/2d5c84f3caa74c4b93fd558262e9b764_126x168.png";
				case "Answer":
					return model.objCoverImage
						? model.objCoverImage
						: "https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/09/20/2d5c84f3caa74c4b93fd558262e9b764_126x168.png";
				default:
					return model.objCoverImage;
			}
		},
		// 图标过滤
		iconFilter(val) {
			switch (val) {
				case "like":
					return require("@/assets/images/chat/like.png");
				case "commentReceive":
					return require("@/assets/images/chat/comment.png");
				case "commentSend":
					return require("@/assets/images/chat/comment.png");
				case "questionReceive":
					return require("@/assets/images/chat/comment.png");
				case "collect":
					return require("@/assets/images/chat/collection.png");
			}
		},
	},
};
</script>
<style lang="scss">
.InteractiveMessages_card {
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  display: grid;
  row-gap: 24px;

  .card_row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftUserInfo {
      flex: 1;
      display: flex;
      padding-right: 32px;

      .userAvatar {
        position: relative;
        width: 48px;
        height: 48px;
        margin-right: 10px;

        .point {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #FF6C27;
          position: absolute;
          left: -15px;
          top: calc(50% - 4px);
        }

        .el-avatar {
          width: 48px;
          height: 48px;
        }

        .typeIcon {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 22px;
          height: 22px;
          transform: translate(4px, 4px);
        }
      }

      .userData {
        .username {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: #3d3d3d;
        }

        .content {
          max-width: 250px;
          color: #555555;
          font-size: 12px;
          line-height: 18px;
          margin: 2px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .time {
          color: #bcbcbc;
          font-size: 10px;
          line-height: 16px;
        }
      }
    }

    .rightImg {
      border-radius: 4px;
      width: 42px;
      height: 56px;
    }
  }
}
</style>
