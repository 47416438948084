import { get, postFile, postJson } from "@/utils/request";
export default {
  // 首页视频列表
  getContentVideoList: (data) => {
    return get(`/app/api/content/video/list`, data);
  },
  // 获取视频详情
  getContentVideoDetail: (data) => {
    return get(`/app/api/content/video/${data}`);
  },
  // 获取视频评论列表
  getCommentList: (data) => {
    return get(`/app/api/common/comment/list`, data);
  },
  // 点赞或取消
  contentMainLike: (data) => {
    return postJson(`/app/operate/like`, data);
  },
  // 关注-取消关注
  follow: (data) => {
    return postJson(`/app/operate/follow`, data);
  },
  // 收藏-取消收藏
  collect: (data) => {
    return postJson(`/app/operate/collect`, data);
  },
  // 不感兴趣
  dislikeInsertByDislike: (data) => {
    return postJson(`/app/dislike/insertByDislike`, data);
  },
  // 发表评论
  commentPost: (data) => {
    return postJson(`/app/comment`, data);
  },

  //问题列表
  questionList: (data) => {
    return get(`/app/api/question/list`, data);
  },
  //问题详情
  questionDetail: (data) => {
    return get(`/app/api/question/detail/${data}`);
  },
  // 回答列表
  questionAnswerList: (data) => {
    return get(`/app/api/question/answer/list`,data);
  },
  // 新增回答
  questionAnswer: (data) => {
    return postJson(`/app/question/answer`,data);
  },
  // 图文列表
  contentArticleList: (data) => {
    return get(`/app/api/content/article/list`, data);
  },

  // 图文详情
  contentArticleDetail: (data) => {
    return get(`/app/api/content/article/${data}`);
  },

  // 获取分类列表
  getCategoryTree: (data) => {
    return get("/app/api/common/category/tree", data);
  },
  // 用户列表
  contentUserList: (data) => {
    return get(`/app/api/common/user/page`, data);
  },
  // 任务列表
  contentTaskList: (data) => {
    return get(`/busy-order/app/busy/task/task/page`, data);
  },
};
