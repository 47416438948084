<template>
	<div class="subType_system">
		<div class="card_title">{{ item.title }}</div>
		<div class="card_time">{{ item.updateTime }}</div>
		<div class="card_desc" v-html="handleDescFilter(item,content.content.keyWord)"></div>
		<div class="card_desc" v-if="['personalAuthNotPass'].includes(item.content.type)">
			未通过原因：<span class="reject">{{ content.content.rejectReason }}</span>
		</div>
		<!-- 身份信息展示内容 -->
		<div class="info" v-if="item.content.content.idCard">
			<div class="item">
				<div class="title">姓&emsp;&emsp;名：</div>
				<div class="value">{{
						nameFilter(item.content.content.userName)
					}}</div>
			</div>
			<div class="item">
				<div class="title">身份证号：</div>
				<div class="value">{{ idFilter(item.content.content.idCard) }}</div>
			</div>
			<div class="item">
				<div class="title">手&thinsp;&nbsp;机&thinsp;&nbsp;号：</div>
				<div class="value">{{
						phoneFilter(item.content.content.phone)
					}}</div>
			</div>
		</div>
		<!-- 举报内容展示 -->
		<div
			class="report"
			v-if="['reportPass', 'reportRefuse'].includes(item.content.type)"
		>
			<div class="item">
				<div class="title">举报内容：</div>
				<div class="value">{{ item.content.content.contentTitle }}</div>
			</div>
			<div class="item">
				<div class="title">举报时间：</div>
				<div class="value">{{ item.content.content.time }}</div>
			</div>
			<div class="item">
				<div class="title">举报类型：</div>
				<div class="value">{{ item.content.content.typeInfo }}</div>
			</div>
			<div class="item">
				<div class="title">举报结果：</div>
				<div class="value">{{ item.content.content.result }}</div>
			</div>
			<div class="item" v-if="item.content.content.rejectReason">
				<div class="title">原&emsp;&emsp;因：</div>
				<div class="value">{{ item.content.content.rejectReason }}</div>
			</div>
		</div>
		<!-- 内容下架展示 -->
		<div class="report" v-if="['report','reportOffShelf'].includes(item.content.type)">
			<div class="item">
				<div class="title">内容标题：</div>
				<div class="value">{{ item.content.content.contentTitle }}</div>
			</div>
			<div class="item">
				<div class="title">举报时间：</div>
				<div class="value">{{ item.content.content.time }}</div>
			</div>
			<div class="item">
				<div class="title">笔记状态：</div>
				<div class="value">{{ item.content.content.status }}</div>
			</div>
			<div class="item" v-if="item.content.content.rejectReason != ''">
				<div class="title">{{ notPassTile() }}</div>
				<div class="value">{{ item.content.content.rejectReason }}</div>
			</div>
		</div>
		<!-- 行业申请拒绝 -->
		<div
			class="report-text"
			v-if="['industryApplyNotPass'].includes(item.content.type)"
		>
			<div class="item">
				<div class="title">{{ notPassTile() }}</div>
				<div class="value">{{ item.content.content.rejectReason }}</div>
			</div>
		</div>
		<!-- 审核通过后展示文本 -->
		<div
			class="pass-tip"
			v-if="item.content.content.linkTitle || item.content.content.linkUrl && !['interview'].includes(item.content.type)"
		>
			<div class="content" v-if="item.content.content.linkTitle">
				{{ item.content.content.linkTitle }}
			</div>
			<div class="link" v-if="item.content.content.linkUrl" @click.stop="handleToLink">
				{{ item.content.content.linkUrl }}
			</div>
		</div>
		<!-- 意见反馈 -->
		<div class="interview" v-if="['interview'].includes(item.content.type)">
			<div class="item">
				<span>链接地址：</span>
				<span class="link" @click.stop="handleToLink">{{ item.content.content.linkUrl }}</span>
			</div>
			<div class="item" v-if="item.content.content.rejectReason">
				<span>未通过原因：</span>
				<span class="reject">{{ item.content.content.rejectReason }}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['item'],
	data() {
		return {};
	},
	computed: {
		content() {
			return this.item.content
		},
	},
	methods: {
		/**
		 * 前往链接
		 */
		handleToLink(){
			window.open(this.item.content.content.linkUrl,'_blank')
		},
		/**
		 * 处理手机号
		 * @param {Object} value 字符
		 */
		phoneFilter(value) {
			return `${value.substring(0, 3)}****${value.substring(value.length - 4)}`;
		},
		/**
		 * 处理身份证号
		 * @param {Object} value 字符
		 */
		idFilter(value) {
			return `${value.substring(0, 2)}**************${value.substring(
				value.length - 2,
			)}`;
		},
		/**
		 * 处理姓名
		 * @param {Object} value 字符
		 */
		nameFilter(value) {
			return `${value.substring(0, 1)}*${value.substring(value.length - 1)}`;
		},
		notPassTile() {
			switch (this.item.content.type) {
				case 'report':
					return '违规原因：';
				case 'industryApplyNotPass':
					return '未通过原因：';
				case 'reportOffShelf':
					return "下架原因："
			}
		},
		/**
		 * 处理卡片内容
		 * @param {Object} model 卡片数据
		 * @param {Array<String>} keyWord
		 */
		handleDescFilter(model, keyWord) {
			let str = model.content.content.desc;
			if (!keyWord) return str;
			let color;
			switch (model.type) {
				case 'roleAuth':
					color = keyWord.length === 1 ? ['#FF4F0F'] : ['#FF4F0F', '#0A7AFF'];
					break;
				case 'industryApply':
					color = keyWord.length === 1 ? ['#FF4F0F'] : ['#FF4F0F', '#0A7AFF'];
					break;
				case 'personalAuth':
					color = ['#FF0000'];
					break;
				case 'account':
					color = ['#FF4F0F'];
					break;
			}
			keyWord.forEach((v, i) => {
				str = str.replace(v, this.handleContentFilter(model.type, color[i], v));
			});
			return str;
		},
		/**
		 * 处理关键字内容
		 * @param {Object} type 类型
		 * @param {Object} color 颜色
		 * @param {Object} value 值
		 */
		handleContentFilter(type, color, value) {
			switch (type) {
				case 'roleAuth':
					return `<text style="color:${color}">[${value}]</text>`;
				default:
					return `<text style="color:${color}">${value}</text>`;
			}
		},
	},
};
</script>
<style lang="scss">
.subType_system {
	width: 100%;
  box-sizing: border-box;
	.card_title {
		color: #333333;
		font-size: 15px;
		font-weight: 500;
		line-height: 20px;
	}
	.card_time {
		color: #555555;
		line-height: 16px;
		font-size: 10px;
		margin-bottom: 20px;
	}
	.card_desc {
		font-size: 12px;
		line-height: 16px;
		.reject {
			color: #FF0000;
		}
	}
	.certificationDetails {
		background: #f3f3f5;
		border-radius: 4px;
		padding: 8px;
		box-sizing: border-box;
		margin-top: 8px;
		display: grid;
		grid-row-gap: 6px;
		.certificationDetails_row {
			color: #555555;
			font-size: 12px;
			display: flex;
			align-items: center;
			line-height: 18px;
			.certificationDetails_title {
				text-align-last: justify;
				width: 48px;
			}
		}
	}
  .info,
	.report {
    width: 100%;
    height: fit-content;
    margin: 8px 0;
    background-color: #f3f3f5;
    border-radius: 4px;
    padding: 8px 9px 4px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
		box-sizing: border-box;
    .item {
      display: flex;
      color: #555555;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 4px;
      .value {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
				max-width: 400px;
      }
    }
	}
  .report-text {
    margin-top: 20px;
    .item {
      display: flex;
      color: #555555;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 4px;
      .value {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .pass-tip {
    margin-top: 20px;
    font-size: 12px;
    .content {
      color: rgba(0, 0, 0, 0.9);
    }
    .link {
      color: #0a7aff;
			line-height: 24px;
    }
  }
  .interview {
		margin-top: 10px;
    display: grid;
    gap: 4px;
    .item {
      font-size: 12px;
      color: #333333;
      line-height: 18px;
      span {
        &.link {
          color: #0a7aff;
        }
        &.reject {
          color: #ff0000;
        }
      }
    }
  }
	.text_info {
		line-height: 18px;
		font-size: 12px;
		color: #333333;
	}
	.el-link {
		line-height: 18px;
		margin-top: 2px;
	}
}
</style>
