<template>
	<div class="goTop-wrap">
		<div
			ref="goTop"
			@click="gotoTop"
			class="goTop"
			v-if="activeShow"
			:style="{ right: right }"
		>
			<i class="icon-sohu-lujing"></i>
		</div>
	</div>
</template>

<script>
import VueEmit from '@/utils/VueEmit';
export default {
	data() {
		return {
			//默认关闭
			activeShow: false,
			right: 0,
			grandparentEl: null,
		};
	},

	// 监听,当路由发生变化的时候执行
	watch: {
		$route: {
			handler(val, oldVal) {
				console.log(val);
				if (val.path === '/home') {
					this.right = '30%';
				} else {
					this.right = '60'+'px';
				}
			},
			// 深度观察监听
			deep: true,
			immediate: true,
		},
	},
	methods: {
		getGrandparentEl() {
			let parent = this.$parent;
			while (parent && parent.$parent) {
				parent = parent.$parent;
			}
			return parent ? parent.$el : null;
		},

		gotoTop() {
			this.$emit('gotoTop');
		},

		handleScroll(e) {
			this.activeShow = e.target.scrollTop > 1200;
			this.height = e.target.scrollTop;
		},
	},
	mounted() {
		//监听滚动条
		VueEmit.$on('scroll', (event) => {
			this.handleScroll(event);
		});
	},
};
</script>

<style lang="scss" scoped>
.goTop {
	position: fixed;
	width: 60px;
	height: 60px;
	background: #ffffff;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	align-items: center;
	display: flex;
	justify-content: center;
	cursor: pointer;
	z-index: 200;
	bottom: 50px;
	.icon-sohu-lujing{
		font-size: 24px;
		&:hover{
			color: #FF6C27;
		}
	}
}
</style>
