<template>
	<transition name="el-fade-in-linear">
		<div class="confirm_wrapper" v-show="visible">
			<div class="confirm_box" ref="confirmBox">
				<p :class="[{ center: center }, 'confirm_content']">
					{{ content }}
				</p>
				<div class="footer_button">
					<div class="button" @click="cancel">取消</div>
					<div class="button define" @click="define">
						{{ confirmText }}
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
	data() {
		return {
			content: '',
			visible: false,
			// 确定按钮文字
			confirmText: '确定',
			// 文字居中排列
			center: true,
			// 异步回调
			beforeClose: null,
			loading: false,
		};
	},
	methods: {
		/**
		 * 关闭
		 */
		close() {
			this.loading = false;
			this.visible = false;
		},

		/**
		 * 确定
		 */
		define() {
			if (this.loading) return;
			this.loading = true;
			if (typeof this.beforeClose === 'function') {
				this.beforeClose('confirm', this.close);
			} else {
				this.close();
				this.callback('confirm');
			}
		},

		/**
		 * 取消
		 */
		cancel() {
			this.close();
			this.callback('cancel');
		},
	},
};
</script>
<style lang="scss">
.confirm_wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99999;
	.confirm_box {
		background: #ffffff;
		border-radius: 12px;
		width: 394px;
		padding: 32px;
		box-sizing: border-box;
		.confirm_content {
			color: #3d3d3d;
			margin-bottom: 40px;
			font-size: 18px;
			font-weight: 500;
			line-height: 24px;
			min-height: 48px;
			&.center {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.footer_button {
			display: flex;
			align-items: center;
			justify-content: center;
			.button {
				background: #f3f3f3;
				height: 40px;
				width: 100px;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				font-weight: 500;
				cursor: pointer;
				&.define {
					background: $theme-color;
					margin-left: 16px;
					color: #ffffff;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
  .confirm_wrapper .confirm_box {
		width: 95vw;
	}
}
</style>
