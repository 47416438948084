<template>
	<div
		:class="[
			{ active: active.receiver?.id == item.receiver?.id },
			{ asTop: item.asTopTime },
			'chat-item',
		]"
		@click="chatUserItemClick"
	>
		<el-avatar :src="item.userAvatar"></el-avatar>
		<div class="chat-right">
			<div class="chat-name">
				<div class="chat-name-text line-1">{{ item.userName }}</div>
				<div class="chat-time-text">{{ showTime }}</div>
			</div>
			<div class="chat-content">
				<div class="chat-at-text line-1">
					{{ content }}
				</div>
				<div class="dot" v-if="item.unreadCount">{{ item.unreadCount }}</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['item', 'active'],
	data() {
		return {};
	},
	computed: {
		showTime() {
			return this.$util.toTimeText(this.item.updateTime, true);
		},
		content() {
			switch (this.item.messageType) {
				case 'text':
					return this.item.body.content;
				case 'photo':
					return '[图片]';
				case 'video':
					return '[视频]';
				case 'voice':
					return `[语音]${this.item.body.duration}"`;
				case 'share':
					return '[分享]';
			}
		},
	},
	methods: {
		// 点击左侧聊天
		chatUserItemClick() {
			this.$emit('chatUserItemClick', this.item);
		},
	},
};
</script>
<style lang="scss">
.chat-item {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	height: 60px;
	padding: 8px 16px;
	.el-avatar {
		width: 40px;
		height: 40px;
		margin-right: 10px;
	}

	.chat-right {
		position: relative;
		flex: 1;
		height: 44px;
		.chat-name {
			display: flex;
			justify-content: space-between;
			color: #011627;
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			height: 22px;
			margin-bottom: 4px;
			.chat-name-text {
				max-width: 89px;
			}
			.chat-time-text {
				color: #707991;
				font-size: 12px;
				line-height: 16px;
			}
		}
		.chat-content {
			color: #707991;
			font-size: 12px;
			line-height: 18px;
			.chat-at-text {
				max-width: 150px;
			}
			.dot {
				position: absolute;
				bottom: 3px;
				right: 0;
				background: #f6685d;
				height: 14px;
				font-size: 12px;
				color: #ffffff;
				line-height: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 3px;
				border-radius: 8px;
			}
		}
	}
	&.asTop {
		background-color: #eeeeee;
	}
	&.active {
		background-color: #e7e7e7;
	}
}
</style>
