<template>
	<div id="app" @click="hide">
		<router-view />
	</div>
</template>
<script>
import VueEmit from '@/utils/VueEmit';
export default {
	data() {
		return {};
	},
	mounted() {
		if (!this._isMobile()) {
			var t = window.devicePixelRatio;
			if (!!window.ActiveXObject || 'ActiveXObject' in window) {
				if (t != 1) {
					alert(
						'您的设备对显示进行放大导致页面显示不完全,请调整后打开/或用其他浏览器',
					);
				}
			} else {
				if (t != 1 && this._getSystem()) {
					let c = document.querySelector('body');
					c.style.zoom = 1 / t;
				}
			}
		}
	},
	methods: {
		hide() {
			VueEmit.$emit('hide');
		},
		_getSystem() {
			return navigator.userAgent.toLowerCase().includes('windows');
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
			);
			return flag;
		},
	},
};
</script>

<style lang="scss">
html,
body,
#app {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #fff;
}

::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: #ccc;
}

#YSF-BTN-HOLDER {
	z-index: 99 !important;
	margin-bottom: 75px;
}
</style>
