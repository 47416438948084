<template>
	<div class="chatSendBox" @click="closeRefBox()">
		<div class="contentCard" v-if="showContentCard">
			<!-- 任务卡片 -->
			<div class="taskCard" v-if="cardType == 'task'">
				<div class="leftContent">
					<div
						class="close icon-sohu-quxiao"
						@click="showContentCard = false"
					></div>
					<el-image
						class="left_img"
						:src="require('@/assets/images/chat/taskImg.png')"
					></el-image>
					<div class="taskCard_content">
						<div class="task_title line-1">
							{{ cardData.title }}
						</div>
						<div class="task_price">
							<span>￥</span>
							{{ cardData.fullAmount }}
						</div>
					</div>
				</div>
				<div class="rightSendBtn" @click="sendShare">发送任务</div>
			</div>
		</div>
		<div
			class="operationRow"
			v-if="
				(chatUserMsgActive.sessionType == 'group' && isGroupLeader) ||
				(chatUserMsgActive.sessionType == 'group' && isGroupAdmin)
			"
		>
			<div class="operation_item" @click="operation_item_click('@所有人')">
				<el-image
					:src="require('@/assets/images/chat/@Everyone.png')"
				></el-image>
				@所有人
			</div>
			<div
				v-if="isGroupLeader || isGroupAdmin"
				class="operation_item"
				@click="operation_item_click('进群审批')"
			>
				<el-image
					:src="require('@/assets/images/chat/groupApproval.png')"
				></el-image>
				进群审批
			</div>
		</div>
		<div class="send-content-area">
			<div
				@paste.prevent="onEditorPaste"
				:disabled="Prohibition"
				@input="onEditorInput"
				ref="editBox"
				:placeholder="Prohibition ? '禁止发言' : '发送消息'"
				:contenteditable="!Prohibition"
				class="send-text-area"
				@keydown.down.prevent="onKeyDown"
				@keydown.up.prevent="onKeyUp"
				@keydown.enter.prevent="sendClick"
			></div>
			<div :class="[{ disabled: Prohibition }, 'send-btn-area']">
				<file-upload
					:disabled="Prohibition"
					:fileMaxSize="5"
					:fileTypes="['.jpeg', '.png', '.jpg', '.webp', '.gif', '.mp4']"
					@success="onImageSuccess"
				>
					<i class="icon-sohu-lianjie"></i>
				</file-upload>
				<i class="icon-sohu-fasongxinxi" @click="sendMessage('text')"></i>
			</div>
		</div>
		<!-- 群审批列表 -->
		<GroupApprovalDialog ref="GroupApprovalDialogRef"></GroupApprovalDialog>

		<chatAtBox
			:search-text="atSearchText"
			:members="userList"
			ref="atBox"
			@select="onAtSelect"
		></chatAtBox>
	</div>
</template>
<script>
import FileUpload from '@/components/FileUpload.vue';
import GroupApprovalDialog from './groupDialog/GroupApprovalDialog.vue';
import chatAtBox from './ChatAtBox.vue';
export default {
	props: ['chatUserMsgActive', 'switchGroup', 'imDetails'],
	components: { FileUpload, GroupApprovalDialog, chatAtBox },
	data() {
		return {
			fileUrl: '',
			Prohibition: false,
			details: {},
			userList: [],
			atSearchText: '',
			focusNode: '',
			focusOffset: '',
			showContentCard: false,
			cardData: {},
			cardType: '',
		};
	},
	watch: {
		chatUserMsgActive: {
			handler(val) {
				if (val.sessionType == 'group') {
					this.details = this.imDetails;
					this.imGroupForbidProhibition();
				} else {
					this.Prohibition = false;
				}
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		// 是否是群主
		isGroupLeader() {
			return this.$store.state.userInfo.id == this.details.userId;
		},
		// 是否是管理员
		isGroupAdmin() {
			return this.userList.some((item) => {
				return (
					item.permissionType == 'group_admin' &&
					item.userId == this.$store.state.userInfo.id
				);
			});
		},
	},
	methods: {
		// 获取用户是否被禁言
		async imGroupForbidProhibition() {
			const res = await this.$http.imGroupForbid(
				this.chatUserMsgActive.receiver.id,
			);
			this.Prohibition = res.data;

			const res2 = await this.$http.imGroupUserAll({
				groupId: this.chatUserMsgActive.receiver.id,
			});
			this.userList = res2.data;
		},

		// 输入框上操作项
		operation_item_click(val) {
			switch (val) {
				case '@所有人':
					if (this.Prohibition) return;
					let element = document.createElement('SPAN');
					element.className = 'at';
					element.dataset.id = 0;
					element.contentEditable = 'false';
					element.innerText = `@所有人`;
					let textNode = document.createTextNode('\u00A0');
					this.$refs.editBox.appendChild(element);
					this.$refs.editBox.appendChild(textNode);
					this.$refs.editBox.focus();
					let text = this.$refs.editBox.innerText;
					let caretPosition = text.length;
					let selection = window.getSelection();
					let range = document.createRange();
					// 如果有子节点,则设置光标位置在最后一个子节点的末尾
					if (this.$refs.editBox.childNodes.length > 0) {
						range.setStart(
							this.$refs.editBox.childNodes[
								this.$refs.editBox.childNodes.length - 1
							],
							this.$refs.editBox.childNodes[
								this.$refs.editBox.childNodes.length - 1
							].textContent.length,
						);
					} else {
						// 如果没有子节点,则设置光标位置在编辑框的末尾
						range.setStart(this.$refs.editBox, caretPosition);
					}
					range.collapse();
					selection.removeAllRanges();
					selection.addRange(range);
					break;
				case '进群审批':
					this.$refs.GroupApprovalDialogRef.open(
						this.switchGroup.id || this.chatUserMsgActive.receiver.id,
					);
					break;
			}
		},

		// 图片选择成功
		onImageSuccess(data, file) {
			console.log(data, file);
			this.fileUrl = data.url;
			if (file.type == 'video/mp4') {
				this.sendMessage('video');
			} else {
				this.sendMessage('photo');
			}
		},

		// 监听输入框
		onEditorInput(e) {
			// 如果触发 @
			if (this.chatUserMsgActive.sessionType == 'group') {
				if (e.data == '@') {
					// 打开选择弹窗
					this.showAtBox(e);
				} else {
					let selection = window.getSelection();
					this.focusNode = selection.focusNode;
					// 截取@后面的名称作为过滤条件
					let stIdx = this.focusNode.textContent.lastIndexOf('@');
					this.atSearchText = this.focusNode.textContent.substring(stIdx + 1);
					// 删除@时隐藏列表
					if (stIdx == -1 && this.$refs.atBox.show) {
						this.$refs.atBox.close();
					}
				}
			}
		},

		// 显示@列表
		showAtBox(e) {
			this.atSearchText = '';
			let selection = window.getSelection();
			let range = selection.getRangeAt(0);
			// 记录光标所在位置
			this.focusNode = selection.focusNode;
			this.focusOffset = selection.focusOffset;
			// 光标所在坐标
			let pos = range.getBoundingClientRect();

			// 获取列表的宽度和高度
			let listWidth = 260;

			// 获取当前屏幕的宽度和高度
			let screenWidth = window.innerWidth;

			// 计算列表的位置
			let x = pos.x;
			let y = pos.y;

			// 如果列表超出屏幕右边界,则将 x 坐标向左移动
			if (x + listWidth > screenWidth) {
				x = screenWidth - listWidth;
			}

			this.$refs.atBox.open({
				x: x,
				y: y,
			});
		},

		// 选中@列表的值
		onAtSelect(member) {
			let range = window.getSelection().getRangeAt(0);
			// 选中输入的 @xx 符
			range.setStart(
				this.focusNode,
				this.focusOffset - 1 - this.atSearchText.length,
			);
			range.setEnd(this.focusNode, this.focusOffset);
			range.deleteContents();
			// 创建元素节点
			let element = document.createElement('SPAN');
			element.className = 'at';
			element.dataset.id = member.userId;
			element.contentEditable = 'false';
			element.innerText = `@${member.userName}`;
			range.insertNode(element);
			// 光标移动到末尾
			range.collapse();
			// 插入空格
			let textNode = document.createTextNode('\u00A0');
			range.insertNode(textNode);
			range.collapse();
			this.atSearchText = '';
			this.$refs.editBox.focus();
		},

		// 键盘上箭头
		onKeyUp() {
			if (this.$refs.atBox.show) {
				this.$refs.atBox.moveUp();
			}
		},

		// 键盘下箭头
		onKeyDown() {
			if (this.$refs.atBox.show) {
				this.$refs.atBox.moveDown();
			}
		},

		// 过滤掉粘贴过来的样式
		async onEditorPaste(e) {
			let txt = e.clipboardData.getData('Text');
			if (typeof txt == 'string') {
				let range = window.getSelection().getRangeAt(0);
				let textNode = document.createTextNode(txt);
				range.insertNode(textNode);
				range.collapse();
			}
			let items = (e.clipboardData || window.clipboardData).items;
			if (items.length) {
				for (let i = 0; i < items.length; i++) {
					if (items[i].type.indexOf('image') !== -1) {
						let file = items[i].getAsFile();
						const { data } = await this.$http.uploadFile(file);
						this.insertImageAtCursor(data.url);
					}
				}
			}
		},

		// 在光标处插入图片
		insertImageAtCursor(url) {
			const imgNode = document.createElement('img');
			imgNode.className = 'chat-image';
			imgNode.src = url;
			const selection = window.getSelection();
			const range = selection.getRangeAt(0);
			range.insertNode(imgNode);
			range.setStartAfter(imgNode);
			range.collapse(true);
			selection.removeAllRanges();
			selection.addRange(range);
		},

		// 键盘回车事件
		sendClick(e) {
			if (this.$refs.atBox.show) {
				// 键盘操作不会自动修正焦点，需要手动修正,原因不详
				this.focusOffset += this.atSearchText.length;
				this.$refs.atBox.select();
			} else if (e.shiftKey && e.keyCode == 13) {
				// 无内容限制回车
				if (!this.$refs.editBox.childNodes.length) return false;

				//新建空白
				let docFragment = document.createDocumentFragment();
				// 创建br
				let newBr = document.createElement('br');
				docFragment.appendChild(newBr);
				// 获取当前选择
				let range = window.getSelection().getRangeAt(0);
				range.deleteContents();
				// 判断是否是最后一个元素是就多加一个（处理回车问题）
				if (
					!this.hasNextSibling(range.endContainer) &&
					range.startOffset == range.startContainer.length
				) {
					let extra_break = document.createElement('br');
					docFragment.appendChild(extra_break);
				}
				range.insertNode(docFragment);
				//创建
				range = document.createRange();
				range.setStartAfter(newBr);
				range.collapse(true);
				// 插入
				let sel = window.getSelection();
				sel.removeAllRanges();
				sel.addRange(range);
			} else {
				this.sendMessage('text');
			}
		},
		//判断节点
		hasNextSibling(node) {
			if (node.nextElementSibling) {
				return true;
			}
			while (node.nextSibling) {
				node = node.nextSibling;
				if (node.length > 0) {
					return true;
				}
			}
			return false;
		},
		// 过滤标签
		html2Escape(strHtml) {
			return strHtml.replace(/[<>&"]/g, function (c) {
				return {
					'<': '&lt;',
					'>': '&gt;',
					'&': '&amp;',
					'"': '&quot;',
				}[c];
			});
		},

		// 发送消息方法
		sendMessage(type) {
			let sendText = '';
			let userIds = [];
			let sendParams = {};
			switch (type) {
				case 'text':
					this.$refs.editBox.childNodes.forEach((node) => {
						console.log(node.nodeName, '标签名');
						if (node.nodeName == '#text') {
							sendText += this.html2Escape(node.textContent);
						} else if (node.nodeName == 'SPAN') {
							if (node.dataset.id) userIds.push(node.dataset.id);
							sendText += node.innerHTML;
						} else if (node.nodeName == 'IMG') {
							sendText += `[${node.currentSrc}]`;
						} else if (node.nodeName == 'BR') {
							sendText += '\n';
						}
					});
					if (!!sendText.trim()) {
						this.parseString(sendText).map((item) => {
							this.$emit('sendMessage', {
								messageType: item.type,
								content: item.content,
								atIds: userIds.join(),
							});
						});
						this.$refs.editBox.innerHTML = '';
					}
					break;
				case 'share':
					sendText = this.cardData.title;
					sendParams.shareId = this.cardData.id;
					sendParams.share = {
						amount: this.cardData.fullAmount,
						content: this.cardData.taskNumber,
						id: this.cardData.id,
						shareUserId: this.$store.state.userInfo.id,
						title: sendText.trim(),
						type: 'taskGuide',
						userId: this.cardData.userId,
					};
					this.$emit('sendMessage', {
						messageType: type,
						content: 'share',
						...sendParams,
					});
					break;
				default:
					sendText = this.fileUrl;
					this.$emit('sendMessage', {
						messageType: type,
						content: sendText.trim(),
					});
					break;
			}
		},

		// 分割字符串
		parseString(str) {
			const regex = /(\[[^\[\]]*\])|([^\[\]]+)/g;
			const result = [];
			let match;
			while ((match = regex.exec(str)) !== null) {
				if (match[1]) {
					// 括号内的内容
					const bracketContent = match[1].slice(1, -1); // 去除开头和结尾的括号
					result.push({
						type: 'photo',
						content: bracketContent,
					});
				} else if (match[2]) {
					// 括号外的内容
					result.push({ type: 'text', content: match[2] });
				}
			}
			return result;
		},

		// 点击其他区域关闭弹窗
		closeRefBox() {
			this.$refs.atBox.close();
		},

		// 显示卡片
		async showCard(id, type) {
			this.showContentCard = true;
			this.cardType = type;
			if (type == 'task') {
				const res = await this.$http.busyOrderAppBusyTaskChild(id);
				this.cardData = res.data;
			}
		},

		// 发送分享卡片
		sendShare() {
			this.showContentCard = false;
			this.sendMessage('share');
		},
	},
};
</script>
<style lang="scss">
.chatSendBox {
	position: relative;
	.contentCard {
		.taskCard {
			background: #ffffff;
			border-radius: 4px;
			height: 62px;
			margin-bottom: 10px;
			position: relative;
			display: flex;
			align-items: center;
			padding: 8px;
			box-sizing: border-box;
			justify-content: space-between;
			.close {
				position: absolute;
				right: 7px;
				top: 7px;
				color: #c8c9cc;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}

			.leftContent {
				display: flex;
				align-items: center;
				.left_img {
					height: 46px;
					width: 46px;
				}

				.taskCard_content {
					margin-left: 8px;
					margin-right: 32px;
					.task_title {
						font-size: 14px;
						margin-bottom: 2px;
						line-height: 20px;
						max-width: 380px;
					}
					.task_price {
						color: #f6685d;
						font-size: 20px;
						font-weight: 600;
						span {
							font-size: 12px;
						}
					}
				}
			}

			.rightSendBtn {
				background: linear-gradient(270deg, #ff6c27 0%, #ffb011 98%);
				color: #ffffff;
				font-size: 12px;
				border-radius: 4px;
				height: 24px;
				width: 64px;
				text-align: center;
				line-height: 24px;
				margin-right: 42px;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	.operationRow {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		.operation_item {
			background: #ffffff;
			border-radius: 4px;
			color: rgba(0, 0, 0, 0.6);
			font-weight: 500;
			font-size: 12px;
			line-height: 20px;
			padding: 3px 5px;
			display: flex;
			align-items: center;
			margin-right: 8px;
			.el-image {
				width: 16px;
				height: 16px;
				margin-right: 2px;
			}
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.send-content-area {
		position: relative;
		padding: 11px 12px;
		box-sizing: border-box;
		background: #ffffff;
		border-radius: 12px;
		.send-text-area {
			outline-color: transparent;
			font-size: 14px;
			line-height: 22px;
			max-height: 200px;
			overflow: auto;
			white-space: normal;
			word-break: break-all;
			word-wrap: break-word;
			&:empty:before {
				content: attr(placeholder);
				color: #aaa;
				font-size: 14px;
			}
			.at {
				// color: #409eff;
			}

			.chat-image {
				display: block;
				max-width: 150px;
				max-height: 60px;
				border: 1px solid #e6e6e6;
				cursor: pointer;
			}
		}

		.send-btn-area {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			i {
				font-size: 24px;
				color: #707991;
				margin-left: 16px;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}

			&.disabled {
				i {
					opacity: 0.5;
					cursor: not-allowed;
				}
			}
		}
	}
}
</style>
