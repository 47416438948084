<template>
	<dialogBox
		appendToBody
		width="500px"
		v-model="dialogVisible"
		class="GroupMembers"
		title="进群人员"
	>
		<div class="list">
			<div class="rowItem" v-for="item of list" item.userId>
				<div class="userData">
					<el-image
						class="avatar"
						:src="item.userAvatar"
						fit="cover"
					></el-image>
					{{ item.userName }}
				</div>
				<div class="time">{{ item.createTime }}</div>
			</div>
		</div>
	</dialogBox>
</template>
<script>
import dialogBox from '@/components/dialogBox.vue';
export default {
	components: { dialogBox },
	data() {
		return {
			dialogVisible: false,
			list: [],
		};
	},
	methods: {
		open(list) {
			this.dialogVisible = true;
			this.list = list;
		},
	},
};
</script>
<style lang="scss">
.GroupMembers {
	.list {
		max-height: 500px;
		overflow: auto;
		.rowItem {
			padding: 10px 0;
			border-bottom: 1px solid #e7e7e7;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.userData {
				display: flex;
				align-items: center;
				color: #3d3d3d;
				font-size: 12px;
				.avatar {
					width: 30px;
					height: 30px;
					margin-right: 6px;
					border-radius: 50%;
				}
			}
			.time {
				color: #999999;
				font-size: 12px;
			}
		}
	}
}
</style>
