import { deleteApi, get, postFile, postJson, putJson } from '@/utils/request';
export default {
	// 用户新增 ai 应用
	addAiTemplatePersonally(data) {
		return postJson(`/admin/aiTemplatePersonally`, data);
	},

	// 用户编辑 ai 应用
	editAiTemplatePersonally(data) {
		return putJson(`/admin/aiTemplatePersonally`, data);
	},

	// 获取ai我的应用列表
	aiTemplatePersonallyList(data) {
		return get(`/admin/aiTemplatePersonally/list`, data);
	},

	// 获取用户ai应用详情
	aiTemplatePersonallyDetails(id, data) {
		return get(`/admin/aiTemplatePersonally/${id}`, data);
	},

	// 删除我的AI应用
	delAiTemplatePersonally(ids, data) {
		return deleteApi(`/admin/aiTemplatePersonally/${ids}`, data);
	},

	// 我的AI应用生成内容
	aiTemplatePersonallyWork(data) {
		return postJson(`/admin/aiTemplatePersonally/work`, data);
	},

	// 获取ai应用类型列表
	aiTemplateTagList(data) {
		return get(`/admin/aiTemplateTag/list`, data);
	},

	// 获取平台ai应用列表
	aiTemplateList(data) {
		return get(`/admin/aiTemplate/list`, data);
	},

	// 获取平台ai应用详情
	aiTemplateDetails(id, data) {
		return get(`/admin/aiTemplate/${id}`, data);
	},

	// 平台AI应用生成内容
	aiTemplateWork(data) {
		return postJson(`/admin/aiTemplate/work`, data);
	},

	// 新建会话
	addAiChatMessage(data) {
		return postJson(`/admin/aiChatMessage`, data);
	},

	// 查询AI聊天外层列表
	aiChatLastMessageList(data) {
		return get(`/admin/aiChatLastMessage/list`, data);
	},

	// 删除AI聊天外层会话
	delAiChatLastMessage(msgIds, data) {
		return deleteApi(`/admin/aiChatLastMessage/${msgIds}`, data);
	},

	// 查询AI聊天历史记录
	aiChatMessageList(data) {
		return get(`/admin/aiChatMessage/list`, data);
	},

	// 发送AI聊天消息
	aiChatMessageChat(data) {
		return postJson(`/admin/aiChatMessage/chat`, data);
	},

	// 查询AI会员价格列表
	aiPriceList(data) {
		return get(`/admin/aiPrice/list`, data);
	},

	// AI会员购买生成预订单
	aiOrderCreateOrder(data) {
		return postJson(`/admin/aiOrder/create/order`, data);
	},

	// 获取AI聊天会员信息
	aiInfo(data) {
		return get(`/admin/aiInfo`, data);
	},

	// 获取会员开通记录
	aiOrderListUser(data) {
		return get(`/admin/aiOrder/list/user`, data);
	},
};
