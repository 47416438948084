import { get, postFile, postJson, deleteApi } from '@/utils/request';

export default {
	// 查询游戏主体列表
	gameList: (params) => get('/app/api/gameNovel/list', params),

	// 新游列表
	newGameList: (params) => get('/app/api/gameNovel/newest/list', params),

	// 获取游戏主体详细信息
	gameDetail: (id) => get(`/app/api/gameNovel/${id}`),

	// 推荐列表
	gameRecommendList: (data) => get('/app/api/gameNovel/recommend/list', data),

	// 公告列表
	gameNoticeList: (data) => get('/app/api/gameNovel/popularize/list', data),
};
