const microApps = [
	{
		name:"game-app",
		entry:"http://localhost:8081/",
		activeRule: '/game-app/home',//匹配的路由
		sanbox: true //解决css冲突
	}
]

const apps = microApps.map(item => {
	return {
		...item,
		container: '#micro', //解决css冲突
		props: {
			routeBase: item.activeRule //下发基础路由
		}
	}
})

export default apps;
