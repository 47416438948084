import RoleLayout from '@/views/roleEnter/Layout.vue';
// 角色入驻
export default [
	{
		path: '/roleEnter',
		component: RoleLayout,
		name: 'Layout',
		redirect: '/roleEnter/roleIndex',
		children: [
			{
				path: 'roleIndex',
				component: () => import('@/views/roleEnter/roleIndex/index'),
				meta: {
					title: '平台介绍',
				},
			},
			{
				path: 'platformInfo',
				component: () => import('@/views/roleEnter/platformInfo/index'),
				meta: {
					title: '平台资讯',
				},
			},
			{
				path: 'infoDetail/:id',
				component: () => import('@/views/roleEnter/platformInfo/infoDetail'),
				meta: {
					title: '资讯详情',
				},
			},
			{
				path: 'helpCenter',
				component: () => import('@/views/roleEnter/helpCenter/index'),
				meta: {
					title: '帮助中心',
				},
			},
			{
				path: 'helpCenter/:id',
				component: () => import('@/views/roleEnter/helpCenter/helpDetail'),
				meta: {
					title: '帮助手册',
				},
			},
			{
				path: 'immediateEnter',
				component: () => import('@/views/roleEnter/immediateEnter/index'),
				meta: {
					title: '立即入驻',
				},
			},
		],
	},

	// 任务方入驻
	{
		path: '/taskPartyEntry',
		component: () => import('@/views/entry/taskPartyEntry/index'),
		meta: {
			title: '任务方入驻',
		},
	},
	// 达人入驻
	{
		path: '/articleEntry',
		component: () => import('@/views/entry/articleEntry/index'),
		meta: {
			title: '达人入驻',
		},
	},

	// 海外入驻
	{
		path: '/overseasSettlement',
		component: () => import('@/views/entry/overseasSettlement/index'),
		meta: {
			title: '海外入驻',
		},
	},

	// 品牌方招募
	{
		path: '/brandRecruitment',
		component: () => import('@/views/entry/brandRecruitment/index'),
		meta: {
			title: '品牌方招募',
		},
	},
	// 承办方招募
	{
		path: '/organizerRecruitment',
		component: () => import('@/views/entry/organizerRecruitment/index'),
		meta: {
			title: '承办方招募',
		},
	},
];
