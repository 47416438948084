<template>
	<button :disabled="disabled" :class="[type, 'q-button']" @click="handleClick">
		<slot></slot>
	</button>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {};
	},
	methods: {
		handleClick(evt) {
			this.$emit('click', evt);
		},
	},
};
</script>
<style lang="scss" scoped>
.q-button {
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	line-height: 20px;
	padding: 6px 16px;
	font-size: 14px;
	color: #666666;
	border: 1px solid #d1d1d1;
	background-color: #fff;
	&:not([disabled]):hover {
		opacity: 0.8;
	}

	&.theme {
		background: $theme-color;
		color: #fff;
		border: 1px solid $theme-color;
	}
	&[disabled] {
		cursor: not-allowed;
		opacity: 0.5;
	}
}
</style>
