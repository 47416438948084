<template>
	<dialogBox
		appendToBody
		:showHeader="false"
		v-model="dialogVisible"
		class="GroupQRCode"
		width="410px"
	>
		<div class="userData">
			<el-image class="Avatar" :src="details.logo" fit="cover"></el-image>
			<div class="groupName">{{ details.name }}</div>
		</div>
		<div class="qrCode" id="qrCode" ref="qrCodeUrl"></div>
		<div class="qrCodeSubmit">用APP扫描二维码，即可加入群聊</div>
		<el-button @click="dialogVisible = false">关闭</el-button>
	</dialogBox>
</template>
<script>
import QRCode from 'qrcodejs2';
import dialogBox from '@/components/dialogBox.vue';
export default {
	components: { dialogBox },
	data() {
		return {
			dialogVisible: false,
			details: {},
		};
	},
	methods: {
		async open(id) {
			this.dialogVisible = true;

			const res = await this.$http.imGroupDetails(id);
			this.details = res.data;

			const res1 = await this.$http.imGroupGencode(id);
			this.$nextTick(() => {
				document.getElementById('qrCode').innerHTML = '';
				new QRCode(this.$refs.qrCodeUrl, {
					text: `https://world.sohuglobal.com/#/contentPackages/downloadApp/downloadApp?type=joinGroup&groupId=${id}&inviteId=${this.$store.state.userInfo.id}&inviteCalculate=${res1.data.inviteCalculate}`, // 需要转换为二维码的内容
					width: 167,
					height: 167,
				});
			});
		},
	},
};
</script>
<style lang="scss">
.GroupQRCode {
	text-align: center;
	.userData {
		.Avatar {
			border-radius: 4px;
			width: 56px;
			height: 56px;
		}
		.groupName {
			color: rgba(0, 0, 0, 0.9);
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			margin-top: 12px;
			margin-bottom: 40px;
		}
	}
	.qrCode {
		width: 167px;
		height: 167px;
		margin: auto;
	}
	.qrCodeSubmit {
		color: rgba(0, 0, 0, 0.4);
		font-size: 12px;
		line-height: 18px;
		margin-top: 24px;
		margin-bottom: 42px;
	}
	.el-button {
		width: 100px;
		height: 40px;
		border-radius: 4px;
		padding: 0;
		margin: 0;
		border: 0 solid #000;
		background: #f3f3f3;
		color: #3d3d3d;
		font-size: 16px;
		font-weight: 500;
		&:hover {
			opacity: 0.8;
		}
	}
}
</style>
