import AgentEntryLayout from "@/views/entry/agentEntry/Layout.vue";
export default [
	{
		path: '/entry',
		component: AgentEntryLayout,
		name: "AgentLayout",
		redirect: "/entry/entryIndex",
		children: [
			{
				path: 'entryIndex',
				component: () => import('@/views/entry/entryIndex/index.vue'),
				meta: {
					title: '入驻首页',
				},
			},
			{
				path: 'personAuth',
				component: () => import('@/views/entry/personAuth/index.vue'),
				meta: {
					title: '入驻首页',
				},
			},
			{
				path: 'status',
				component: () => import('@/views/entry/entryStatus/index.vue'),
				meta: {
					title: '入驻状态',
				},
			},
		],
	},
];