var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{staticClass:"systemNotification"},[_c('el-header',{attrs:{"height":"56px"}},[_c('div',{staticClass:"systemNotification_header"},[_c('div',{staticClass:"leftTitle"},[_c('el-image',{attrs:{"src":require('@/assets/images/chat/notification.png')}}),_vm._v(" 消息通知 ")],1),_c('div',{staticClass:"rightTabs"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.messageTitle))])])])]),_c('el-main',[_c('el-container',{staticStyle:{"height":"100%"}},[_c('el-aside',{attrs:{"width":"248px"}},[_c('div',{staticClass:"notificationTypeList"},_vm._l((_vm.systemOuterList),function(item){return _c('div',{class:[
							{ active: _vm.systemEnum[item.type].value === _vm.leftTitleActive },
							'notificationType_item',
						],on:{"click":function($event){return _vm.leftTitleClick(item)}}},[_c('el-image',{attrs:{"src":_vm.systemEnum[item.type].icon}}),_vm._v(" "+_vm._s(_vm.systemEnum[item.type].name)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.unReadCount && _vm.systemEnum[item.type].value!=_vm.leftTitleActive),expression:"item.unReadCount && systemEnum[item.type].value!=leftTitleActive"}],staticClass:"dot-red"})],1)}),0)]),_c('el-main',{staticStyle:{"padding":"0"}},[_c('div',{staticClass:"notificationContent",on:{"scroll":_vm.handleScroll}},[(_vm.leftTitleActive === 'system')?[_c('div',{staticClass:"system-tab"},_vm._l((_vm.systemTabList),function(item){return _c('div',{key:item.type,staticClass:"item",on:{"click":function($event){return _vm.handleSystemTab(item)}}},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('div',{staticClass:"title"},[(item.unReadCount > 0)?_c('div',{staticClass:"point"}):_vm._e(),_c('p',{style:({
											color:
												item.type === _vm.systemTabActive
													? item.color
													: '#3D3D3D',
											textDecoration:
												item.type === _vm.systemTabActive ? 'underline' : 'none',
										})},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),0),_c('div',{staticClass:"system-list"},[_vm._l((_vm.list),function(item){return _c('system_card',{key:item.id,attrs:{"item":item},on:{"toDownload":_vm.handleDownload}})})],2)]:_vm._e(),(_vm.leftTitleActive === 'friend')?[_c('div',{staticClass:"new-tab"},_vm._l((_vm.newList),function(v){return _c('div',{key:v.type,class:[
									'item',
									_vm.newTabActive === v.type ? 'success' : 'fail',
								],on:{"click":function($event){return _vm.handleNewTab(v)}}},[_vm._v(" "+_vm._s(v.title)+" ")])}),0),(_vm.newTabActive === 'friend')?_c('div',{staticClass:"new-list"},[_c('NewFriends_card',{attrs:{"list":_vm.list}})],1):_vm._e(),(_vm.newTabActive === 'fans')?_c('div',{staticClass:"fans-list"},[_c('new-fans_card',{attrs:{"list":_vm.list},on:{"update":_vm.handleFansUpdate}})],1):_vm._e()]:_vm._e(),(_vm.leftTitleActive === 'interact')?[_c('div',{staticClass:"interact-list"},[_c('InteractiveMessages_card',{attrs:{"list":_vm.list}})],1)]:_vm._e(),(_vm.leftTitleActive === 'task')?[_c('div',{staticClass:"task-tab"},_vm._l((_vm.taskList),function(v){return _c('div',{key:v.type,class:[
									'item',
									_vm.taskTabActive === v.type ? 'success' : 'fail',
								],on:{"click":function($event){return _vm.handleTaskTab(v)}}},[_vm._v(" "+_vm._s(v.title)+" ")])}),0),_c('div',{staticClass:"task-list"},_vm._l((_vm.list),function(item){return _c('task_card',{key:item.id,attrs:{"model":item},on:{"toDownload":_vm.handleDownload}})}),1)]:_vm._e(),(_vm.loading)?_c('p',{staticClass:"bottom_loading"},[_vm._v("加载中...")]):_vm._e(),(!_vm.list.length)?_c('q-empty',{attrs:{"height":"400px"}}):_vm._e()],2)])],1)],1),_c('download_dialog',{model:{value:(_vm.downloadShow),callback:function ($$v) {_vm.downloadShow=$$v},expression:"downloadShow"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }