import AgentEntryLayout from "@/views/entry/agentEntry/Layout.vue";
// 代理商入驻
export default [
	{
		path: "/agentEnter",
		component: AgentEntryLayout,
		name: "AgentLayout",
		redirect: "/agentEnter/agentEnterIndex",
		children: [
			{
				path: "agentEnterIndex",
				component: () =>
					import("@/views/entry/agentEntry/agentEntryIndex/index"),
				meta: {
					title: "代理商入驻首页",
				},
			},
			{
				path: "agentEnterInfo",
				component: () =>
					import("@/views/entry/agentEntry/agentEntryInfo/index"),
				meta: {
					title: "填写入驻信息",
				},
			},
			{
				path: "helpCenter",
				component: () => import("@/views/entry/helpCenter/index.vue"),
				meta: {
					title: "帮助中心",
				},
			},
			{
				path: "success",
				component: () => import("@/views/entry/demolitionEnter/success/index"),
				meta: {
					title: "提交成功",
				},
			},
		],
	},
];
