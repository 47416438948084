<template>
	<div class="authenticationDialog" @click.stop="">
		<el-dialog :visible.sync="drawer" top="50%" width="400px">
			<!-- 实名认证 -->
			<div class="drawer" v-if="type == 'authentication'">
				<img
					v-if="skip"
					src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/24/5f8d5b309b6a4f589a4fdb2b778bbe00_378x240.png"
				/>
				<img
					v-else
					src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/24/f0a15626109c406f8f46e1eae4b19169_378x240.png"
				/>
				<span>{{ hintText }}</span>
				<div class="authenticationBtn" @click.stop="drawerBtnClick">
					{{ btnText }}
				</div>
			</div>

			<!-- 行业入驻 -->
			<div class="drawer" v-if="type == 'enter'">
				<img
					src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/04/24/7ced8bc20b7346edbfb819534b3299bb_378x240.png"
				/>
				<span>{{ hintText }}</span>
				<button class="authenticationBtn" @click.stop="goEnterFn">
					{{ btnText }}
				</button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { dateFormat } from '@/utils/util.js';
import URL from '@/utils/baseUrl';
export default {
	props: {
		//认证：authentication  行业入驻：enter
		type: {
			type: String,
			default: 'authentication',
		},
		//是否验证银行卡
		bankCardShow: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			hintText: '', //提示文字
			btnText: '', //按钮文字
			drawer: false,
			skip: true, //认证true、银行卡false跳转
			showClose: false,
		};
	},
	methods: {
		//获取实名认证信息
		getAccountDetails() {
			return new Promise((resolve, reject) => {
				this.$http.getPayAccount().then((res) => {
					console.log(res, '数据');
					this.skip = true;
					if (res.code == 200 && res.data) {
						switch (res.data.state) {
							case 'WaitApprove':
								this.hintText = '认证审核中';
								this.btnText = '去查看';
								this.drawer = true;
								resolve(false);
								break;
							case 'Pass':
								if (
									(res.data.identityValidityType == 0 &&
										res.data.identityEndDate &&
										dateFormat(Date.now(), 'YYYYMMDD') >=
											res.data.identityEndDate) ||
									(res.data.licenseValidityType == 0 &&
										res.data.licenseEndDate &&
										dateFormat(Date.now(), 'YYYYMMDD') >=
											res.data.licenseEndDate)
								) {
									this.hintText = '您的实名认证已过期，请重新认证';
									this.btnText = '重新实名认证';
									this.drawer = true;
									resolve(false);
								} else {
									// 银行卡
									if (this.bankCardShow) {
										this.getBankCardDetails().then((v) => {
											console.log(v, '11111');
											resolve(v);
										});
									}
								}

								break;
							case 'Refuse':
								this.hintText = '实名认证审核未通过';
								this.btnText = '去查看';
								this.drawer = true;
								resolve(false);
								break;
						}
					} else {
						this.hintText = '您还未实名认证，请先实名认证';
						this.btnText = '去实名认证';
						this.drawer = true;
						resolve(false);
					}
				});
			});
		},
		//是否绑定银行卡
		async getBankCardDetails() {
			this.showClose = true;
			let res = await this.$http.payAccountBankBank();
			this.skip = false;
			if (res.code == 200 && res.data) {
				switch (res.data.state) {
					case 'WaitApprove':
						this.hintText = '银行卡审核中';
						this.btnText = '去查看';
						this.drawer = true;
						return false;
						break;
					case 'Pass':
						this.drawer = false;
						console.log('触发2222');
						return true;
						break;
					case 'Refuse':
						this.hintText = '审核未通过';
						this.btnText = '去查看';
						this.drawer = true;
						return false;
						break;
				}
			} else {
				this.hintText = '为保证您正常结算收益，请添加结算银行卡';
				this.btnText = '去添加';
				this.drawer = true;
				return false;
			}
		},
		//按钮跳转
		drawerBtnClick() {
			let url;
			if (this.skip) {
				//实名
				url = '/user_content/authentication';
			} else {
				//银行卡
				url = '/user_content/add_bank_card';
			}
			window.open(
				`${URL.ADMIN_URL}${url}?token=${this.$store.state.token}&roleIdentifier=article`,
			);
		},
	},
};
</script>
<style lang="scss" scoped>
.drawer {
	padding: 20px 20px 30px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		width: 126px;
		height: 80px;
		margin-bottom: 10px;
	}
	span {
		color: rgba(0, 0, 0, 0.6);
		font-size: 20px;
		font-weight: 600;
	}
	.authenticationBtn {
		margin-top: 30px;
		width: 142px;
		height: 40px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #ffffff;
		text-align: center;
		background: #ff6c27;
		border: none;
		cursor: pointer;
		&:hover {
			opacity: 0.8;
		}
	}
}
:deep(.el-dialog__header) {
	padding: 0;
}
:deep(.el-dialog__body) {
	padding: 20px;
}
:deep(.el-dialog:not(.is-fullscreen)) {
	margin-top: 35vh !important;
	border-radius: 8px;
}
</style>
