<template>
	<el-dialog
		:modal-append-to-body="false"
		:close-on-click-modal="false"
		:visible.sync="dialogVisiblePay"
		:width="payWayIndex == 1 ? '743px' : '500px'"
		append-to-body
		@close="handleClose"
	>
		<div slot="title" class="title flex">
			<p>从第{{ videoInfo.episodeNumber }}集开始解锁</p>
			<p class="line-1">
				{{ videoInfo.playletTitle }}·共{{ videoInfo.episodeCount }}集
			</p>
		</div>
		<div class="dialog-box flex">
			<div class="left">
				<div class="flex price-box">
					<div
						class="price"
						:class="{ 'price-active': priceIndex == 1 }"
						@click="priceChange(1)"
					>
						<p>{{ playInfo.singlePrice }}元</p>
						<p>解锁一集</p>
						<p>{{ singlePrice }}狐币</p>
					</div>
					<div
						class="price"
						:class="{ 'price-active': priceIndex == 2 }"
						@click="priceChange(2)"
					>
						<p>{{ playInfo.playletPrice }}元</p>
						<p>解锁全部{{ isPayTotalNumber }}集</p>
						<p>{{ totalPrice }}狐币</p>
					</div>
				</div>
				<div class="pay-way">
					<div
						class="way-item flex recharge"
						@click="goRecharge"
						v-show="payWayIndex == 0"
					>
						<p>狐币余额:{{ userCoinInfo.virtualCoin }}</p>
						<p class="go-recharge">
							<span>去充值</span
							><i class="icon-sohu-xiayiyeqianjinchakangengduo"></i>
						</p>
					</div>
					<div
						class="way-item flex"
						v-for="(item, index) in payWayList"
						:key="index"
						@click="payWayIndex = index"
					>
						<p>
							<svg class="icon" aria-hidden="true">
								<use :xlink:href="`#${item.img}`"></use>
							</svg>
							{{ item.name }}
						</p>
						<div class="circle-checked" v-if="payWayIndex == index">
							<div class="circle-solid"></div>
						</div>
						<div class="circle" v-else></div>
					</div>
				</div>

				<el-button
					class="pay-finish"
					v-if="payWayIndex == 0"
					@click="handleAgree"
					:disabled="payText == '支付中'"
				>
					{{ payText }}
				</el-button>
				<el-button class="pay-finish" v-else @click="payFinish"
					>支付完成</el-button
				>
			</div>
			<div class="right" v-if="payWayIndex == 1">
				<p class="pay">
					支付{{
						priceIndex == 1 ? playInfo.singlePrice : playInfo.playletPrice
					}}元
				</p>
				<div class="code-main">
					<el-image
						:src="require('@/assets/images/qrcode.png')"
						class="code"
						v-if="!qrcodeVal"
					></el-image>
					<vue-qr
						style="margin: auto; display: block"
						:text="qrcodeVal"
						class="code"
						:size="192"
						v-if="qrcodeVal"
					></vue-qr>
					<p @click="handleAgree" v-if="qrcodeVal" class="tip">刷新二维码</p>
					<div class="pay-btn-main" v-show="isAgree">
						<p class="pay-btn" @click="handleAgree">同意协议并支付</p>
					</div>
				</div>

				<p class="protocol" @click="goAgree">
					支付前请阅读<span>《短剧付费服务协议》</span>
				</p>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import VueQr from 'vue-qr';

export default {
	components: { VueQr },
	props: {
		videoInfo: {
			type: Object,
		},
		videoList: {
			type: Array,
		},
		playInfo: {
			type: Object,
		},
	},
	data() {
		return {
			dialogVisiblePay: false,
			priceIndex: 1,
			qrcodeVal: '',
			isAgree: true,
			payWayList: [
				{ name: '狐币支付', img: 'icon-sohu-hubi' },
				{ name: '微信支付', img: 'icon-sohu-weixinzhifu' },
			],
			payWayIndex: 0,
			userCoinInfo: {},
			payText: '',
			rechargeRate: 0,
		};
	},
	computed: {
		// 需要付费的集数
		isPayTotalNumber() {
			return this.videoList.filter((item) => item.episodePay && !item.paid)
				.length;
		},
		amount() {
			let num =
				this.priceIndex == 1
					? this.playInfo.singlePrice * this.rechargeRate
					: this.playInfo.playletPrice * this.rechargeRate;
			return num.toFixed(1);
		},
		singlePrice() {
			return (Number(this.playInfo.singlePrice) * this.rechargeRate).toFixed(1);
		},
		totalPrice() {
			return (Number(this.playInfo.playletPrice) * this.rechargeRate).toFixed(
				1,
			);
		},
	},
	methods: {
		// 获取金币比例
		async getDicts() {
			let res = await this.$http.getDicts('system_config');
			res.data.forEach((val) => {
				if (val.dictLabel == 'RechargeRate') {
					this.rechargeRate = Number(val.dictValue);
					this.payText = `支付${(
						this.playInfo.singlePrice * this.rechargeRate
					).toFixed(1)}狐币`;
				}
			});
		},
		async init() {
			await this.getDicts();
			this.dialogVisiblePay = true;
			this.getRechargeVirtual();
		},
		// 付钱价格变化
		priceChange(val) {
			this.priceIndex = val;
			this.payText = `支付${this.amount}狐币`;
			if (!this.isAgree) {
				this.handleAgree();
			}
		},
		// 狐币充值
		goRecharge() {
			this.$foxCoin({
				playInfo: this.playInfo,
				routeParams: this.$route.query,
				userId: this.$store.state.userInfo.id,
				siteId: this.$store.state.siteId,
			}).then(() => {
				this.getRechargeVirtual();
			});
		},
		// 获取狐币余额
		async getRechargeVirtual() {
			let { code, data } = await this.$http.rechargeVirtual();
			if (code == 200) {
				this.userCoinInfo = data;
			}
		},
		// 同意协议
		goAgree() {
			window.open('https://world.sohuglobal.com/短剧付费协议.html', '_blank');
		},
		// 处理扫码付费预支付参数
		handlePayParams() {
			console.log(this.priceIndex, '????');
			const routePasrams = this.$route.query;
			const params = {
				payType: 'yi-ma', //支付方式
				paySource: 14, //支付来源
				payPrice:
					this.priceIndex === 1
						? this.playInfo.singlePrice
						: this.playInfo.playletPrice, //金额
				payChannel: 'pc', //支付渠道
				independentUserId: routePasrams.shareUserId || '', //分销人id
				isPlatform: this.playInfo.isPlatform, // 版权是否归平台所有
				isIndependent: false, // 是否为分销单
				materialId: routePasrams.materialId || '', //素材id
				userId: this.$store.state.userInfo.id,
				masterId: this.videoInfo.id,
			};
			console.log(this.videoInfo.id, '!');
			console.log(this.playInfo.id, '?');
			if (!params.isPlatform) params.siteId = this.$store.state.siteId;
			if (routePasrams.shareUserId) {
				//有分销人时 开启分销
				params.isIndependent = true;
			} else {
				//无分销人时 关闭分销
				params.isIndependent = false;
			}
			return params;
		},
		// 同意协议并支付
		async handleAgree() {
			console.log(this.playInfo, 'playInfo');
			console.log(this.videoInfo, 'videoInfo');
			if (
				this.playInfo.state == 'OffShelf' ||
				this.playInfo.state == 'CompelOff'
			) {
				this.$message.warning('该视频已下架，请观看其它视频');
			} else {
				let params = {};
				// 添加订单
				if (this.payWayIndex === 1 && this.priceIndex === 1) {
					params = {
						payType: 'yi-ma',
						payChannel: 'pc',
						masterId: this.videoInfo.id,
						paySource: 7,
					};
				} else if (this.payWayIndex == 1 && this.priceIndex !== 1) {
					params = this.handlePayParams();
				} else {
					if (Number(this.userCoinInfo.virtualCoin) < Number(this.amount)) {
						this.$message.warning('狐币余额不足，请先充值再支付。');
						return;
					}
					params = {
						payType: 'virtual-pay',
						payChannel: 'pc',
						masterId:
							this.priceIndex == 1 ? this.videoInfo.id : this.playInfo.id,
						paySource: this.priceIndex == 1 ? 101 : 102,
					};
					this.payText = '支付中';
				}
				let resOrder;
				if (this.payWayIndex === 0 || this.priceIndex === 1) {
					resOrder = await this.$http.ordersAddedApp(params);
				} else {
					resOrder = await this.$http.ordersAdded(params);
				}
				if (resOrder.code == 200) {
					if (this.payWayIndex == 1) {
						let msg = JSON.parse(resOrder.msg);
						this.qrcodeVal = msg.codeUrl;
						this.isAgree = false;
					} else {
						setTimeout(() => {
							this.$message.success('支付成功');
							this.dialogVisiblePay = false;
							this.$emit('success', this.videoInfo.episodeNumber);
							this.payText = '支付完成';
						}, 3000);
					}
				}
			}
		},
		// 支付完成
		payFinish() {
			this.$confirm('是否确定已经付款？', '系统提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(async () => {
				this.isAgree = true;
				this.dialogVisiblePay = false;
				this.$emit('success', this.videoInfo.episodeNumber);
			});
		},
		// 支付弹窗关闭
		handleClose() {
			this.priceIndex = 1;
			this.qrcodeVal = '';
			this.isAgree = true;
			this.dialogVisiblePay = false;
		},
	},
};
</script>
<style lang="scss" scoped>
.flex {
	display: flex;
}

.title {
	padding-right: 10px;

	p {
		&:first-child {
			color: #3d3d3d;
			font-size: 18px;
			font-weight: 600;
			max-width: 148px;
		}

		&:last-child {
			flex: 1;
			color: #999999;
			font-size: 14px;
			margin-left: 16px;
			line-height: 1.2;
		}
	}
}

.dialog-box {
	gap: 69px;

	.left {
		width: 400px;

		.price-box {
			gap: 57px;

			.price {
				width: 172px;
				height: 97px;
				border-radius: 12px;
				background: #fcfcfc;
				text-align: center;
				position: relative;
				box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);

				p {
					&:first-child {
						font-size: 20px;
						font-weight: 500;
						line-height: 24px;
						color: #3d3d3d;
						margin-top: 21px;
					}

					&:nth-child(2) {
						font-size: 14px;
						line-height: 22px;
						color: $theme-color;
						margin-top: 10px;
					}

					&:last-child {
						position: absolute;
						font-size: 14px;
						line-height: 22px;
						color: $theme-color;
						right: -40px;
						top: -13px;
						width: 78px;
						height: 25px;
						border-radius: 20px 0px 20px 0px;
						background: linear-gradient(180deg, #f04803 0%, #eb6d07 100%);
						text-align: center;
						line-height: 25px;
						font-size: 12px;
						font-weight: 500;
						color: #ffffff;
					}
				}
			}

			.price-active {
				border: 1px solid $theme-color;
				background: #fff5f0;
			}
		}

		.pay-way {
			margin: 16px 0 !important;

			.recharge {
				color: #999 !important;
				height: 24px;
				line-height: 24px;

				.go-recharge {
					cursor: pointer;
					color: $theme-color;
				}
			}

			.way-item {
				font-size: 14px;
				color: #333333;
				margin-bottom: 14px;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;

				.icon {
					margin-right: 8px;
					width: 16px;
					vertical-align: middle;
				}

				.circle,
				.circle-checked {
					width: 16px;
					height: 16px;
					background: rgba(255, 255, 255, 0.9);
					box-sizing: border-box;
					border: 1px solid #dcdcdc;
					border-radius: 50%;
				}

				.circle-checked {
					border-color: $theme-color;
					position: relative;

					.circle-solid {
						width: 8px;
						height: 8px;
						border-radius: 50%;
						background: $theme-color;
						position: absolute;
						left: calc(50% - 4px);
						top: calc(50% - 4px);
					}
				}
			}
		}

		.pay-finish {
			// width: 124px;
			height: 40px;
			border-radius: 4px;
			// text-align: center;
			// line-height: 40px;
			// cursor: pointer;
			background: $theme-color;
			font-size: 16px;
			font-weight: 500;
			color: #ffffff;
			// margin: 40px auto 0;
			margin-left: calc(50% - 62px);
		}
	}

	.right {
		width: 192px;
		text-align: center;
		margin-top: -20px;

		.pay {
			font-size: 16px;
			font-weight: 600;
			color: #333333;
		}

		.code-main {
			width: 192px;
			margin: 10px 0;
			position: relative;

			.code {
				width: 100%;
			}

			.tip {
				color: $theme-color;
				cursor: pointer;
			}

			.pay-btn-main {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.4);
				display: flex;
				justify-content: center;
				align-items: center;

				.pay-btn {
					width: 134px;
					height: 38px;
					border-radius: 6px;
					background: $theme-color;
					font-size: 14px;
					font-weight: 500;
					line-height: 38px;
					text-align: center;
					color: #ffffff;
					cursor: pointer;
				}
			}
		}

		.protocol {
			font-size: 12px;
			color: #999999;
			cursor: pointer;

			span {
				color: $theme-color;
			}
		}
	}
}

:deep(.el-dialog) {
	border-radius: 20px;
}

/* 整个滚动条 */
::-webkit-scrollbar {
	width: 0; /* 设置滚动条的宽度 */
	height: 4px; /* 设置滚动条的高度（如果是水平滚动条） */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
	background: #f1f1f1; /* 设置轨道的背景颜色 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	background: #888; /* 设置滑块的背景颜色 */
}
</style>
