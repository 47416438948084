<template>
	<el-scrollbar
		class="scroll-box"
		:style="{ height: height }"
		ref="scrollbarRef"
	>
		<slot></slot>
		<div class="loading" v-if="loading">加载中...</div>
		<q-empty height="300px" :content="emptyContent" v-if="!total"></q-empty>
	</el-scrollbar>
</template>
<script>
import util from '@/utils/util';
export default {
	props: {
		// 列表数量
		total: {
			type: Number,
			default: 0,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		height: {
			type: String,
			default: '300px',
		},
		// 空状态文字
		emptyContent: {
			type: String,
			default: '暂无数据',
		},
	},
	data() {
		return {};
	},
	mounted() {
		this.$refs.scrollbarRef.wrap.addEventListener('scroll', this.onScroll);
	},
	methods: {
		// 滚动事件
		onScroll(event) {
			const scrollTop = event.target.scrollTop;
			const scrollHeight = event.target.scrollHeight;
			const clientHeight = event.target.clientHeight;
			if (scrollHeight - scrollTop - clientHeight < 10 && !this.loading) {
				this.scrolltolower();
			}
		},

		// 滚动到底部触发
		scrolltolower: util.debounce(function () {
			this.$emit('scrolltolower');
		}),
	},
};
</script>
<style lang="scss">
.scroll-box {
	.loading {
		text-align: center;
		margin: 10px 0;
		color: #999;
	}
}
</style>
