<template>
  <div class="enter-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "aiCustomization",
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.enter-layout {
  background-color: white;
}
</style>

