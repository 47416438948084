<template>
	<div
		:class="[
			'unlike-report-box',
			{ article: type == 'Article' },
			{ answer: type == 'Question' },
		]"
		v-show="isShow"
	>
		<div class="unlike-report-item" @click="handleOpt('disLikeSure')">
			<div class="icon-box">
				<i class="icon-sohu-unlike"></i>
			</div>
			<p class="name">不感兴趣</p>
		</div>
		<div class="unlike-report-item" @click="handleOpt('report')">
			<div class="icon-box">
				<i class="icon-sohu-report"></i>
			</div>
			<p class="name">举报</p>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: '',
		},
		info: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	methods: {
		async handleOpt(type) {
			this.$login().then(async (res) => {
				switch (type) {
					case 'disLikeSure':
						await this.$http.dislikeInsertByDislike({
							busyCode: this.info.id,
							busyType: this.type,
							dislikeType: 'DislikeCategory',
						});
						this.$message.success('操作成功');
						this.$emit('handleOpt', type);
						this.isShow = false;
						break;
					case 'report':
						this.$emit('handleOpt', 'reportSucess');
						this.$report({
							info: { ...this.info, reportType: this.type },
						}).then(() => {
							this.$emit('handleOpt', 'reportSucess');
						});
						this.isShow = false;
						break;
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.unlike-report-box,
.article,
.answer {
	position: absolute;
	bottom: 20px;
	right: 60px;
	border-radius: 8px;
	background: #21222c;
	padding: 16px 24px;
	display: flex;
	gap: 24px;

	.unlike-report-item {
		text-align: center;
		color: rgba(255, 255, 255, 0.8);

		.icon-box {
			width: 48px;
			height: 48px;
			background: #2d2e37;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			i {
				font-size: 24px;
			}
		}

		.name {
			font-size: 12px;
			margin-top: 10px;
		}
	}
}

.article,
.answer {
	top: 0px;
	right: 40px;
	height: 80px;
	background: #f5f5f5;

	.unlike-report-item {
		color: #333;

		.icon-box {
			background: #ffffff;
		}
	}
}

.article {
	right: 90px;
}
</style>
