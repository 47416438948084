import InstitutionEntryLayout from '@/views/entry/institutionEntry/Layout.vue';
// MCN机构入驻
export default [
	{
		path: '/institutionEntry',
		component: InstitutionEntryLayout,
		name: 'InstitutionLayout',
		redirect: '/institutionEntry/institutionEntryIndex',
		children: [
			{
				path: 'institutionEntryIndex',
				component: () =>
					import('@/views/entry/institutionEntry/institutionEntryIndex/index'),
				meta: {
					title: '机构入驻首页',
				},
			},
			{
				path: 'institutionEntryInfo',
				component: () =>
					import('@/views/entry/institutionEntry/institutionEntryInfo/index'),
				meta: {
					title: '填写入驻信息',
				},
			},
			{
				path: 'success',
				component: () => import('@/views/entry/demolitionEnter/success/index'),
				meta: {
					title: '提交成功',
				},
			},
		],
	},
];
