<template>
	<div class="subType_shop">
		<div class="card_title">{{ content.title }}</div>
		<div class="card_time">{{ content.content.noticeTime }}</div>
		<div class='card_desc'>{{ content.content.content.desc }}</div>
		<div class='card_info'>
			<img :src='content.content.content.coverImage'>
			<div class='content'>
				<div class='title'>{{ content.content.content.shopTitle }}</div>
				<div class="attr" v-if="content.content.content.attrValue">
					<div class="item" v-for="(v,i) in handleAttrData()" :key="i">{{ v }}</div>
				</div>
				<div class="price">价格：￥{{ content.content.content.price }}</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['content'],
	data() {
		return {};
	},
	methods: {
		handleAttrData(){
			const list = [];
			const attrValue = JSON.parse(this.content.content.content.attrValue);
			for (const key in attrValue) {
				list.push(key + "：" + attrValue[key]);
			}
			return list;
		}
	},
};
</script>
<style lang="scss">
.subType_shop {
	width: 100%;
	height: fit-content;
	background-color: #FFF;
	border-radius: 8px;
  .card_title {
    color: #333333;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
  .card_time {
    color: #555555;
    line-height: 16px;
    font-size: 10px;
    margin-bottom: 20px;
  }
  .card_desc {
    font-size: 12px;
    line-height: 16px;
  }
	.card_info {
		width: 100%;
		height: 64px;
		background-color: #F3F3F5;
		border-radius: 4px;
		margin-top: 8px;
		padding: 8px;
		display: flex;
    box-sizing: border-box;
		img {
			width: 48px;
			height: 48px;
			border-radius: 2.5px;
			margin-right: 8px;
		}
		.content {
			flex: 1;
			height: 100%;
			.title {
				max-width: 376px;
				color: #555555;
				font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
				text-overflow: ellipsis;
			}
			.attr {
        color: #555555;
        font-size: 12px;
				line-height: 24px;
				display: flex;
				.item {
					margin-right: 10px;
				}
			}
			.price {
        color: #555555;
        font-size: 12px;
			}
		}
	}
}
</style>
