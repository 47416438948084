import { get, postFile, postJson } from '@/utils/request';

export default {
	// 普通登录接口
	authLogin(data) {
		return postJson('/auth/v2/login', data);
	},

	// 三方授权预登录
	authThirdPrepareLogin(data) {
		return postJson('/auth/v2/thirdPrepareLogin', data);
	},

	//账号密码登录
	// loginByPwd(data) {
	// 	return postJson('/auth/login', data);
	// },

	//获取手机号验证码
	getCode(data) {
		return postJson('/resource/sms/code', data);
	},

	//验证码登录
	// smsLogin(data) {
	// 	return postJson('/auth/smsLogin', data);
	// },

	//修改密码
	resetPwd(data) {
		return postJson('/auth/resetPwd', data);
	},

	//微信扫码登录
	authWeixinAppLogin(data) {
		return postJson(`/auth/weixin-qr-login`, data);
	},

	//使用手机 + 验证码登录(apple授权,微信授权绑定)
	bindingPhoneWeixinLogin(data) {
		return postJson(`/auth/v2/third-app-login`, data);
	},
	
	//获取二维码标识
	getQrCodeId(data) {
		return get(`/auth/getQrCodeId`, data);
	},

	//通过二维码标识获取登录信息
	loginInfoQrCodeId(qrCodeId) {
		return get(`/auth/loginInfo/${qrCodeId}`);
	},

	//获取邮箱验证码
	resourceEmailCode(data) {
		return postJson(`/resource/email/code`, data);
	},

	//邮箱绑定手机号
	autheMailBindPhone(data) {
		return postJson(`/auth/email-bind-phone`, data);
	},

	//使用手机 + 验证码登录(apple授权,微信授权绑定)
	authThirdAppLogin(data) {
		return postJson(`/auth/third-app-login`, data);
	},

	//邮箱验证码登录
	// authEmailLogin(data) {
	// 	return postJson(`/auth/emailLogin`, data);
	// },

	//注册
	authRegister(data) {
		return postJson(`/auth/v2/register`, data);
	},
};
