<template>
	<div>
		<dialogBox
			appendToBody
			width="500px"
			v-model="dialogVisible"
			class="ComeInDialog"
			title="进群设置"
			showBottomBtn
			confirmText="新增消息"
			@confirm="addEditClick($event)"
		>
			<div class="topTitle">
				<div class="title">进群前可浏览群消息</div>
				<div class="tip">该消息以群主身份发送，最多10条。</div>
			</div>
			<div class="setTitle">消息设置</div>
			<scroll-box
				emptyContent="暂无数据"
				height="400px"
				:total="infoList.length"
			>
				<div style="padding-top: 10px"></div>
				<div
					class="setlist"
					v-for="(item, index) of infoList"
					:key="index"
					@click="updateInfo(item)"
				>
					<img
						src="https://sohugloba.oss-cn-beijing.aliyuncs.com/2024/09/27/0455a28e20a847518886f809bdf5daf6_16x16.png"
						alt=""
						class="closeBtn"
						@click.stop="deleteInfo(item)"
					/>
					<div class="setContent">
						{{ item.busyContent }}
					</div>
				</div>
			</scroll-box>
		</dialogBox>
		<dialogBox
			appendToBody
			width="500px"
			v-model="addDialogVisible"
			class="addDialog"
			title="消息内容"
			showBottomBtn
			confirmText="保存"
			@confirm="addInfo($event)"
			@cancel="cancel"
			:confirmDisabled="!message"
		>
			<el-input
				class="addDialog-input"
				type="textarea"
				:rows="4"
				placeholder="请输入内容"
				:autosize="false"
				resize="none"
				v-model="message"
				maxlength="200"
			></el-input>
		</dialogBox>
	</div>
</template>

<script>
import dialogBox from '@/components/dialogBox.vue';
export default {
	components: {
		dialogBox,
	},

	data() {
		return {
			dialogVisible: false,
			addDialogVisible: false,
			message: '',
			details: '',
			infoList: [],
			currentRow: {},
		};
	},
	methods: {
		open(details) {
			console.log('details', details);
			this.details = details;
			this.dialogVisible = true;
			this.getInfoList(details.id);
		},
		addEditClick(close) {
			if (this.infoList.length == 10) {
				this.$message.warning('最多添加10条信息');
			} else {
				this.addDialogVisible = true;
			}
			close(true);
		},
		updateInfo(item) {
			this.addDialogVisible = true;
			//$set可以触发更新视图
			this.$nextTick(() => {
				this.currentRow = item;
				this.message = item.busyContent;
			});
			console.log('item', this.message);
		},
		async addInfo(close) {
			let res;
			if (this.currentRow.id) {
				res = await this.$http.updatedFakeList({
					id: this.currentRow.id,
					groupId: this.details.id,
					busyType: 'FakeImMessage',
					busyContent: this.message,
				});
			} else {
				res = await this.$http.fakeListAdd({
					groupId: this.details.id,
					busyType: 'FakeImMessage',
					busyContent: this.message,
				});
			}
			if (res.code == 200) {
				this.$message.success('操作成功');
				this.getInfoList(this.details.id);
				this.message = '';
				close();
			} else {
				close(true);
			}
		},

		//删除
		deleteInfo(item) {
			this.$confirm('确定删除该条信息？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(async () => {
				const res = await this.$http.fakeListDel(item.groupId, item.id);
				if (res.code == 200) {
					this.$message.success('删除成功');
					this.getInfoList(this.details.id);
				} else {
					this.$message.error(res.msg);
				}
			});
		},

		cancel() {
			this.message = '';
			this.addDialogVisible = false;
		},
		async getInfoList(groupId) {
			const res = await this.$http.getFakeList({
				groupId: groupId,
				busyType: 'FakeImMessage',
			});
			if (res.code !== 200) return false;
			this.infoList = res.data;
		},
	},
};
</script>

<style lang="scss">
.ComeInDialog {
	.topTitle {
		width: 436px;
		height: 54px;
		border: 1px dashed #ff7d11;
		border-radius: 2px;
		background: #fbf2ed;
		box-sizing: border-box;
		padding: 12px;
		margin-top: 10px;
		.title {
			font-size: 14px;
			line-height: 10px;
			color: #ff602b;
		}
		.tip {
			font-size: 12px;
			line-height: 12px;
			color: #b1b1b1;
			margin-top: 10px;
		}
	}
	.setTitle {
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		color: #333333;
		margin: 25px 0 8px 0;
	}
	.setlist {
		cursor: pointer;
		position: relative;
		box-sizing: border-box;
		.closeBtn {
			position: absolute;
			top: -3px;
			right: 13px;
			cursor: pointer;
		}
	}
	.setContent {
		width: 420px;
		border-radius: 4px;
		box-sizing: border-box;
		padding: 14px 12px;
		margin-bottom: 15px;
		font-size: 12px;
		background: #fafafa;
		color: #999999;
		line-height: 18px;
	}
}
.addDialog {
	.addDialog-input {
		margin-top: 15px;
		.el-textarea__inner {
			background-color: #f3f3f3;
		}
	}
}
</style>
