<template>
	<div :class="[{ rightMsg: itMe }, 'chat-msg-item']">
		<div class="chat-msg-item-time" v-if="isShowTime">
			{{ messageCreateTime }}
		</div>
		<!-- 消息提示 -->
		<div class="chat-msg-item-time" v-if="item.messageType == 'command' || item.messageType == 'msg'">
			{{ item.body.content }}
		</div>
		<div class="chat-msg-item-content" v-else v-show="!(item.body.err && $store.state.userInfo.id == item.receiver.id)"> 
			<el-avatar class="userAvatar" :src="item.sender.avatar"></el-avatar>
			<el-popover
				popper-class="messageMenu"
				placement="bottom"
				trigger="manual"
				v-model="visible"
				disabled
			>
				<div
					class="messageMenuBox"
					@mouseenter="handleMouseEnter"
					@mouseleave="handleMouseLeave"
				>
					<div @click="messageMenuClick('撤回')">
						<i class="icon-sohu-chehui"></i>
						<p>撤回</p>
					</div>
					<!-- <div @click="messageMenuClick('回复')">
						<i class="icon-sohu-xiaoxi1"></i>
						<p>回复</p>
					</div>
					<div @click="messageMenuClick('删除')">
						<i class="icon-sohu-shanchu"></i>
						<p>删除</p>
					</div> -->
				</div>
				<div
					slot="reference"
					class="messageBody"
					@contextmenu.prevent.stop="showMenu"
					@mouseenter="handleMouseEnter"
					@mouseleave="handleMouseLeave"
				>
					<el-tooltip effect="dark" :content="item.body.err" placement="top">
						<i
							class="el-icon-warning"
							v-if="item.body.err"
							@click="errorClick"
						></i>
					</el-tooltip>
					<!-- 群聊显示昵称和角色 -->
					<div
						class="senderName"
						v-if="['group', 'groupTask'].includes(item.sessionType)"
					>
						{{ item.sender.name }}
						<span class="roleTag" v-if="roleName">
							{{ roleName }}
						</span>
					</div>
					<!-- 图片 -->
					<div v-if="item.messageType == 'photo'" class="photo">
						<el-image
							@load="imageLoaded"
							:src="item.body.content"
							:preview-src-list="[item.body.content]"
						>
						</el-image>
					</div>
					<!-- 视频 -->
					<div
						v-else-if="item.messageType == 'video'"
						class="video"
						@click="videoView"
					>
						<video :src="item.body.content"></video>
						<i class="icon-sohu-bofang"></i>
					</div>
					<!-- 语音 -->
					<div
						v-else-if="item.messageType == 'voice'"
						:class="[
							{ smallVoice: item.body.duration > 10 },
							{ mediumVoice: item.body.duration > 50 },
							'voice',
						]"
						@click="playAudio"
					>
						<i class="icon-sohu-yuyinbofang playing" v-if="!playing"></i>
						<el-image
							class="playing"
							v-else
							:src="require('@/assets/images/chat/playing.gif')"
						></el-image>
						<span class="duration"> {{ item.body.duration }}" </span>
					</div>
					<!-- 分享卡片 -->
					<div v-else-if="item.messageType == 'share'" class="share">
						<!--    task("task", "任务"),
										taskGuide("taskGuide", "任务咨询"),
										good("good", "商品"),
										article("article", "图文"),
										video("video", "视频"),
										person("person", "分享名片"),
										invite("invite", "邀请"),
										goodWindow("goodWindow", "商品橱窗"),
										answer("answer", "回答"),
										question("question", "问题"), -->
						<!-- 任务 -->
						<div
							v-if="['taskGuide'].includes(item.share.type)"
							class="share_taskGuide"
							@click="shareCardClick"
						>
							<div class="share_taskGuide_content">
								<el-image
									:src="require('@/assets/images/chat/taskImg.png')"
								></el-image>
								<div class="right_taskGuide_content">
									<div class="title line-2">
										{{ item.share.title }}
									</div>
									<div class="price">
										<span>￥</span>
										{{ item.share.amount }}
									</div>
								</div>
							</div>
							<div class="share_taskGuide_user">
								来自{{ item.sender.name }}的分享
							</div>
						</div>
						<!-- 视频 -->
						<div
							v-else-if="
								['Video', 'task', 'article', 'playlet'].includes(
									item.share.type,
								)
							"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										<span v-if="item.share.type == 'task'">
											{{ item.share.name }}邀请您加入任务“{{
												item.share.title
											}}”快乐赚钱吧
										</span>
										<span v-else>快来看看作品「{{ item.share.title }}」</span>
									</div>
									<div class="content_userData">
										<el-avatar :size="20" :src="item.share.avatar"></el-avatar>
										<div class="username line-1">{{ item.share.name }}</div>
									</div>
								</div>
								<el-image
									:src="
										item.share.type == 'task'
											? require('@/assets/images/chat/taskShare.png')
											: item.share.shareParam
											? JSON.parse(item.share.shareParam).videoThumbnail
											: require('@/assets/images/logo_x2.png')
									"
									fit="cover"
								></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>
						<!-- 名片 -->
						<div
							v-else-if="item.share.type == 'person'"
							class="share_person"
							@click="shareCardClick"
						>
							<div class="userData">
								<el-image
									class="leftImg"
									:src="item.share.avatar"
									fit="cover"
								></el-image>
								<div class="username line-1">
									{{ item.share.name }}
								</div>
							</div>
							<div class="share_user">个人名片</div>
						</div>

						<!-- 商品 -->
						<div
							v-else-if="['good'].includes(item.share.type)"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										快来看看商品「{{ item.share.title }}」
									</div>
									<div class="content_userData">
										<el-avatar
											:size="20"
											:src="item.share.shareUserAvatar"
										></el-avatar>
										<div class="username line-1">
											{{ item.share.shareUserName }}
										</div>
									</div>
								</div>
								<el-image :src="item.share.coverImage" fit="cover"></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>

						<!-- 邀请 -->
						<div
							v-else-if="['invite'].includes(item.share.type)"
							class="share_video"
							@click="shareCardClick"
						>
							<div class="content_box">
								<div class="content_text">
									<div class="content_title line-2">
										{{ item.share.shareUserName }}邀请您加入狐少少一起做任务赚钱
									</div>
									<div class="content_userData">
										<el-avatar
											:size="20"
											:src="item.share.shareUserAvatar"
										></el-avatar>
										<div class="username line-1">
											{{ item.share.shareUserName }}
										</div>
									</div>
								</div>
								<el-image
									:src="require('@/assets/images/chat/inviteShare.png')"
									fit="cover"
								></el-image>
							</div>
							<div class="share_title">狐少少</div>
						</div>

						<!-- 未处理的类型 -->
						<div class="chat-msg-text" v-html="content" v-else></div>
					</div>

					<!-- 纯文本 -->
					<div class="chat-msg-text" v-html="content" v-else></div>
				</div>
			</el-popover>
		</div>
	</div>
</template>
<script>
export default {
	props: ['item', 'prev'],
	data() {
		return {
			audio: '',
			playing: false,
			visible: false,
			timer: null,
		};
	},
	computed: {
		itMe() {
			return this.item.sender.id == this.$store.state.userInfo.id;
		},
		// 消息类容
		content() {
			let str = '';
			if (this.item.messageType == 'text') {
				var httpReg =
					/(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi;
				str = this.item.body.content.replace(httpReg, function (httpText) {
					let url = '';
					if (!httpText.includes('http')) {
						url = '//' + httpText;
					} else {
						url = httpText;
					}
					return "<a href='" + url + "' target='_blank'>" + httpText + '</a>';
				});
			}else {
				str = '[该消息暂未支持，请在App内查看]';
			}
			return str;
		},
		roleName() {
			switch (this.item.sender.role) {
				case 'group_leader':
					return '群主';
				case 'group_admin':
					return '管理员';
			}
		},

		// 消息时间
		messageCreateTime() {
			return this.$util.toTimeText(this.item.createTime, false);
		},

		// 是否显示时间
		isShowTime() {
			if (this.prev) {
				return this.item.createTime - this.prev.createTime > 1000 * 60 * 5;
			} else {
				return true;
			}
		},
	},
	beforeDestroy() {
		if (this.audio) {
			this.playing = false;
			this.audio.pause();
			this.audio = null;
		}
	},
	methods: {
		// 图片加载完成
		imageLoaded() {
			this.$emit('imageLoad');
		},

		// 视频预览
		videoView() {
			window.open(this.item.body.content);
		},

		// 语音播放
		playAudio() {
			if (!this.audio) {
				this.audio = new Audio(this.item.body.content);
			}
			//监听语音
			this.audio.addEventListener('ended', ()=> {
				this.playing = false;
				this.audio.pause();
				console.log('Audio ended');
			});
			if (!this.playing) {
				this.playing = true;
				this.audio.play();	
			}
		},

		// 鼠标右键事件
		showMenu() {
			if (this.itMe) {
				clearTimeout(this.timer);
				this.visible = true;
			}
		},

		// 鼠标移入菜单
		handleMouseEnter() {
			if (this.visible) {
				clearTimeout(this.timer);
			}
		},

		// 鼠标移出菜单
		handleMouseLeave() {
			if (this.visible) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					this.visible = false;
				}, 1000);
			}
		},

		// 点击菜单
		messageMenuClick(type) {
			clearTimeout(this.timer);
			this.visible = false;
		},

		// 分享卡片
		shareCardClick() {
			let link;
			switch (this.item.share.type) {
				case 'task':
				case 'taskGuide':
					link = this.$router.resolve({
						path: '/makeMoney/taskDetails',
						query: {
							taskNumber: this.item.share.content,
							source: '任务广场',
						},
					});
					break;
				case 'Video':
					link = this.$router.resolve({
						path: '/videoShare',
						query: {
							id: this.item.share.id,
						},
					});
					break;
				case 'article':
					link = this.$router.resolve({
						path: '/imageTextDetail',
						query: {
							id: this.item.share.id,
						},
					});
					break;
				case 'person':
					link = this.$router.resolve({
						path: '/othersHome',
						query: {
							userId: this.item.share.id,
						},
					});
					break;
				case 'playlet':
					link = this.$router.resolve({
						path: '/shortPlayDetail',
						query: {
							episodeRelevance: this.item.share.episodeRelevance,
						},
					});
					break;
			}
			link && window.open(link.href, '_blank');
		},

		// 消息报错，重新发送
		errorClick() {
			let sendParams = {};
			switch (this.item.messageType) {
				case 'share':
					// sendParams.shareType = this.item.share.type;
					sendParams.shareId = this.item.share.id;
					// sendParams.shareParam = this.item.share.shareParam;
					sendParams.share = {
						amount: this.item.share.amount,
						content: this.item.share.content,
						id: this.item.share.id,
						shareUserId: this.$store.state.userInfo.id,
						title: this.item.share.title,
						type: this.item.share.type,
						userId: this.item.share.userId,
					};
					break;
			}
			this.$emit('errorClick', {
				localId: this.item.localId,
				messageType: this.item.messageType,
				content: this.item.body.content,
				...sendParams,
			});
		},
	},
};
</script>
<style lang="scss">
.chat-msg-item {
	margin-top: 24px;
	.chat-msg-item-time {
		text-align: center;
		margin-bottom: 20px;
		color: #999;
		font-size: 12px;
	}
	.chat-msg-item-content {
		display: flex;
		.userAvatar {
			width: 36px;
			height: 36px;
			margin-right: 8px;
			margin-left: 20px;
		}
		.messageBody {
			position: relative;
			.el-icon-warning {
				position: absolute;
				left: -5px;
				top: 5px;
				transform: translateX(-100%);
				color: #f56c6c;
				font-size: 24px;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}
			.senderName {
				color: #999999;
				font-size: 12px;
				margin-bottom: 5px;
				.roleTag {
					background: #d9e1ff;
					color: #4787f0;
					font-size: 12px;
					padding: 1px 8px;
					border-radius: 2.86px;
					margin-left: 7px;
					line-height: 18px;
					display: inline-block;
				}
			}
			.chat-msg-text {
				border-radius: 4px;
				padding: 8px 12px;
				color: #1a1a1a;
				font-size: 14px;
				box-sizing: border-box;
				background-color: #fff;
				line-height: 20px;
				max-width: 246px;
				display: inline-block;
				text-wrap: wrap;
				white-space: pre-wrap;
				word-break: break-all;
				word-wrap: break-word;
				a {
					color: #4787f0;
					text-decoration: underline;
					&:hover {
						opacity: 0.8;
					}
				}
			}

			.photo {
				.el-image {
					border-radius: 5px;
					overflow: hidden;
					img {
						max-width: 240px;
						max-height: 180px;
						width: auto;
						height: auto;
						object-fit: fill;
					}
				}
			}
			.video {
				width: 135px;
				height: 180px;
				cursor: pointer;
				position: relative;
				video {
					width: 100%;
					height: 100%;
					border-radius: 5px;
					object-fit: cover;
				}
				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
					opacity: 0.8;
					font-size: 60px;
				}
			}

			.voice {
				background: #fff;
				border-radius: 4px;
				display: flex;
				align-items: center;
				padding: 10px 14px;
				color: #1a1a1a;
				font-size: 12px;
				cursor: pointer;
				i {
					font-size: 16px;
				}
				.duration {
					margin: 0 5px;
				}
				&.smallVoice {
					width: 146px;
				}
				&.mediumVoice {
					width: 246px;
				}
			}

			.share {
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
				.share_taskGuide {
					background: #fff;
					border-radius: 4px;
					padding: 12px;
					box-sizing: border-box;
					width: 295px;
					cursor: pointer;
					.share_taskGuide_content {
						margin-bottom: 12px;
						display: flex;
						.el-image {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
							margin-right: 12px;
						}
						.right_taskGuide_content {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							.title {
								color: #1a1a1a;
								font-size: 14px;
								margin-bottom: 5px;
								line-height: 20px;
							}
							.price {
								color: #f6685d;
								font-weight: 600;
								font-size: 20px;
								span {
									font-size: 12px;
								}
							}
						}
					}

					.share_taskGuide_user {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
					}
				}

				.share_video {
					background: #fff;
					border-radius: 4px;
					width: 295px;
					height: 121px;
					padding: 12px;
					box-sizing: border-box;
					.content_box {
						display: flex;
						justify-content: space-between;
						.content_text {
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							.content_title {
								font-size: 13px;
								color: #3d3d3d;
								line-height: 18px;
								margin-right: 50px;
							}
							.content_userData {
								display: flex;
								align-items: center;

								.username {
									color: rgba(0, 0, 0, 0.6);
									font-size: 10px;
									margin-left: 5px;
								}
							}
						}
						.el-image {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
						}
					}

					.share_title {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
						margin-top: 14px;
					}
				}

				.share_person {
					background: #fff;
					border-radius: 4px;
					width: 295px;
					height: 121px;
					padding: 12px;
					box-sizing: border-box;
					.userData {
						display: flex;
						align-items: center;
						flex: 1;
						.leftImg {
							border-radius: 5px;
							min-width: 64px;
							height: 64px;
							margin-right: 12px;
						}
						.username {
							color: #1a1a1a;
							font-size: 14px;
						}
					}
					.share_user {
						border-top: 1px solid #d8d8d8;
						color: #969696;
						font-size: 12px;
						padding-top: 8px;
						margin-top: 14px;
					}
				}
			}
		}
	}
	&.rightMsg {
		.chat-msg-item-content {
			justify-content: flex-end;
			.userAvatar {
				order: 1;
				margin-left: 8px;
				margin-right: 20px;
			}
			.messageBody {
				.senderName {
					display: none;
				}
				.chat-msg-text {
					background: #95ec69;
				}
				.voice {
					justify-content: flex-end;
					background: #95ec69;
					.playing {
						order: 1;
						transform: scaleX(-1);
					}
				}
			}
		}
	}
}
.messageMenu {
	padding: 16px 16px 12px !important;
	width: 40px;
	min-width: 40px !important;
	border-radius: 10px !important;
	.messageMenuBox {
		display: grid;
		column-gap: 24px;
		grid-template-columns: repeat(1, 1fr);
		text-align: center;
		color: #333333;
		div {
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
		i {
			font-size: 16px;
		}
		p {
			font-size: 13px;
		}
	}
}
</style>
