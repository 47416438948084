import layout_game from '@/views/layout/layout-game.vue';
export default [
	{
		path: '/game',
		component: layout_game,
		children: [
			{
				path: 'home',
				component: () => import('@/views/game/home/index.vue'),
				meta: {
					title: '游戏首页',
				},
			},
			{
				path: 'details',
				component: () => import('@/views/game/gameDetails/index.vue'),
				meta: {
					title: '游戏详情',
				},
			},
			{
				path: 'list',
				component: () => import('@/views/game/gameList/index.vue'),
				meta: {
					title: '游戏列表',
				},
			},
			{
				path: 'rebatePage',
				component: () => import('@/views/game/rebatePage/index.vue'),
				meta: {
					title: '活动规则',
				},
			},
		],
	},
];
