<template>
	<div :class="{ promotion: type == 'video' }">
		<div class="promotion-wrapper" v-if="relation.busyType === 'Window'">
			<img
				class="icon-img"
				src="../assets/images/promotionIcon/icon-showcase.png"
			/>
			<span class="text"
				><span class="type-name">橱窗</span>｜{{
					relation.userName
				}}的橱窗</span
			>
		</div>
		<!-- 推广-橱窗商品 -->
		<div class="promotion-wrapper" v-if="relation.busyType === 'GoodsWindow'">
			<img
				class="icon-img"
				src="../assets/images/promotionIcon/icon-product.png"
			/>
			<span class="text"
				><span class="type-name">商品</span>｜{{ relation.busyTitle }}</span
			>
		</div>
		<!-- 推广-我的任务 -->
		<div
			class="promotion-wrapper"
			v-if="relation.busyType === 'BusyTask'"
			style="cursor: pointer"
			@click="goTaskDetail"
		>
			<img
				class="icon-img"
				src="../assets/images/promotionIcon/icon-task.png"
			/>
			<span class="text"
				><span class="type-ame">任务</span>｜{{ relation.busyTitle }}</span
			>
		</div>
		<!-- 认证赚钱 -->
		<div class="promotion-wrapper" v-if="relation.busyType === 'EntryRole'">
			<img
				class="icon-img"
				src="../assets/images/promotionIcon/icon-certification.png"
			/>
			<span class="text">{{ relation.busyTitle }}</span>
		</div>
		<!-- 狐少少课堂 -->
		<div class="promotion-wrapper" v-if="relation.busyType === 'SohuLesson'">
			<img
				class="icon-img"
				src="../assets/images/promotionIcon/icon-lesson.png"
				mode="aspectFill"
			/>
			<span class="text">{{ relation.busyTitle }}</span>
		</div>
		<!-- <div class="goodContainer">
			<el-carousel height="80px">
				<el-carousel-item v-for="item in 4" :key="item">
					<div class="goodBox">
						<div class="left">
							<img
								src="../assets/images/promotionIcon/icon-lesson.png"
								mode="aspectFill"
							/>
						</div>
						<div class="right">
							<p class="line-1" >SOL点击购买点击购SOL点击购买点击购</p>
							<p>¥ 2,000.00</p>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div> -->
	</div>
</template>
<script>
export default {
	props: ['relation', 'type'],
	data() {
		return {};
	},
	mounted() {},
	methods: {
		// 跳转任务详情
		goTaskDetail() {
			this.$login().then((res) => {
				this.$router.push({
					path: '/makeMoney/taskDetails',
					query: {
						taskNumber: this.relation.childTaskNumber,
						type: 'square',
						source: "任务广场"
					},
				});
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.promotion-wrapper {
	height: 42px;
	line-height: 42px;
	border-radius: 6px;
	background: #f9f9f9;
	padding: 0 10px;
	width: max-content;
	.icon-img {
		width: 28px;
		height: 28px;
		border-radius: 4px;
		margin-right: 10px;
	}

	.type-name {
		color: #333;
	}

	.text {
		font-size: 14px;
		color: #666666;
	}
}
.goodContainer {
	width: 252px;
	height: 70px;
	background: rgba(84, 84, 84, 0.8);
	padding: 8px 7px;
	margin-top: 10px;
	border-radius: 6px;
	.goodBox {
		display: flex;
		width: 266px;
		height: 70px;
		gap:10px;
		.left {
			width: 60px;
			height: 60px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.right {
			color: #fff;
			font-size: 18px;
			height: 60px;
			line-height: 1;
			display: flex;
			flex:1;
			flex-direction: column;
			justify-content:space-between;
		}
	}
}
:deep(.el-carousel__indicators) {
			.el-carousel__button {
				background: rgba(216, 216, 216, 0.36);
				width: 3px;
				height: 3px;
				border-radius: 50%;
			}

			.is-active {
				.el-carousel__button {
					width: 3px;
					height: 3px;
					border-radius: 50%;
					background: #D8D8D8;
				}
			}
		}
// 视频详情的橱窗
.promotion {
	.promotion-wrapper {
		background: rgba(84, 84, 84, 0.8);
		color: #ffffff;
		height: 40px;
		display: flex;
		align-items: center;
		.icon-img {
			width: 28px;
			height: 28px;
		}
		.type-name {
			color: #fff;
			font-size: 18px;
		}

		.text {
			color: #fff;
			font-size: 18px;
		}
	}
}
</style>
