//埋点场景

import store from "@/store";

export const aiBuryScene = {
    homeArticle:"101001",//首页图文
    homeQuestion:"104001",//首页知识
    homeVideo:"102001",//首页视频
    lifePlaylet:"103001",//生活短剧
    moneyRecommended:"105001",//赚钱为你推荐
    moneySquare:"105002",//赚钱任务广场
    moneyDistribution:"105003",//赚钱分销任务
    moneyArticle:"101002",//赚钱图文
    moneyQuestion:"104002",//赚钱知识
    moneyVideo:"102003",//赚钱视频
}
//埋点事件
export const aiBuryEvent = {
    expose:"expose",//曝光
    click:"click",//点击
    like:"like",//点赞
    unlike:"unlike",//不喜欢
    comment:"comment",//评论
    collect:"collect",//收藏
    stay:"stay",//停留时长
    share:"share",//分享
    follow:"subscribe",//关注
}
//内容类型
export const aiBuryContentType = {
    article:"article",//文章
    video:"video",//视频
    shortvideo:"shortvideo",//短剧
    item:"item",//任务
    recipe:"recipe"//问答
}
//是否开启ai模型
export const aiRec = true

export const aiPraams = {
    aiRec,
    aiRecImei: store.getters.isLogin
      ? store.state.userInfo.id
      : localStorage.getItem("aiRec"),
};